import axiosStatic from '../axiosStatic';
import { replaceAll } from '../utils';

const fileNameParser = (name, parse = false) => {
  if (parse) {
    name = replaceAll(name.toLowerCase(), /\s|[0-9_]|\W|[#$%^&*()]/gi, '');
  }

  console.log('name of the geojson', name.includes('.json') ? name : `${name}_geojson.json`);
  return name.includes('.json') ? name : `${name}_geojson.json`;
};

export const getMapData = ({ dataMap = 'India', parse = true }) =>
  axiosStatic.get('/static/data/maps/' + fileNameParser(dataMap, parse));
