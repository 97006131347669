import get from 'lodash/get';
import { voidFunction, replaceAll } from '../../utils';
import { getDimensions } from '../../services/dataset';
import { TYPE_LOCATION } from '../../constants';

export const getColumn = (dimension) => ({
  title: dimension.DisplayName,
  dataIndex: dimension.ID,
  key: dimension.ID,
  enableCheckbox: true,
  width: 250,
  showNumberOfRecord: true,
});

export const getDimensionQuery = (filteredDimensions, ID = [], search = [], view_name = '') => {
  if (!filteredDimensions) return false;

  if (!ID) {
    const dimension =
      filteredDimensions.length === 1 ? filteredDimensions[0] : filteredDimensions.find((d) => d.hierarchy === 1);

    if (!dimension) {
      return false;
    }

    if (!view_name) {
      dimension.ID = String(dimension.ID).split(',').shift();
    }

    return {
      query: {
        ip_columns: dimension.ID,
        ip_search: search,
        view_name,
      },
      column: getColumn(dimension),
      dimension,
    };
  } else {
    const dimension = filteredDimensions.find((data) => data.ID === ID);
    let isLocation = dimension?.DimensionType?.toLowerCase?.() === TYPE_LOCATION;
    let checkedGreaterthen = isLocation ? 2 : 1;

    if (dimension) {
      let potentialDimensions = [];
      if (isLocation) {
        potentialDimensions = filteredDimensions.filter((data) =>
          [dimension.hierarchy + 1, dimension.hierarchy + 2].includes(data.hierarchy)
        );
      } else {
        filteredDimensions.every((data) => {
          if (data.ID !== dimension.ID && data.hierarchy >= dimension.hierarchy) {
            potentialDimensions = [data];
            return false;
          }

          return true;
        });
      }

      if (potentialDimensions.length >= checkedGreaterthen) {
        const targetDimension = potentialDimensions.pop();

        if (!view_name) {
          targetDimension.ID = String(targetDimension.ID).split(',').shift();
        }

        return {
          query: {
            ip_columns: targetDimension.ID,
            ip_search: [
              {
                key: potentialDimensions[0]?.ID || ID,
                operator: 'IN',
                value: typeof search === 'string' ? replaceAll(search, "'", '').split(',') : search,
                type: 'dimension',
              },
            ],
            view_name,
          },
          column: getColumn(targetDimension),
          dimension: targetDimension,
        };
      }
    }
  }

  return false;
};

export const loadDimensionData = ({
  sourceID,
  dimensions = [],
  setColumns = voidFunction,
  setLoading = voidFunction,
  setData = voidFunction,
  onHault = voidFunction,
  setError = voidFunction,
  ID = '',
  search = [],
  view_name = '',
  multipleDataset = false,
}) => {
  const formatDimensions = dimensions?.filter(({ ID }) => !ID.includes('Code'));
  if (formatDimensions?.length && sourceID) {
    if (!view_name) {
      sourceID = String(sourceID).split(',');
      if (!multipleDataset) {
        sourceID = sourceID.shift();
      }
    }

    const dimensionQuery = getDimensionQuery(formatDimensions, ID, search, view_name);

    if (dimensionQuery) {
      return getDimensionValues({
        sourceID,
        setColumns,
        setLoading,
        setError,
        setData: (data) => {
          if (data.length) {
            setColumns((ps) => {
              const newColumns = [...ps];
              const columnExists = ps.find((c) => c.dataIndex === dimensionQuery.column.dataIndex);

              if (!columnExists) {
                newColumns.push(dimensionQuery.column);
              }

              return newColumns;
            });
          }

          setData({ data, dimensionQuery });
        },
        query: dimensionQuery.query,
      });
    } else {
      onHault();
    }
  } else {
    onHault();
  }

  return {};
};

export const getDimensionValues = ({
  sourceID,
  query = {},
  setLoading = voidFunction,
  setData = voidFunction,
  setError = voidFunction,
}) => {
  return getDimensions({
    sourceID,
    setLoading,
    setData,
    setError,
    ...query,
  });
};

export const clearChecked = (ps) => {
  return ps.map((item) => {
    if (item.checked) {
      for (let [key] of Object.entries(item.checked)) {
        item.checked[key] = false;
        item[key + 'Checked'] = false;
      }
    }

    return item;
  });
};

export const calculateNewState = ({ ps = [], data = [], dimensionQuery = {}, quickPaths = {} }) => {
  const newState = [...ps];
  const nameKey = dimensionQuery?.column?.dataIndex;
  const idKey = nameKey + 'ID';
  const idColumn = nameKey + 'Column';

  let lastIndex = 0;
  data.forEach((item, index) => {
    if (item.ID || item.Name || item.columnname) {
      const { ID, Name, columnname, ...rest } = item;
      const otherProps = {};
      Object.keys(rest).forEach((key) => {
        otherProps[`${nameKey}${key}`] = rest[key];
      });

      if (!newState[index]) {
        newState[index] = {
          ...otherProps,
          key: String(index),
          [idKey]: ID,
          [nameKey]: Name,
          [idColumn]: columnname,
        };
      } else {
        newState[index] = {
          ...newState[index],
          ...otherProps,
          [nameKey]: Name,
          [idKey]: ID,
          [idColumn]: columnname,
        };
      }

      lastIndex = index;
    }
  });

  let quickEntries = Object.entries(quickPaths);

  newState.forEach((item, index) => {
    if (quickEntries.length) {
      for (let [key, itemValue] of quickEntries) {
        let isChecked = get(itemValue, `selected.${item[key + 'ID']}`, false);

        newState[index][key + 'Checked'] = isChecked;

        if (!newState[index]['checked']) {
          newState[index]['checked'] = {};
        }

        newState[index]['checked'][key] = isChecked;
      }
    }

    if (index > lastIndex && nameKey) {
      delete newState[index][nameKey];
      delete newState[index][idKey];
      delete newState[index]['checked']?.[nameKey];
    }
  });

  return newState;
};

export const getDimensionsByCategory = (dimensions = [], category = '', upperLimit = false) => {
  const searchCategory = category.toLowerCase();

  return dimensions
    .map((data) => ({
      ...data,
      isOutOfLimit: upperLimit === false || (upperLimit && Number(data.unique_values) >= upperLimit),
    }))
    .filter((data) => data.DimensionType.toLowerCase() === searchCategory || !searchCategory);
};
