import { t } from 'i18next';
import React,{useContext} from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { RoutePaths } from '../../routePaths';
import Context from '../../context';
import s from './index.module.less';
import { BulbOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
const { Text } = Typography;

const SuggestDataset = () => {
  const { pathname = '/' } = useLocation();
  const [context, setContext] = useContext(Context);
  const history = useHistory();

  const suggestDataset = () => {
    if (!context.isAuth) {
      setContext((state) => ({
        ...state,
        showLogin: true,
        afterLoginRedirect: `${RoutePaths.SUGGESTED}`,
      }));
    } else {
      history.push(RoutePaths.SUGGESTED);
    }
  };

  return (
    <>
      {context.screenContext !== 'mobile' && (
        <div className={s.popupContainer} id="suggest-dataset">
          {pathname !== '/' && (
            <div
              className={`${s.suggestDataset} joyride-suggestadataset-step1 ${
                context.screenContext === 'mobile' && s.suggestDatasetMobile
              }`}
              onClick={suggestDataset}
            >
              <Text ellipsis={true} className={s.suggestDatasetText}>
                <BulbOutlined className={`${s.mobileIcon} mr-1`} />
                {t('SuggestDataset')}
              </Text>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SuggestDataset;
