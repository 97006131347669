import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';

import { Badge, Button, Modal, Tabs } from '../../../../components';
import {
  TYPE_LOCATION,
  TYPE_OTHERS,
  TYPE_TIME,
  TYPE_DIMENSIONS,
  FILTER_INPUTS,
  COMBINED_FILTERS,
  LOCATION_MODES,
} from '../../../../constants';

import {
  useFilterContext,
  useAppliedFilterContext,
  useFilterActions,
} from '../../../../providers/FilterContextProvider';
import { getLastItem } from '../../../../utils/common';
import CloseButton from '../../../CloseButton';
import DatasetDimension from '../../../DatasetDimension';
import LocationFilter, { useLocationSelectedFilter } from '../../../LocationFilter';
import { StateName } from '../../../LocationFilter/constant';
import ActionButton, { CustomFilterButton } from './ActionButton';

const { TabPane } = Tabs;

const Combined = ({
  source,
  applyModalTo,
  view_name,
  locationType = TYPE_LOCATION,
  othersType = TYPE_DIMENSIONS,
  timeType = TYPE_TIME,
  exclude = [FILTER_INPUTS.OtherDimensionFilter],
  classnames,
  granularity = false,
  isStandarized = true,
  isVisualisation = false,
}) => {
  const { clearFilters, getAny, resetAllSelectedFilters, setFilter, filters } = useFilterContext();
  const { getSelectedCount, setAppliedFilter, resetAllFilters } = useAppliedFilterContext();
  const firstSourceID = useMemo(() => getLastItem(source.SourceId), [source.SourceId]);
  const { resetToPrevious } = useFilterActions({ sourceID: source.SourceId });

  const isOtherIncluded = useMemo(() => !exclude.includes(FILTER_INPUTS.OtherDimensionFilter), [exclude]);
  const isTimeIncluded = useMemo(() => !exclude.includes(FILTER_INPUTS.time), [exclude]);

  const locationCount = getSelectedCount({ sourceID: source.SourceId, type: locationType, oneLevel: true });
  const otherDimensionCount = !isOtherIncluded
    ? 0
    : getSelectedCount({ sourceID: source.SourceId, type: othersType, oneLevel: true });
  const timeCount = !isTimeIncluded
    ? 0
    : getSelectedCount({ sourceID: source.SourceId, type: timeType, oneLevel: true });
  const totalFilters = locationCount + otherDimensionCount + timeCount;

  const toggleModal = () => setFilter({ type: COMBINED_FILTERS, sourceID: source.SourceId });
  const [isSearchValue, setIsSearchValue] = useState(true);

  const onFilterReset = () => {
    let types = [locationType];

    if (isOtherIncluded) {
      types.push(othersType);
    }

    if (isTimeIncluded) {
      types.push(timeType);
    }

    resetAllFilters({ sourceID: source.SourceId, types });
    resetAllSelectedFilters({ sourceID: source.SourceId, types });
    clearFilters();
    setIsSearchValue(false);
  };

  const targetParams = {
    sourceID: source.SourceId,
    type: locationType,
  };

  const onApply = () => {
    let sources = targetParams.sourceID;
    if (!Array.isArray(sources)) {
      sources = String(sources).split(',');
    }
    sources.forEach((sourceID) => {
      setAppliedFilter({
        appliedFilter: getAny({
          type: locationType,
          sourceID,
          key: 'checked',
        }),
        quickPaths: getAny({
          type: locationType,
          sourceID,
          key: 'quickPaths',
        }),
        type: locationType,
        sourceID,
      });

      setAppliedFilter({
        appliedFilter: getAny({
          sourceID,
          key: 'checked',
          type: timeType,
        }),
        quickPaths: getAny({
          sourceID,
          key: 'quickPaths',
          type: timeType,
        }),
        sourceID,
        type: timeType,
      });

      setAppliedFilter({
        appliedFilter: getAny({
          sourceID,
          key: 'checked',
          type: othersType,
        }),
        quickPaths: getAny({
          sourceID,
          key: 'quickPaths',
          type: othersType,
        }),
        sourceID,
        type: othersType,
      });
    });

    clearFilters();
    setIsSearchValue(true);
  };

  const [activeTab, setActiveTab] = useState(TYPE_LOCATION);
  const [userClickTab, setUserClickTab] = useState(false);

  let isVisible = useMemo(() => {
    let status = false;
    let activeType = TYPE_LOCATION;

    if (filters[firstSourceID]?.[COMBINED_FILTERS]?.show || filters[firstSourceID]?.[TYPE_LOCATION]?.show) {
      status = true;
    } else if (filters[firstSourceID]?.[TYPE_OTHERS]?.show) {
      activeType = TYPE_OTHERS;
      status = true;
    } else if (filters[firstSourceID]?.[TYPE_TIME]?.show) {
      activeType = TYPE_TIME;
      status = true;
    }

    if (!userClickTab && status) {
      setActiveTab(activeType);
    }

    return status;

    // eslint-disable-next-line
  }, [firstSourceID, filters]);

  useEffect(() => {
    if (!isVisible) setUserClickTab(false);
  }, [isVisible]);

  const filterProps = {
    dimensions: source.Dimensions?.Items || [],
    sourceName: source.SourceName,
    sourceID: source.SourceId,
    applyModalTo: applyModalTo || null,
    view_name,
    raw: true,
    activeTab,
    isVisible,
    height: applyModalTo ? 200 : '50vh',
  };

  const filterTime = DatasetDimension({
    category: TYPE_TIME,
    type: timeType,
    title: t('Search_Time_Range'),
    placeholder: t('Search_Time_Range'),
    icon: 'Clock',
    raw: true,
    ...filterProps,
  });

  const filterOthers = DatasetDimension({
    category: TYPE_OTHERS,
    type: othersType,
    title: t('Select_Dimensions'),
    placeholder: t('Select_Dimensions'),
    icon: 'source',
    mode: 'static',
    raw: true,
    upperLimit: 500,
    ...filterProps,
  });

  let availableDimension = [];

  filterProps?.dimensions?.forEach(({ DimensionType, ID }) => {
    availableDimension.push(`${DimensionType}`.toLowerCase());
    availableDimension.push(`${ID}`.toLowerCase());
  });

  let hasLocation = isStandarized && availableDimension.includes(StateName.toLowerCase());
  let hasTypeTime = availableDimension.includes(TYPE_TIME);
  let hasTypeOtherDimensions = availableDimension.includes(TYPE_OTHERS);

  let actions = [];
  let cancels = [];

  let totalSelected =
    getAny({
      ...targetParams,
      key: 'checked',
    }).length +
    getAny({
      ...targetParams,
      key: 'checked',
      type: timeType,
    }).length +
    getAny({
      ...targetParams,
      key: 'checked',
      type: othersType,
    });

  if (!exclude.includes(FILTER_INPUTS.time)) {
    actions.push({
      key: TYPE_TIME,
      type: timeType,
      disabled: !hasTypeTime,
      tab: (
        <CustomFilterButton
          toolTip={!hasTypeTime ? t('TimeFilterNotAvailble') : ''}
          title={'Time'}
          count={timeCount}
          key="time"
          icon="clock"
          minWidth={100}
          hideOnZero={true}
          disabled={!hasTypeTime}
        />
      ),
      content: !hasTypeTime ? null : filterTime?.component,
    });

    if (filterTime?.onCancel) cancels.push(filterTime.onCancel);
  }

  if (!exclude.includes(FILTER_INPUTS.OtherDimensionFilter)) {
    actions.push({
      key: TYPE_OTHERS,
      type: othersType,
      disabled: !hasTypeOtherDimensions,
      tab: (
        <CustomFilterButton
          toolTip={!hasTypeOtherDimensions ? t('OtherDimensionsNotAvailable') : ''}
          title={t('OtherDimensions')}
          count={otherDimensionCount}
          key="other-dimensions"
          icon="FilePin"
          hideOnZero={true}
          disabled={!hasTypeOtherDimensions}
        />
      ),
      content: !hasTypeOtherDimensions ? null : filterOthers?.component,
    });

    if (filterOthers?.onCancel) cancels.push(filterOthers.onCancel);
  }

  const {
    selected: selectedLocations,
    isMobile,
    loacationMode,
    setLoacationMode,
  } = useLocationSelectedFilter({
    checked: getAny({
      sourceID: source.SourceId,
      type: TYPE_LOCATION,
      key: 'checked',
    }),
  });

  const closeModal = () => {
    resetToPrevious({
      types: [...actions, { key: TYPE_LOCATION }].map(({ key, type }) => type || key),
    });
    if (cancels.length) {
      cancels.forEach((i) => i?.());
    }
  };

  const getInitialTab = () => {
    if (hasLocation) return TYPE_LOCATION;
    else if (hasTypeTime) return TYPE_TIME;
    else return TYPE_OTHERS;
  };

  const hasNoFilter = !hasLocation && !hasTypeTime && !hasTypeOtherDimensions;
  return (
    <>
      <ActionButton
        onClick={() => {
          clearFilters();
          toggleModal();
        }}
        totalFilters={totalFilters}
        disabled={hasNoFilter}
        toolTip={hasNoFilter && t('NoFilters')}
      />

      <Modal
        title={t('Filters')}
        onCancel={closeModal}
        keyboard={false}
        footer={[
          activeTab === TYPE_LOCATION && isMobile ? (
            <Button
              key={'mobile-button'}
              type="primary"
              ghost
              onClick={() =>
                setLoacationMode((ps) =>
                  LOCATION_MODES.Available === ps ? LOCATION_MODES.Selected : LOCATION_MODES.Available
                )
              }
            >
              {loacationMode === LOCATION_MODES.Available ? (
                <span>
                  {t('ViewSelected')}
                  {(selectedLocations?.length && <Badge count={selectedLocations?.length} className="ml-1" />) || ''}
                </span>
              ) : (
                t('ViewAvailable')
              )}
            </Button>
          ) : null,
          <Button
            onClick={onApply}
            key="apply-fillter"
            type="primary"
            className="rounded-common"
            disabled={totalSelected < 1}
          >
            {t('Apply')}
          </Button>,
          <Button
            onClick={onFilterReset}
            type="secondary"
            key={'reset-filters'}
            className="rounded-common"
            disabled={totalFilters < 1}
          >
            {t('ResetFilters')}
          </Button>,
        ]}
        visible={isVisible !== false}
        closeIcon={<CloseButton />}
        width={'80%'}
        style={{ top: 25 }}
        className={`combined-filter-modal ${isMobile ? 'full-screen-modal mobile' : ''}`}
        getContainer={applyModalTo}
      >
        <Tabs
          onTabClick={(key) => {
            setUserClickTab(true);
            setActiveTab(key);
          }}
          activeKey={!hasLocation && !userClickTab ? getInitialTab() : activeTab}
        >
          <TabPane
            key={TYPE_LOCATION}
            tabKey={TYPE_LOCATION}
            disabled={!hasLocation}
            tab={
              <CustomFilterButton
                toolTip={!hasLocation ? t('LocationFilterNotAvailable') : ''}
                title={t('Locations')}
                count={locationCount}
                key="location"
                icon="location"
                hideOnZero={true}
                disabled={!hasLocation}
              />
            }
          >
            <LocationFilter
              category={TYPE_LOCATION}
              type={locationType}
              mergeDimensions={source.Dimensions?.Items || []}
              title={t('Location')}
              placeholder={t('Search_Location')}
              icon={'MapPin'}
              isMobile={isMobile}
              mode={loacationMode}
              isSearchValue={isSearchValue}
              resetAllFilters={resetAllFilters}
              classnames={classnames}
              granularity={granularity}
              onSearchDone={() =>
                loacationMode !== LOCATION_MODES.Available && setLoacationMode(LOCATION_MODES.Available)
              }
              {...filterProps}
              isVisualisation={isVisualisation}
            />
          </TabPane>

          {actions.map(({ tab, content, key, disabled }) => (
            <TabPane disabled={disabled} tab={tab} tabKey={key} key={key}>
              {content?.()}
            </TabPane>
          ))}
        </Tabs>
      </Modal>
    </>
  );
};

export default Combined;
