import React, { Fragment, useState, useContext } from 'react';
import { EditTwoTone } from '@ant-design/icons';

import {
  CHART_TYPE_MAPVIEW,
  CHART_TYPE_PIVOT,
  GridFixedSpan,
  ACTIVITIES,
  BLUEBROWN,
  INITIAL_CHART_GRIDS_AND_LINES,
  MAPBLUE,
  DATASET_NAME_LENGTH,
} from '../../../constants';
import { voidFunction } from '../../../utils';
import { postClickStreamEvent } from '../../../services';
import Graph from './Graph';
import { Row, Col, Typography, Table, Input } from '../../../components';
import ExportTableToCSVButton from '../../ExportTableToCSVButton';
import Context from '../../../context';
import CustomIcon from '../../CustomIcon';
import MapOnVisualize from '../../MapOnVisualize';
import Pivot from '../../Pivot';
import ChartOptions from './ChartOptions';
import { getDatasetHeaderColumns } from '../Columns';

const { TextArea } = Input;

const VisualizationCharts = ({
  selectedChart,
  chart = false,
  loadingYears = false,
  years = [],
  charTitle = {},
  onChangeAggregateOverTime = voidFunction,
  onChangeYear = voidFunction,
  sourceID,
  filterSetting = {},
  colorPallet = {},
  selectedPallet,
  setSelectedPallet = voidFunction,
  aggregateOverTime = false,
  gridAndLine = [],
  setGridAndLines = voidFunction,
  chartTableData = {},
  setSortBy,
  sorted = {},
  initialSortBy = {},
  setChartTitle = voidFunction,
  domain,
  ...rest
}) => {
  const [context, ,] = useContext(Context);
  const [showTitle, setShowTitle] = useState(false);
  const [editableChart, setEditableChart] = useState(false);
  const { type } = selectedChart;
  const sourceIDList = ['string', 'number'].includes(typeof sourceID) ? String(sourceID).split(',') : sourceID;

  let Visualize = false;
  let isMap = type === CHART_TYPE_MAPVIEW;
  let isPivot = type === CHART_TYPE_PIVOT;

  if (type || chart) {
    switch (type) {
      case CHART_TYPE_PIVOT:
        Visualize = Pivot;
        break;

      case CHART_TYPE_MAPVIEW:
        Visualize = MapOnVisualize;
        break;

      default:
        Visualize = Graph;
        break;
    }
  }

  if (!Visualize) {
    Visualize = () => (
      <Row justify="center">
        <CustomIcon type={'Visulaize'} width={'100%'} height={300} />
      </Row>
    );
  }

  const onDownload = (status) => {
    setShowTitle(status);
    if (status) {
      let params = {};

      sourceIDList.forEach((ID, i) => {
        params[`datasetid_${i + 1}`] = ID;
      });

      postClickStreamEvent({
        activity: ACTIVITIES.DOWNLOAD_VISUALIZATION,
        userid: context?.profile?.email || '',
        ...params,
      });
    }
  };

  const dataColumns = getDatasetHeaderColumns(chartTableData.columns || [], rest.merge);
  const resetChartOptionsToDefault = () => {
    setSelectedPallet(type === CHART_TYPE_MAPVIEW ? MAPBLUE : BLUEBROWN);
    setGridAndLines(INITIAL_CHART_GRIDS_AND_LINES);
    setSortBy(initialSortBy);
  };

  return (
    <Fragment>
      <Row>
        <Col {...GridFixedSpan.size.ten}>
          <div className="text-capitalize mb-3">
            {charTitle.title && chartTableData?.data?.length ? (
              <Typography>
                {!editableChart && (
                  <div className="d-flex ">
                    <div>
                      <Typography.Paragraph ellipsis={{ rows: 2 }} className="m-0 font-weight-bold">
                        {type === CHART_TYPE_PIVOT ? `${charTitle?.by} - Pivot` : charTitle?.title}
                      </Typography.Paragraph>
                    </div>
                    <div>
                      <EditTwoTone className="cursor pl-1" onClick={() => setEditableChart(true)} />
                    </div>
                  </div>
                )}
                {editableChart && (
                  <TextArea
                    autoFocus
                    showCount
                    maxLength={DATASET_NAME_LENGTH}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value?.trim())
                        setChartTitle((ps) => ({
                          ...ps,
                          title: value,
                          by: type === CHART_TYPE_PIVOT ? value : `${charTitle?.by}`,
                        }));
                    }}
                    onBlur={(e) => {
                      setEditableChart(false);
                    }}
                    defaultValue={type === CHART_TYPE_PIVOT ? `${charTitle?.by} - Pivot` : charTitle?.title}
                  />
                )}
              </Typography>
            ) : null}
          </div>
        </Col>
        {((chart || isMap) && chartTableData?.data?.length && (
          <Col {...GridFixedSpan.size.full} className="text-right postion-relative">
            <ChartOptions
              sourceID={sourceID}
              filterSetting={filterSetting}
              colorPallet={colorPallet}
              selectedPallet={selectedPallet}
              setSelectedPallet={setSelectedPallet}
              gridAndLine={gridAndLine}
              setGridAndLines={setGridAndLines}
              setSortBy={setSortBy}
              type={type}
              sorted={sorted}
              onlyMapPallet={isMap}
              onReset={resetChartOptionsToDefault}
              onlyPallet={isMap}
            />
          </Col>
        )) ||
          null}
      </Row>

      <Visualize
        loadingYears={loadingYears}
        years={years}
        onChangeAggregateOverTime={onChangeAggregateOverTime}
        onChangeYear={onChangeYear}
        chart={chart}
        aggregateOverTime={aggregateOverTime}
        divId={`${(sourceID || '').toString()}-chart-${type}`}
        gridAndLine={gridAndLine}
        charTitle={type === CHART_TYPE_PIVOT ? `${charTitle?.by} - Pivot` : `${charTitle.title} ${charTitle.by}`}
        showTitle={showTitle}
        sourceData={chartTableData}
        selectedPallet={selectedPallet}
        colorPallet={colorPallet.Plotly}
        sourceID={sourceIDList}
        onDownload={onDownload}
        chartType={type}
        domain={domain}
        {...rest}
      />

      {(!isPivot && chartTableData?.data?.length && Visualize && (
        <div className="mt-3 mb-3">
          <ExportTableToCSVButton
            columns={dataColumns}
            rows={chartTableData.data || []}
            fileName={`${charTitle?.title}-${charTitle?.by}`}
          />
          <Table
            dataSource={(chartTableData.data || []).map((item, index) => ({ ...item, key: `${index}` }))}
            scroll={{ y: 440, x: '100%' }}
            pagination={false}
            columns={dataColumns}
            bordered
          />
        </div>
      )) ||
        null}
    </Fragment>
  );
};

export default VisualizationCharts;
