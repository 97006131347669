export const Country = 'Country';
export const StateName = 'StateName';
export const DistrictName = 'DistrictName';
export const BlockName = 'BlockName';

export const SubDistrictName = 'SubDistrictName';
export const ULB_RLB_VillageName = 'ULB_RLB_VillageName';
export const VillageTownName = 'VillageTownName';

export const CountryCode = 'CountryCode';
export const StateCode = 'StateCode';
export const DistrictCode = 'DistrictCode';
export const SubDistrictCode = 'SubDistrictCode';
export const BlockCode = 'BlockCode';
export const ULB_RLB_VillageCode = 'ULB_RLB_VillageCode';
export const ULB_RLB_Village = 'ULB_RLB_Village';
export const LocationCodes = [CountryCode, StateCode, DistrictCode, SubDistrictCode, BlockCode, ULB_RLB_VillageCode];
export const LocationNames = {
  StateName: StateCode,
  DistrictName: DistrictCode,
  SubDistrictName: SubDistrictCode,
  BlockName: BlockCode,
  ULB_RLB_VillageName: ULB_RLB_VillageCode,
};

export const Villages = 'Villages';
export const Village = 'Village';

export const SubDistricts = 'SubDistricts';
export const Districts = 'Districts';
export const District = 'District';

export const Blocks = 'Blocks';

export const Block = 'Block';
export const Countries = 'Countries';
export const SubDistrict = 'SubDistrict';
export const States = 'States';
export const State = 'State';

export const BlockOptions = {
  totalKey: Blocks,
  lookUpKey: Blocks,
  ipLocation: Block,
  isLastNode: true,
  lookUpID: BlockName,
  column: BlockCode,
  singular: Block,
};

export const DistrictOptions = {
  totalKey: Districts,
  lookUpKey: SubDistricts,
  lookUpID: SubDistrictName,
  column: DistrictCode,
  ipLocation: SubDistrict,
  singular: District,
};

export const villageOptions = {
  totalKey: Villages,
  lookUpID: ULB_RLB_VillageName,
  isLastNode: true,
  column: ULB_RLB_VillageCode,
  singular: Village,
};

export const translationKeys = {
  [VillageTownName]: {
    ...villageOptions,
  },
  [ULB_RLB_VillageName]: {
    ...villageOptions,
  },
  [SubDistrictName]: {
    totalKey: SubDistricts,
    lookUpKey: Villages,
    lookUpID: ULB_RLB_VillageName,
    ipLocation: ULB_RLB_Village,
    column: SubDistrictCode,
    singular: SubDistrict,
  },
  [BlockName]: {
    ...BlockOptions,
  },
  [DistrictName]: [
    {
      ...DistrictOptions,
    },
    {
      totalKey: Blocks,
      lookUpKey: Blocks,
      ipLocation: Block,
      lookUpID: BlockName,
      column: DistrictCode,
      singular: District,
    },
  ],
  [StateName]: {
    totalKey: States,
    lookUpKey: Districts,
    lookUpID: DistrictName,
    column: StateCode,
    ipLocation: District,
    singular: State,
  },
  [Country]: {
    totalKey: Countries,
    lookUpKey: States,
    lookUpID: StateName,
    column: CountryCode,
    ipLocation: Districts,
    singular: Country,
  },
  [`${DistrictName}-other`]: { ...DistrictOptions },
};
