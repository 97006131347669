import React, { useEffect } from 'react';
import { t } from 'i18next';
import { SearchOutlined } from '@ant-design/icons';
import { Typography, Tag, List, Spin } from '../../../components';
import { emptyContent } from '../../../shared/Empty/EmptyContent';
import s from '../../SearchBar/index.module.less';
import { Country } from '../constant';

const { Text, Paragraph } = Typography;

const LocationSearchPopup = ({
  data = [],
  onSelect = () => {},
  onFilterChange = () => {},
  width = 50,
  showFilters = false,
  searchFilters = [],
  isLoading = false,
}) => {
  useEffect(() => {
    const searchList = document.getElementById('searchList');
    searchList.scrollTop = 0;
  }, [data]);

  const filterChange = (filter) => {
    onFilterChange(filter);
  };

  const onSelectItem = (item) => {
    onSelect(item);
  };

  return (
    <div
      className={`${s.searchWrapper} searchWrapperCustom`}
      style={{
        width: typeof width === 'string' ? width : `${width}vw`,
      }}
    >
      <Spin spinning={isLoading}>
        {showFilters && (
          <div className={`${s.recent} py-3 mb-0`}>
            <Paragraph key="filterHeading" type="secondary" className={s.spacers}>
              {t('ImLookingFor')}
            </Paragraph>
            <Paragraph key="filterIteration" className={`${s.hint} ${s.spacers} mb-2`}>
              {searchFilters?.length > 0 &&
                searchFilters.map(
                  (f, ind) =>
                    f.ID !== Country && (
                      <Tag
                        key={`${f.ID}-${ind}`}
                        className={`${s.filterHintItem} font-14 rounded-common mb-1 mr-3`}
                        onClick={() => filterChange(f)}
                      >
                        {f.DisplayName}
                      </Tag>
                    )
                )}
            </Paragraph>
          </div>
        )}
        <div className="pb-2">
          <List
            id="searchList"
            className={s.list}
            bordered={false}
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item className="border-0 p-0" key={`${item?.LGDName} ${index}`} onClick={() => onSelectItem(item)}>
                {item?.LGDName ? (
                  <div className={`${s.meta} ${s.listItem} location-recommedndationListItem`}>
                    <Text className={`font-weight-500`} title={item?.LGDName}>
                      {item?.LGDName}
                      <Text className={`ml-1 font-12  font-weight-normal location-recommedndationList-MetaInfo`}>
                        <Text className={`${s.displayItem} pl-1 pr-2 location-recommedndationList-StyleType`}></Text>
                        {item?.Display}
                      </Text>
                    </Text>

                    <Text className={`${s.recommendations} text-right`}>
                      <span title={item?.LocationType}>
                        <Tag className={`font-14 mr-0 ${s.recommendationsText} border-0`}>{item?.LocationType}</Tag>
                      </span>
                    </Text>
                  </div>
                ) : (
                  <div className={`${s.meta} ${s.listItem} location-recommedndationListItem`}>
                    <Text className={`font-weight-500`} title={item}>
                      <Text className={`text-muted font-14 font-weight-normal`}>
                        <SearchOutlined size={16} className="mr-2" />
                      </Text>
                      {item?.toUpperCase()}
                    </Text>
                  </div>
                )}
              </List.Item>
            )}
            itemLayout="horizontal"
            locale={emptyContent}
          />
        </div>
      </Spin>
    </div>
  );
};

export default LocationSearchPopup;
