import { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';

import { TableInput } from '../CustomInputs';
import { message } from '../../components';
import { voidFunction } from '../../utils';
import { loadDimensionData, calculateNewState, clearChecked } from './DimensionHelpers';
import { useFilterByCategory } from './DimensionProviders';
import { LocationNames } from '../LocationFilter/constant';

const DynamicDimensionFilter = ({
  sourceID,
  height = 200,
  onSelect = voidFunction,
  category = 'location',
  dimensions = [],
  mergeDimensions = [],
  placeholder = '',
  quickPaths = {},
  view_name = '',
  resetSearch = false,
  multipleDataset = false,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearch] = useState({ search: [], searchID: '' });
  const [searchedEmpty, setSearchedEmpty] = useState(false);
  const { filteredDimensions } = useFilterByCategory(dimensions, category, mergeDimensions);

  const paths = useMemo(() => {
    if (Object.keys(quickPaths).length) {
      setSearch((ps) => ({ ...ps, load: true }));
    } else {
      setData(clearChecked);
    }

    return quickPaths;
  }, [quickPaths]);

  useEffect(() => {
    if (!searchQuery.searchID && !data.length && filteredDimensions.length && !searchedEmpty) {
      let entries = Object.entries(paths);
      let levels = false;

      if (entries.length) {
        levels = {};
        for (let [searchID, item] of entries) {
          let level = item.level || 1;
          if (!levels[level]) {
            levels[level] = { values: [], searchID };
          }

          levels[level]['values'] = [...levels[level]['values'], ...Object.keys(item.selected)];
        }
      }

      const load = (params = {}) => {
        let {
          search = searchQuery.search,
          ID = searchQuery.searchID,
          onLoad = ({ data = [], dimensionQuery = {} }) => {
            setSearchedEmpty(true);
            setData((ps) => calculateNewState({ ps, data, dimensionQuery }));
          },
          setLoader = voidFunction,
        } = params;

        return loadDimensionData({
          sourceID,
          dimensions: filteredDimensions,
          search,
          ID,
          setColumns,
          setData: onLoad,
          setLoading: setLoader,
          view_name,
          multipleDataset,
        });
      };

      if (levels) {
        setLoading(true);
        load({
          setLoader: voidFunction,
          onLoad: async ({ data = [], dimensionQuery = {} }) => {
            setData((ps) => calculateNewState({ ps, data, dimensionQuery, quickPaths: paths }));
            for (let [itemLevel, item] of Object.entries(levels)) {
              await load({
                search: item.values
                  .filter((value) => !String(value).includes('order'))
                  .map((value) => `'${value}'`)
                  .join(','),
                ID: item.searchID,
                // eslint-disable-next-line
                onLoad: ({ data = [], dimensionQuery = {} }) => {
                  setData((ps) => calculateNewState({ ps, data, dimensionQuery, quickPaths: paths }));
                  levels[itemLevel]['loaded'] = true;
                },
              });
            }

            setLoading(false);
          },
        });
      } else {
        load({
          setLoader: setLoading,
        });
      }
    }
    // eslint-disable-next-line
  }, [paths, filteredDimensions, sourceID, searchQuery, data, view_name]);

  useEffect(() => {
    if (searchQuery.searchID && searchQuery.load) {
      setSearch((ps) => ({ ...ps, load: false }));
      loadDimensionData({
        sourceID,
        dimensions: filteredDimensions,
        search: searchQuery.search,
        ID: searchQuery.searchID,
        setLoading,
        setColumns,
        setData: ({ data = [], dimensionQuery = {} }) => {
          setData((ps) => calculateNewState({ ps, data, dimensionQuery, quickPaths: paths }));
        },
        onHault: () => {
          setData((ps) => calculateNewState({ ps: [...ps], data: [...ps], dimensionQuery: {}, quickPaths: paths }));
        },
        view_name,
        setError: () => {
          message.error(t('ServerError'));
          setData((ps) => calculateNewState({ ps, quickPaths: paths }));
        },
        multipleDataset,
      });
    }

    //eslint-disable-next-line
  }, [sourceID, filteredDimensions, searchQuery, paths, view_name]);

  const handleSelect = (selected, searchID) => {
    const search = [];
    const idKey = searchID + 'ID';
    const updateStatus = {};
    const dimension = filteredDimensions.find((data) => data.ID === searchID);
    const removeColumns = [];
    let onlyRemovableKeys = [];
    let removeSubColumns = [];
    let removeSubColumnKey = '';

    if (dimension) {
      filteredDimensions.forEach((data) => {
        if (
          !String(data.ID || '')
            .toLowerCase()
            .includes('code') &&
          data.hierarchy > dimension.hierarchy
        ) {
          onlyRemovableKeys.push(data.ID);
          removeColumns.push(data.ID);
          removeColumns.push(data.ID + 'ID');
          removeColumns.push(data.ID + 'Checked');
        }
      });
    }

    selected.forEach((data) => {
      if (data[idKey] && data[searchID + 'Checked']) {
        let locationKeys = Object.keys(LocationNames);
        const isIndex = locationKeys?.indexOf(searchID);

        if (isIndex !== -1) {
          let format = {};
          locationKeys?.slice(0, isIndex + 1).forEach((item) => {
            format = { ...format, [LocationNames[item]]: data[item + 'ID'] };
          });
          search.push(format);
        } else {
          if (locationKeys.includes(searchID)) search.push({ [LocationNames[searchID]]: data[idKey] });
          else search.push(isNaN(data[idKey]) ? data[idKey] : +data[idKey]);
        }
      }

      let value = data[`${searchID}ID`] || data[`${searchID}`];
      let status = data[`${searchID}Checked`] || data.checked[searchID] || false;
      updateStatus[value] = { status };
    });

    setData((ps) => {
      let state = cloneDeep(ps);
      let newState = state.map((item) => {
        let value = item[`${searchID}ID`] || item[`${searchID}`];
        item[`${searchID}Checked`] = updateStatus?.[value]?.status || false;
        if (item.checked?.[searchID]) item.checked[searchID] = updateStatus[value]?.status || false;

        if (!item[`${searchID}Checked`]) {
          let codeID = `${searchID}ID`;
          if (item[codeID]) {
            removeSubColumnKey = searchID.replace('Name', 'Code');
            removeSubColumns.push(item[codeID]);
          }
        }

        return item;
      });

      /** remove previous selected child values **/
      if (removeSubColumnKey && removeColumns?.length) {
        let selectedCounts = {};
        let totalRecords = {};

        newState = newState.map((item, index) => {
          onlyRemovableKeys.forEach((column) => {
            const remove = (target) => {
              delete target[`${column}`];
              delete target[`${column}ID`];
              delete target[`${column}Checked`];

              if (target['checked']?.[column]) {
                delete target['checked'][column];
              }
            };

            if (removeSubColumns.includes(item[`${column}${removeSubColumnKey}`])) {
              remove(item);
            }

            let selectedTarget = selected[index];

            if (removeSubColumns.includes(selectedTarget?.[`${column}${removeSubColumnKey}`])) {
              remove(selectedTarget);
            }

            if (!selectedCounts[column]) {
              selectedCounts[column] = 0;
            }

            if (!totalRecords[column]) {
              totalRecords[column] = 0;
            }

            if (typeof item[`${column}Checked`] !== 'undefined' && item[column]) {
              totalRecords[column] += 1;
            }

            if (item[`${column}Checked`] === true) {
              selectedCounts[column] += 1;
            }
          });

          return item;
        });

        let removeColumnOnParent = Object.entries(totalRecords);
        let delCol = [];
        if (removeColumnOnParent?.length) {
          for (let [key, value] of removeColumnOnParent) {
            if (!value) {
              delCol.push(key);
            } else if (selectedCounts[key]) {
              break;
            }
          }

          setColumns((psCols) => {
            return psCols.filter((col) => !delCol.includes(col.dataIndex));
          });
        }
      }

      return newState;
    });

    if (search.length) {
      setSearch({
        search: Array.isArray(search) ? search : `'${search.join("','")}'`,
        searchID: searchID,
        load: false,
      });
    } else {
      setSearch({ search: '', searchID: '', load: false });
      setColumns((ps) => {
        if (removeColumns.length) {
          setData((ps) => {
            const newState = [...ps];
            const state = [];
            newState.forEach((item) => {
              let dataObject = {};
              for (const [key, value] of Object.entries(item)) {
                if (!removeColumns.includes(key)) {
                  if (key === searchID + 'Checked') {
                    dataObject[key] = false;
                  } else {
                    dataObject[key] = value;
                  }
                }
              }

              if (Object.keys(dataObject).length) {
                state.push(dataObject);
              }
            });

            return state;
          });
        }

        if (removeColumns.length) {
          return ps.filter((d) => !removeColumns.includes(d.dataIndex));
        }

        return ps;
      });
    }

    onSelect(selected, searchID);
  };

  return (
    <TableInput
      columns={columns}
      width={'auto'}
      dataSource={data}
      height={height}
      loading={loading}
      onSelect={handleSelect}
      showNumberOfRecord={true}
      placeholder={placeholder}
      disableTranslation={true}
      splitColumns={true}
      resetSearch={resetSearch}
    />
  );
};

export default DynamicDimensionFilter;
