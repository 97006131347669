import { t } from 'i18next';

import { Button, Tag, Tooltip } from '../../../../components';
import { voidFunction } from '../../../../utils';
import CustomIcon from '../../../CustomIcon';

const ActionButton = ({ onClick = voidFunction, totalFilters, disabled = false, toolTip = false }) => {
  const action = (
    <Button
      disabled={disabled}
      icon={<CustomIcon type={'Filter'} width={16} verticalAlign={4} className="ml-n1" />}
      onClick={onClick}
      type="outline"
      className={
        'joyride-datasetdata-stepTwo d-flex btn-gray  pr-2 rounded joyride-MergeResultData-Step3 joyride-MergeResultDataVisualize-Step1'
      }
    >
      {t('Filters')}
      {(totalFilters && <Tag className="ml-2 mr-n1 rounded bg-primary">{totalFilters}</Tag>) || null}
    </Button>
  );

  if (toolTip) {
    return <Tooltip title={toolTip}>{action}</Tooltip>;
  }

  return action;
};

export const CustomFilterButton = ({
  onClick,
  title,
  count,
  icon = '',
  iconWidth = 20,
  iconHeight = 20,
  hideOnZero = false,
  toolTip = false,
  disabled = false,
}) => {
  const action = (
    <div onClick={onClick} className={`cursor-pointer position-relative d-flex align-items-center border-0`}>
      <CustomIcon disabled={disabled} type={icon} width={iconWidth} height={iconHeight} hover={true} className="mr-1" />

      {t(title)}
      {hideOnZero && !count ? null : (
        <Tag className="tag-default rounded-circle border-0 ml-2">{count > 99 ? `${count}+` : count}</Tag>
      )}
    </div>
  );

  if (toolTip) {
    return <Tooltip title={toolTip}>{action}</Tooltip>;
  }
  return action;
};

export default ActionButton;
