import { voidFunction } from '../../utils';
import AllCustomIcons from './icons';

const CustomIcon = ({
  type,
  width = 20,
  height = 20,
  onClick = false,
  verticalAlign = 2,
  hover = false,
  children,
  ...rest
}) => {
  const IconComponent = AllCustomIcons[type?.charAt(0).toUpperCase() + type?.slice(1)];
  if (!IconComponent) return null;

  return (
    <span
      className={`icon-color ${hover ? 'icon-color-hover' : ''} anticon customIcon ${
        (onClick && 'cursor-pointer') || ''
      } ${rest.className || ''}`}
      style={{ verticalAlign: `-0.${verticalAlign}em`, color: rest.disabled ? '#ccc' : '' }}
    >
      <IconComponent
        disabled={rest.disabled}
        width={width}
        height={height}
        onClick={onClick || voidFunction}
        {...rest}
      />
      {children}
    </span>
  );
};

export default CustomIcon;
