import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Excel } from 'antd-table-saveas-excel';
import DOMPurify from 'dompurify';
import { InfoCircleOutlined, DownloadOutlined, ArrowUpOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/browser';
import { t } from 'i18next';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import maxBy from 'lodash/maxBy';

import { buildFiltersQuery, getMetaDataValues, postClickStreamEvent } from '../../../services';
import { Button, Typography, Row, Col, Modal, message, Input, Spin, Tooltip, Table } from '../../../components';
import {
  GridFixedSpan,
  TYPE_INDICATORS,
  TYPE_LOCATION,
  TYPE_OTHERS,
  TYPE_TIME,
  VARIABLE_TYPES,
  ACTIVITIES,
  TYPE_YEAR,
} from '../../../constants';
import DimensionIcon from '../../CustomIcon/DimensionIcon';
import MergePlaceholder from '../Merge/MergePlaceholder';
import Context from '../../../context';
import s from './index.module.less';
import {
  cleanName,
  roundDecimalPlaces,
  parseStringToNumber,
  characterLimit,
  parseDataTableYear,
} from '../../../utils/common';
import { CloseButton } from '../../../shared';
import { getDatasetHeaderColumns } from '../Columns';
import CustomIcon from '../../CustomIcon';
import { SimplePagination } from '../../Pagination';
import MetaLongDescription from './MetaLongDescription';
import { emptyContent } from '../../../shared/Empty/EmptyContent';

const WeighingAggregration = ['notapplicable'];
const { Text } = Typography;

const MissingText = ({ value = 0 }) => (
  <Typography.Text type={Number(value) ? 'default' : 'secondary'}>
    {t('Missing')} :{' '}
    <Typography.Text type={Number(value) ? 'danger' : 'secondary'}>
      <strong>{value}</strong>
    </Typography.Text>
  </Typography.Text>
);

const MetaHeader = ({ title, icon, MergingSourceName = '', iconComponent = null, iconProperties = {} }) => {
  let iconProps = {
    ...iconProperties,
    type: icon,
    verticalAlign: 4,
  };

  return (
    <div className={s.keyValues}>
      <Row>
        <Col {...GridFixedSpan.size.four}>
          <span className="mr-2">{iconComponent ? iconComponent(iconProps) : <DimensionIcon {...iconProps} />}</span>
          <span className="ml-n1">{t(title)}</span>
        </Col>
        <Col {...GridFixedSpan.size.eight}>{MergingSourceName}</Col>
      </Row>
    </div>
  );
};

const Metatab = (props) => {
  const {
    view_name = '',
    profile = [],
    details = {},
    merge = false,
    colorCode = 0,
    showHeader = true,
    showScroll = true,
    reference = null,
    sourceIDList = [],
    subHeader = null,
    enableSourceName = false,
    isQuickMerge = false,
    // fixedFilters = {}, WIP:
    years = {},
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentItem, setCurrentItem] = useState({});
  const [isSingleSource, setSingleSource] = useState(false);
  const [sourceID, setSourceID] = useState(false);

  const [loadingValues, setLoadingValues] = useState(false);
  const [context, setContext] = useContext(Context);
  const [searchTerm, setSearchTerm] = useState('');
  const profileColsTemp = [];
  const profileData = profile.map((x, index) => ({ ...x, key: index }));
  const record = profile.length > 0 ? profileData[0] : {};
  const [exportToExcel, setIsExportToExcel] = useState(false);

  useEffect(() => {
    if (context.isAuth && exportToExcel) getExportToExcel();

    // eslint-disable-next-line
  }, [context.isAuth, exportToExcel]);

  Object.keys(record).forEach((field) => {
    if (field !== 'Frequency') {
      profileColsTemp.push({
        title: field,
        dataIndex: field,
        key: field,
        width: 150,
      });
    }
  });

  const [meta, setMeta] = useState({ data: [], cols: [], title: '', unique: 0, missing: 0, NextPagination: 0 });
  const [totalRecords, setTotalRecords] = useState(0);

  const [showMeta, setShowMeta] = useState(false);
  const [metaError, setMetaError] = useState(false);

  // eslint-disable-next-line
  const waitAndCall = useCallback(
    debounce((item, singleSource, term, id, setCurrent, error) => {
      setCurrent(1);
      valuesClick({ item, singleSource, page: 1, term, id, error });
    }, 500),
    []
  );

  const handleSearch = (e) => {
    setSearchTerm(DOMPurify.sanitize(e.target.value));
    waitAndCall(currentItem, isSingleSource, e.target.value, sourceID, setCurrentPage, metaError);
  };

  const valuesClick = async ({
    item,
    singleSource = false,
    page = 1,
    term = '',
    id = false,
    error = false,
    offset = 0,
  }) => {
    const emptyMeta = {
      data: [],
      cols: [],
      title: item['Variable Name'],
      unique: item['Unique'],
      missing: item['Missing'],
    };
    let ID = item.sourceID ? item.sourceID : sourceIDList.length ? sourceIDList : id || [details.SourceId];
    let column = item['ID'];

    if (!sourceID) setSourceID(ID);
    if (currentPage !== page) setCurrentPage(page);
    if (!showMeta) setShowMeta(true);
    if (!isEqual(item, currentItem)) setCurrentItem(item);
    if (isSingleSource !== singleSource) setSingleSource(singleSource);

    const params = {
      ip_sourceid: ID,
      ip_columns: column,
      view_name: view_name ? view_name : '',
      limit: 100,
      page,
      offset,
    };

    if (item.appliedFilters) {
      const { ip_filter } = await buildFiltersQuery({
        appliedFilters: item.appliedFilters,
        datasetID: [item.sourceID],
      });
      params['ip_search'] = ip_filter;
      params['ip_sourceid'] = [item.source_code];
    }

    if (singleSource) {
      params['ip_sourceid'] = [item.source_code];
      params['view_name'] = '';
    }

    if (searchTerm || term) {
      params['ip_filter'] = [
        {
          value: parseStringToNumber(term) || parseStringToNumber(searchTerm),
        },
      ];
    }

    if (isQuickMerge) {
      if (!params['ip_search']) {
        params['ip_search'] = [];
      }

      //WIP: commented due to some issue
      // (fixedFilters[ID] || [])
      //   .filter(({ key }) => key === item.ID)
      //   .forEach((filterItem) => {
      //     params['ip_search'].push(filterItem);
      //   });

      if (item.ID?.toLowerCase() === 'year') {
        const maxYear = maxBy(years[ID] || [], 'ID');

        if (maxYear)
          params.ip_search.push({
            key: item.ID,
            value: [parseStringToNumber(maxYear.ID)],
            type: 'dimension',
            operator: 'IN',
          });
      }
    }

    try {
      setLoadingValues(true);

      const {
        data: { records = [], IsError = true, TotalRecords = 0, NextPagination },
      } = await getMetaDataValues(params);

      if (!IsError) {
        if (page === 1) setTotalRecords(TotalRecords);

        const tempCols = getDatasetHeaderColumns(
          Object.keys(records[0] || {})
            .filter((key) => !['ID', 'key'].includes(key))
            .map((key) => (item.ID === key ? item['Variable Name'] : key)),
          merge,
          'auto',
          false
        );

        setMeta(() => {
          let state = {
            ...emptyMeta,
            data: records.map((data) => ({ ...data, [item['Variable Name']]: data[item.ID] })),
            cols: tempCols,
            NextPagination,
          };

          if (meta?.title === TYPE_YEAR || item?.ID === TYPE_YEAR) {
            const tempRecords =
              records?.map((item) => {
                return { ...item, Year: parseDataTableYear(item?.Year) };
              }) || [];
            let state = {
              ...emptyMeta,
              data: tempRecords?.map((data) => ({ ...data, [item['Variable Name']]: data[item.ID] })),
              cols: tempCols,
              NextPagination,
            };
            return state;
          }

          return state;
        });
        setMetaError(false);
      } else {
        setMetaError(true);
        if (!error) message.error(t('ServerError'));
        setMeta(emptyMeta);
      }

      setLoadingValues(false);
    } catch (e) {
      setMetaError(true);
      if (!error) message.error(t('ServerError'));
      setLoadingValues(false);
      setMeta(emptyMeta);
      Sentry.captureException(`Failed to fetch variable values, ` + e);
    }
  };

  const MergingSourceName =
    merge && enableSourceName ? (
      <MergePlaceholder
        className="font-12 mr-0"
        name={details.SourceName}
        showSourceName={true}
        fonSize="12"
        size={18}
        rowClassName="float-right"
        fontWeight="normal"
        colorCode={colorCode}
      />
    ) : null;

  const onCloseMetaValue = () => {
    setSearchTerm('');
    setShowMeta(false);
  };

  const getExportToExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('metaDataExcelSheet')
      .addColumns(profileColsTemp)
      .addDataSource(profileData)
      .saveAs(`${characterLimit(details.SourceName, 169)}_metadata.xlsx`);
    let params = {
      datasetid_1: details.SourceId,
    };

    if (sourceIDList.length) {
      sourceIDList.forEach((ID, i) => {
        params[`datasetid_${i + 1}`] = ID;
      });
    }

    postClickStreamEvent({
      activity: ACTIVITIES.DOWNLOAD_METADATA,
      userid: context?.profile?.email || '',
      ...params,
    });
  };

  return (
    <div
      className="meta-data-section font-12 mb-4 -mt-4"
      style={{ marginTop: view_name ? '-3.5rem' : 0 }}
      ref={reference}
    >
      <Row justify="space-between" className="pt-3 pb-2">
        {(showHeader && (
          <Col {...GridFixedSpan.size.six}>
            <Typography.Paragraph className="m-0 font-14 font-weight-bold joyride-dataset-step13 joyride-MergeResult-Step10">
              {t('Meta')}
            </Typography.Paragraph>
          </Col>
        )) ||
          null}

        <Col {...GridFixedSpan.size[showHeader ? 'six' : 'full']} className="text-right">
          <Button
            className="font-12 joyride-dataset-step14 joyride-MergeResult-Step11"
            size="small"
            type="link"
            onClick={(e) => {
              if (!context.isAuth) {
                e.preventDefault();
                setContext((state) => ({
                  ...state,
                  showLogin: true,
                }));
                setIsExportToExcel(true);
              } else {
                getExportToExcel();
              }
            }}
          >
            <DownloadOutlined className={'mb-2'} /> {t('ExportToExcel')}
          </Button>
        </Col>
      </Row>

      {subHeader}

      <Row className="meta-data-view">
        <Col span={24}>
          <MetaHeader
            title={merge ? 'KeyDimensionsLocation' : 'KeyDimensions'}
            icon={'PrimaryKey'}
            iconComponent={CustomIcon}
            merge={merge}
            colorCode={colorCode}
            mergingSourceName={MergingSourceName}
            iconProperties={{
              className: (!merge && 'color-blue') || undefined,
            }}
          />
          {profileData.map((item) => (
            <React.Fragment key={item.key}>
              {item['Variable Type'] === VARIABLE_TYPES.keyIdentifier &&
                !(merge ? ['Others', 'Time'] : ['Others']).includes(item['Key Identifier Type']) && (
                  <div>
                    <Row className={'pl-2 pr-2 pt-3'}>
                      {item['Key Identifier Type'] === 'Location' && (
                        <Col>
                          <DimensionIcon
                            type={TYPE_LOCATION}
                            colorCode={merge ? colorCode : false}
                            merge={merge}
                            verticalAlign={4}
                            width={15}
                            height={15}
                          />
                          <Text className={s.subtextColor}>{item['Key Identifier Type']}</Text>
                        </Col>
                      )}
                      {item['Key Identifier Type'] === 'Time' && (
                        <Col>
                          <DimensionIcon
                            merge={merge}
                            type={TYPE_TIME}
                            colorCode={merge ? colorCode : false}
                            verticalAlign={4}
                            width={18}
                          />
                          <Text className={s.subtextColor}>{item['Key Identifier Type']}</Text>
                        </Col>
                      )}
                    </Row>
                    <Row className="pl-2 pr-2  pb-3 border-bottom" gutter={[0, 10]} align="middle">
                      <Col xs={24} md={10} xl={10} className={s.mainText}>
                        {item['Variable Name']}
                        <MetaLongDescription name={item['Variable Name']} description={item['Long Description']} />
                      </Col>
                      <Col xs={8} md={5} xl={5}>
                        {t('Unique')} &nbsp;<strong>{item['Unique']}</strong>
                      </Col>
                      <Col xs={8} md={5} xl={5}>
                        <MissingText value={item['Missing']} />
                      </Col>
                      <Col xs={8} md={4} xl={4} className="text-right ">
                        <Button
                          type="link"
                          className={`${s.textColor} p-0`}
                          onClick={() => {
                            setSearchTerm('');
                            valuesClick({ item });
                          }}
                        >
                          <InfoCircleOutlined /> {t('Values')}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
            </React.Fragment>
          ))}

          {merge &&
            profileData.filter(
              (x) => x['Variable Type'] === VARIABLE_TYPES.keyIdentifier && x['Key Identifier Type'] === 'Time'
            ).length > 0 && (
              <>
                <MetaHeader
                  title={'TimeDimensions'}
                  icon={TYPE_TIME}
                  merge={merge}
                  colorCode={colorCode}
                  mergingSourceName={MergingSourceName}
                />

                {profileData.map((item) => (
                  <React.Fragment key={item.key}>
                    {item['Variable Type'] === VARIABLE_TYPES.keyIdentifier && item['Key Identifier Type'] === 'Time' && (
                      <div>
                        <Row className="pl-2 pr-2 pt-3 pb-3 border-bottom" gutter={[0, 10]} align="middle">
                          <Col xs={24} md={10} xl={10} className={s.mainText}>
                            {(typeof item.colorCode === 'number' && (
                              <Tooltip title={item.SourceName} placement="topRight">
                                <DimensionIcon
                                  merge={true}
                                  type={TYPE_TIME}
                                  colorCode={item.colorCode}
                                  className="mr-1"
                                  verticalAlign={4}
                                />
                              </Tooltip>
                            )) ||
                              ''}
                            {item['Variable Name']}
                            <MetaLongDescription name={item['Variable Name']} description={item['Long Description']} />
                          </Col>
                          <Col xs={8} md={5} xl={5}>
                            {t('Unique')} &nbsp;<strong>{item['Unique']}</strong>
                          </Col>
                          <Col
                            xs={8}
                            md={5}
                            xl={5}
                            className={`position-relative ${Number(item['Missing']) ? 'text-danger' : `text-muted`}`}
                          >
                            <MissingText value={item['Missing']} />
                            {item.aggregratedOver && (
                              <span
                                className="position-absolute pt-1 pb-1 pr-2 pl-2 bg-color-light-white rounded"
                                style={{ right: -60, top: -4 }}
                              >
                                {item.aggregratedOver}
                              </span>
                            )}
                          </Col>
                          <Col xs={8} md={4} xl={4} className="text-right ">
                            <Button
                              type="link"
                              className={`${s.textColor} p-0 `}
                              onClick={() => {
                                setSearchTerm('');
                                valuesClick({ item, singleSource: true });
                              }}
                            >
                              <InfoCircleOutlined /> {t('Values')}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}

          {profileData.filter(
            (x) => x['Variable Type'] === VARIABLE_TYPES.keyIdentifier && x['Key Identifier Type'] === 'Others'
          ).length > 0 && (
            <>
              <MetaHeader
                title={'OtherDimensions'}
                icon={TYPE_OTHERS}
                merge={merge}
                colorCode={colorCode}
                mergingSourceName={MergingSourceName}
              />

              {profileData.map((item) => (
                <React.Fragment key={item.key}>
                  {item['Variable Type'] === VARIABLE_TYPES.keyIdentifier && item['Key Identifier Type'] === 'Others' && (
                    <div>
                      <Row className="pl-2 pr-2 pt-3 pb-3 border-bottom" gutter={[0, 10]} align="middle">
                        <Col xs={24} md={10} xl={10} className={s.mainText}>
                          {(typeof item.colorCode === 'number' && (
                            <Tooltip title={item.SourceName} placement="topRight">
                              <DimensionIcon
                                merge={true}
                                type={TYPE_OTHERS}
                                colorCode={item.colorCode}
                                className="mr-1"
                                verticalAlign={4}
                              />
                            </Tooltip>
                          )) ||
                            ''}
                          {item['Variable Name']}
                          <MetaLongDescription name={item['Variable Name']} description={item['Long Description']} />
                        </Col>
                        <Col xs={8} md={5} xl={5}>
                          {t('Unique')} &nbsp;<strong>{item['Unique']}</strong>
                        </Col>
                        <Col
                          xs={8}
                          md={5}
                          xl={5}
                          className={`position-relative ${Number(item['Missing']) ? 'text-danger' : `text-muted`}`}
                        >
                          <MissingText value={item['Missing']} />
                          {item.aggregratedOver && (
                            <span
                              className="position-absolute pt-1 pb-1 pr-2 pl-2 bg-color-light-white rounded"
                              style={{ right: -60, top: -4 }}
                            >
                              {item.aggregratedOver}
                            </span>
                          )}
                        </Col>
                        <Col xs={8} md={4} xl={4} className="text-right ">
                          <Button
                            type="link"
                            className={`${s.textColor} p-0 `}
                            onClick={() => valuesClick({ item, singleSource: merge })}
                          >
                            <InfoCircleOutlined /> {t('Values')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          )}

          <MetaHeader
            title={'Indicaters'}
            icon={TYPE_INDICATORS}
            merge={merge}
            colorCode={colorCode}
            mergingSourceName={MergingSourceName}
          />

          <div>
            {profileData.map((item) => {
              const iColorCode = merge && colorCode ? colorCode : item.colorCode;
              const isWeightedAvg =
                item['Weighing Variable Name'] &&
                !WeighingAggregration.includes(cleanName(item['Weighing Variable Name'], ''));

              let colSpans = { xs: 8, md: 4, xl: 3 };
              let colSpans1 = { xs: 8, md: 3, xl: 3 };
              let colSpans2 = { xs: 8, md: 3, xl: 3 };

              return (
                <React.Fragment key={item.key}>
                  {item['Variable Type'] === VARIABLE_TYPES.numericVariable && (
                    <div>
                      <Row className="pl-2 pr-2 pt-3 pb-3 border-bottom">
                        <Col xs={24} md={24} xl={5} className={s.mainText}>
                          {iColorCode !== false && iColorCode > -1 && (
                            <Tooltip title={item.SourceName} placement="topRight">
                              <DimensionIcon
                                merge={true}
                                type={TYPE_INDICATORS}
                                colorCode={iColorCode}
                                verticalAlign={3}
                              />
                            </Tooltip>
                          )}
                          {item['Variable Name']}
                          <MetaLongDescription name={item['Variable Name']} description={item['Long Description']} />
                        </Col>

                        <Col xs={24} md={24} xl={19} className={`${context.screenContext === 'desktop' ? '' : 'my-1'}`}>
                          <Row
                            justify="start"
                            className={`${context.screenContext === 'mobile' ? 'text-left' : 'text-right'}`}
                          >
                            <Col {...colSpans1}>
                              <div className="mr-3 pr-3 border-right">
                                <span className={s.subtext}>{t('Min')}</span> <br />
                                <strong>{item['Minimum']}</strong>
                              </div>
                            </Col>
                            <Col {...colSpans1}>
                              <div className="mr-3 pr-3 border-right">
                                <span className={s.subtext}>{t('Max')}</span> <br />
                                <strong>{item['Maximum']}</strong>
                              </div>
                            </Col>
                            <Col {...colSpans}>
                              <div className="mr-3 pr-3 border-right">
                                <span className={s.subtext}>{t('Mean')}</span> <br />
                                <strong>{roundDecimalPlaces({ number: item['Mean'] })}</strong>
                              </div>
                            </Col>
                            <Col {...colSpans}>
                              <div className="mr-3 pr-3 border-right">
                                <span className={s.subtext}>{t('Median')}</span> <br />
                                <strong>{roundDecimalPlaces({ number: item['Median'] })}</strong>
                              </div>
                            </Col>

                            <Col {...colSpans2} className={`text-left`}>
                              <div className="mr-3 border-right">
                                <span className={s.subtext}>{t('AggregationFn')}</span> <br />
                                <strong>{item['Aggregation Function'] || 'N/A'}</strong>
                              </div>
                            </Col>

                            {(
                              <Col {...colSpans} className="text-left">
                                <div className="mr-3 border-right">
                                  <span className={s.subtext}>{t('WeightVariableName')}</span> <br />
                                  <Tooltip
                                    title={t(isWeightedAvg ? item['Weighing Variable Name'] : 'N/A')}
                                    placement="top"
                                  >
                                    <strong className="text-truncate d-block">
                                      {isWeightedAvg ? item['Weighing Variable Name'] : 'N/A'}
                                    </strong>
                                  </Tooltip>
                                </div>
                              </Col>
                            ) || null}
                            <Col {...colSpans2} className={`text-left`}>
                              <div className="mr-3 border-right">
                                <span className={s.subtext}>{t('Units')}</span> <br />
                                <strong className={s.breakWord}>{item['units'] || 'N/A'}</strong>
                              </div>
                            </Col>
                            <Col
                              {...colSpans1}
                              className={`text-left ${context.screenContext === 'mobile' ? 'pl-0' : 'pl-3'}`}
                            >
                              <span className={s.subtext}>{t('ScalingFactor')}</span> <br />
                              <strong>{item['Scalling Factor'] || 'N/A'}</strong>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </Col>
      </Row>

      {(showScroll && (
        <div className={`${context.screenContext === 'mobile' ? s.scrollTopBtnResponsive : ''} ${s.scrollTopBtn}`}>
          <Button
            onClick={() => {
              window.scrollTo({ behavior: 'smooth', block: 'start', top: 0 });
            }}
            type="link"
            icon={<ArrowUpOutlined />}
            className={`${s.scrollBtn} mt-4`}
          >
            <strong>{t('ScrollToTop')}</strong>
          </Button>
        </div>
      )) ||
        null}
      <Modal
        title={
          <div className={s.valuesTitle}>
            {merge ? (
              <MergePlaceholder
                className="font-12 mr-0"
                name={details.SourceName}
                showSourceName={false}
                fonSize="12"
                size={18}
                fontWeight="normal"
                colorCode={colorCode}
                mt={'n1'}
              />
            ) : null}
            {t('ValueList', { title: meta.title })}
          </div>
        }
        visible={showMeta}
        onOk={onCloseMetaValue}
        onCancel={onCloseMetaValue}
        onClose={onCloseMetaValue}
        bodyStyle={{ padding: 20 }}
        closeIcon={<CloseButton />}
        footer={false}
        style={{ top: 20 }}
      >
        <Input.Search
          allowClear
          placeholder={t('PlaceholderSearch')}
          onChange={handleSearch}
          className="font-14 mb-2 CommonSearch"
          value={searchTerm}
          autoComplete="off"
        />
        <Typography.Paragraph className={`font-12 ${!meta.data?.length && 'd-none'}`}>
          <Typography.Text className="mr-2">
            {t('Unique')} : <strong>{meta.unique}</strong>
          </Typography.Text>

          <MissingText value={meta.missing} />
        </Typography.Paragraph>
        <Spin spinning={loadingValues}>
          <Table
            dataSource={meta.data}
            columns={meta.cols}
            pagination={false}
            scroll={{ y: 300 }}
            className="table-grey-theme"
            locale={emptyContent}
          />
          <div>
            <Row>
              <Col xs={24} md={24} xl={24}>
                <SimplePagination
                  limit={100}
                  records={Number(totalRecords)}
                  currentPage={currentPage}
                  NextPagination={meta.NextPagination}
                  onClick={({ page, offset = 0 }) => {
                    valuesClick({ item: currentItem, singleSource: isSingleSource, page, offset });
                  }}
                />
              </Col>
            </Row>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default Metatab;
