import { t } from 'i18next';
import { useEffect, useState } from 'react';

import { Button, Condition } from '../../components';
import { voidFunction } from '../../utils';

export const SimplePagination = ({
  NextPagination = 0,
  records = 0,
  limit = 100,
  onClick = voidFunction,
  currentPage = 1,
}) => {
  const [pages, setPage] = useState([]);

  const handleNext = () => {
    onClick({ page: pages.length + 1, offset: NextPagination });
    setPage((ps) => [...ps, NextPagination]);
  };

  const handlePrevious = () => {
    const avPages = [...pages];
    avPages.pop();

    const offset = avPages[avPages.length - 1];
    onClick({ page: avPages.length, offset });
    setPage(avPages);
  };

  useEffect(() => {
    if (currentPage !== pages.length) {
      setPage([0]);
    }

    // eslint-disable-next-line
  }, [currentPage]);

  const totalPages = Math.ceil(records / limit);
  const isLastPage = pages.length === totalPages;

  return (
    <div className="pt-4 ">
      <Condition show={totalPages > 1}>
        <Button disabled={pages.length <= 1} onClick={handlePrevious} className="mr-2">
          &#x3c; {t('Prev')}
        </Button>

        <Button disabled={!NextPagination || isLastPage} onClick={handleNext}>
          {t('Next')} &#x3e;
        </Button>
      </Condition>
      <Condition show={totalPages > 0}>
        <span className=" py-2 mx-2  font-12">
          {t('ShowingPage', {
            records,
            page: pages.length,
            total: totalPages,
          })}
        </span>
      </Condition>
    </div>
  );
};

export default SimplePagination;
