import React from 'react';
import { Row, Col } from 'antd';
import { t } from 'i18next';
import { RightOutlined } from '@ant-design/icons';
import { Empty } from '../../components';
import { Link } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { RoutePaths } from '../../routePaths';
import s from './index.module.less';

const MinistryList = ({ data = [] }) => {
  const records = sortBy(data, ['ministry_name']);

  if (!records?.length) return <Empty className="p-5" description={t('NoData')} />;

  return (
    <>
      <div className={s.ministryList}>
        <Row align="middle" justify="center">
          <Col span={24}>
            <Row align="left" justify="left" gutter={[10, 0]}>
              {records.slice(0, 18).map((item) => (
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }} key={item.id}>
                  <Link to={`${RoutePaths.CATALOGUE}?ministries=m${item?.id}`} className={s.link}>
                    <h4 className="font-15">{item.ministry_name}</h4>
                    <RightOutlined />
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MinistryList;
