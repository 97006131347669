import { t } from 'i18next';
import { TYPE_INDICATORS } from '../../../constants';
import { Typography } from '../../../components';
import { useAppliedFilterContext } from '../../../providers/FilterContextProvider';
import MergePlaceholder from '../Merge/MergePlaceholder';

const DataViewRowAndIndicators = ({
  mergeResult,
  view = 1,
  colorCode = false,
  total = false,
  details = false,
  allIndicators = false,
  indicatorCount = false,
}) => {
  const { getSelectedCount } = useAppliedFilterContext();
  const getIndicatorsCount = (item, index) => {
    const indicaters = item?.Indicators?.Items?.length || 0;
    const selected = allIndicators
      ? indicatorCount
      : indicatorCount ||
        getSelectedCount({
          sourceID: item.SourceId,
          type: TYPE_INDICATORS,
        });

    return (
      <Typography.Text key={`${index}-rows-view`} className="ml-1">
        <MergePlaceholder
          size={16}
          name={item.SourceName}
          colorCode={colorCode !== false ? colorCode : index}
          mt="n1"
          className="font-12"
        />
        {view === 1 ? selected || indicaters : `${selected || indicaters}/${indicaters}`}
      </Typography.Text>
    );
  };

  return (
    <Typography className="font-12 font-weight-500 ">
      {t('RowCount', { count: total || mergeResult?.TotalRecords?.[0] || 0 })}{' '}
      <Typography.Text className="mr-2 ml-2" type="secondary">
        |
      </Typography.Text>
      <span className="mr-1">{t('Indicaters')}</span>
      {details
        ? getIndicatorsCount(details, 0)
        : mergeResult?.sourcedetails?.Data.map((item, index) => getIndicatorsCount(item, index))}
    </Typography>
  );
};

export default DataViewRowAndIndicators;
