require('dotenv').config();

export const REACT_APP_COGNITO_USER_POOL = process.env.REACT_APP_COGNITO_USER_POOL;
export const REACT_APP_COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
export const REACT_APP_KDAP_COGNITO_USER_POOL = process.env.REACT_APP_KDAP_COGNITO_USER_POOL;
export const REACT_APP_KDAP_COGNITO_CLIENT_ID = process.env.REACT_APP_KDAP_COGNITO_CLIENT_ID;
export const REACT_APP_API_BASE_URI = process.env.REACT_APP_API_BASE_URI;
export const REACT_APP_THEME_PRIMARY_COLOR = process.env.REACT_APP_THEME_PRIMARY_COLOR;
export const REACT_APP_BUSINESS_ENV = process.env.REACT_APP_BUSINESS_ENV || 'dev';
export const REACT_APP_CLICKSTREAM_URI = process.env.REACT_APP_CLICKSTREAM_URI;
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const NODE_ENV = process.env.NODE_ENV;
export const REACT_APP_MEGDAP_COGNITO_USER_POOL = process.env.REACT_APP_MEGDAP_COGNITO_USER_POOL;
export const REACT_APP_MEGDAP_COGNITO_CLIENT_ID = process.env.REACT_APP_MEGDAP_COGNITO_CLIENT_ID;
export const REACT_APP_MEGDAP_PROD_COGNITO_USER_POOL = process.env.REACT_APP_MEGDAP_PROD_COGNITO_USER_POOL;
export const REACT_APP_MEGDAP_PROD_COGNITO_CLIENT_ID = process.env.REACT_APP_MEGDAP_PROD_COGNITO_CLIENT_ID;
export const REACT_APP_DBTDAP_COGNITO_USER_POOL = process.env.REACT_APP_DBTDAP_COGNITO_USER_POOL;
export const REACT_APP_DBTDAP_COGNITO_CLIENT_ID = process.env.REACT_APP_DBTDAP_COGNITO_CLIENT_ID;
export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
