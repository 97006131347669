import ListWithSearch from '../Hoc/ListWithSearch';
import { Row, Col, Switch, Button, Input, Empty, Tooltip, Tag, Divider, Typography } from '../../components';
import React, { useEffect, useState } from 'react';
import s from './index.module.less';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { t } from 'i18next';
import { voidFunction } from '../../utils';
import DimensionIcon from '../CustomIcon/DimensionIcon';
import { TYPE_INDICATORS } from '../../constants';

const Options = ({
  data,
  onApply = voidFunction,
  onCancel = voidFunction,
  selectedValues = {},
  input = 'switch',
  applyOnly = false,
  keyName = 'key',
  reverseSelection = false,
  showCancel = true,
  isVisible = false,
  searchTerm = '',
  resetSearch = voidFunction,
}) => {
  const [selectedData, setSelectedData] = useState(selectedValues);

  useEffect(() => {
    if (!Object.keys(selectedValues).length) clearAll();
    else setSelectedData(selectedValues);

    //eslint-disable-next-line
  }, [selectedValues, isVisible]);

  const onChange = (isChecked, checkedKey) => {
    setSelectedData((prevState) => {
      let state = { ...prevState, [checkedKey]: isChecked };
      if (!isChecked && !reverseSelection) {
        delete state[checkedKey];
      }

      return state;
    });
  };

  const selectAll = () => {
    const state = {};
    data.forEach((col) => {
      state[col[keyName]] = true;
    });

    setSelectedData((ps) => ({ ...ps, ...state }));
  };

  const clearAll = () => {
    if (!reverseSelection) {
      setSelectedData({});
    } else {
      const state = {};
      data.forEach((col) => {
        state[col[keyName]] = false;
      });

      setSelectedData((ps) => ({ ...ps, ...state }));
    }
  };

  const InputComponents = {
    switch: (props) => <Switch {...props} />,
    checkbox: (props) => (
      <div className={s.checkboxes}>
        <Input
          {...props}
          type={'checkbox'}
          onChange={(e) => {
            props.onChange(e.target.checked);
          }}
          autoComplete="off"
        />
      </div>
    ),
    eye: (props) => (
      <div className={s.checkboxes}>
        <div className="text-center cursor-pointer">
          <label htmlFor={props.id} className="cursor-pointer">
            <Tooltip
              getPopupContainer={(triggerNode) => triggerNode}
              placement={'top'}
              title={props.checked ? t('HideColumn') : t('ShowColumn')}
            >
              {props.checked ? <EyeTwoTone /> : <EyeInvisibleOutlined className="text-muted" />}
            </Tooltip>
          </label>
        </div>
        <Input
          {...props}
          type={'checkbox'}
          onChange={(e) => {
            props.onChange(e.target.checked);
          }}
          className="d-none"
          autocomplete="off"
        />
      </div>
    ),
  };

  const InputSelector = InputComponents[input];
  const content = (
    <div className={`${s.multiselectListContainer} pb-2`}>
      {!data?.length ? (
        <Empty description={t('NoData')} />
      ) : (
        data.map((item) => {
          const icon =
            (item.colorIndicator > 0 && (
              <DimensionIcon
                merge={true}
                colorCode={item.colorIndicator - 1}
                type={TYPE_INDICATORS}
                verticalAlign={3}
              />
            )) ||
            null;

          return (
            <Row key={item.key}>
              <Col>
                <InputSelector
                  onChange={(isChecked) => onChange(isChecked, item[keyName])}
                  checked={selectedData[item[keyName]] === true}
                  size="small"
                  style={{ marginTop: 2 }}
                  id={item.key}
                />
              </Col>
              <Col
                xs={{ span: 21, offset: 0 }}
                md={{ span: 21, offset: 0 }}
                xl={{ span: 21, offset: 0 }}
                className="pl-2"
              >
                <label htmlFor={item.key} className="cursor-pointer">
                  {icon}
                  {item.display}
                </label>
              </Col>
            </Row>
          );
        })
      )}
    </div>
  );

  if (!data?.length) {
    return content;
  }

  return (
    <>
      <div className="mt-2">
        <Button type="link" onClick={selectAll} size="small" className="p-0  text-capitalize">
          <small>{t('SelectAll')}</small>
        </Button>
        <Divider type="vertical" />
        <Button type="link" onClick={clearAll} size="small" className="p-0 text-capitalize ">
          <small>{t('Clear_All')}</small>
        </Button>

        {(searchTerm && (
          <>
            <Divider type="vertical" />

            <Tag onClose={resetSearch} closable className={`${s.matchingText} mr-0 rounded-common`}>
              <Typography.Text ellipsis={true} className="w-85">
                <span className="w-85"> {t('Matching', { text: searchTerm })} </span>
              </Typography.Text>
            </Tag>
          </>
        )) ||
          ''}
      </div>
      {content}
      <Row gutter={8} justify="end" className="pt-2 pb-2">
        {applyOnly ? (
          <Col span={24}>
            <Button className="w-100" type="primary" onClick={() => onApply(selectedData)}>
              {t('BtnApply')}
            </Button>
          </Col>
        ) : (
          <>
            <Col>
              <Button type="primary" onClick={() => onApply(selectedData)}>
                {t('BtnApply')}
              </Button>
            </Col>
            {showCancel && (
              <Col>
                <Button
                  onClick={() => {
                    setSelectedData(selectedValues);
                    onCancel(false);
                  }}
                >
                  {t('Close')}
                </Button>
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

const SelectOptions = (props) => ListWithSearch(Options, props)();
export default SelectOptions;
