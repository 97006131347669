import React from 'react';
import { getDatasetProcessingReportSummary } from '../../services/dataset';

const DatasetProcessingReport = (WrapperComponent, { datasetID = false, ...rest }) => {
  const [report, setReport] = React.useState([]);
  const [isFetching, setFetching] = React.useState(false);
  const [hasError, setError] = React.useState(false);

  React.useEffect(() => {
    const fetchDatasetReport = async (datasetID) => {
      setFetching(true);
      setError(false);
      const { data, IsError } = await getDatasetProcessingReportSummary(datasetID);

      setError(IsError);

      if (!IsError) {
        setReport(data.records);
      }

      setFetching(false);
    };

    if (datasetID) fetchDatasetReport(datasetID);
  }, [datasetID]);

  return () => (
    <WrapperComponent
      processingReport={{
        report,
        isFetching,
        hasError,
      }}
      sourceID={datasetID}
      {...rest}
    />
  );
};

export default DatasetProcessingReport;
