import { useContext } from 'react';
import { Empty, Typography, Row, Col } from '../../../components';
import ProcessingSummary from './ProcessingSummary';
import StarProcessing from './StarProcessing';
import { t } from 'i18next';
import { GridFixedSpan, PROCESSING_TYPE } from '../../../constants';
import Context from '../../../context';
import getDynamicFile from '../../../pages/Info/getDynamicFile';

const { Paragraph, Title } = Typography;

const StaticProcessingHeader = ({ downloading = false, helpDocument }) => (
  <div className="mb-4">
    <Row>
      <Col sm={24} lg={12} xl={12}>
        <Title level={3}>{t('ProcessingReport')}</Title>
      </Col>
      {(!downloading && (
        <Col sm={24} lg={12} xl={12}>
          <a
            href={helpDocument}
            target="_blank"
            rel="noreferrer"
            className="font-14 font-weight-bold mt-2 float-xl-right float-lg-right"
          >
            {t('MoreProcessingReport')}
          </a>
        </Col>
      )) ||
        null}
    </Row>

    <Paragraph type="secondary">{t('ProcessingReportDescription')}</Paragraph>
  </div>
);

const ReportCard = ({ sourceID, data = {}, downloading = false }) => {
  const [context] = useContext(Context);
  const { processingReport } = getDynamicFile(context.domain);
  let stars =
    ((data?.oneStarMetadata && 1) || 0) + ((data?.twoStarMetadata && 1) || 0) + ((data?.threeStarMetadata && 1) || 0);

  return (
    <div className="min-vh-50">
      <Row>
        <Col {...GridFixedSpan.size.full}>
          <StaticProcessingHeader downloading={downloading} helpDocument={processingReport} />
        </Col>
        <Col {...GridFixedSpan.size.full}>
          <ProcessingSummary
            data={data?.processing_summary || {}}
            sourceID={sourceID}
            stars={stars}
            downloading={downloading}
          />
        </Col>
      </Row>
      {!data ? <Empty className="p-5" description={t('NoData')} /> : null}
      {PROCESSING_TYPE.map(
        (type, i) =>
          (data[type] && (
            <StarProcessing data={data[type]} star={i + 1} sourceID={sourceID} key={`${i}`} downloading={downloading} />
          )) ||
          null
      )}
    </div>
  );
};

export default ReportCard;
