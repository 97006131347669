import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Typography, Radio } from '../../components';
import { getDomainParameters, CATALOG_DOMAIN_DETAILS } from '../../constants';
const { Text } = Typography;
const { domain } = getDomainParameters();

export const CatalogueNdapHeaderRadio = ({ data = [], onRadioChange = () => {}, appliedFilters = {} }) => {
  const [value, setValue] = useState(appliedFilters?.domain);

  useEffect(() => {
    setValue(appliedFilters?.domain);
  }, [appliedFilters]);

  const onChange = (e) => {
    setValue(e.target.value);
    onRadioChange(e.target.value);
  };

  if (!data)
    return (
      <Text className="font-16" ellipsis={true ? { tooltip: `${t('FindAllDatasets')}` } : false}>{`${t(
        'FindAllDatasets'
      )}`}</Text>
    );

  return (
    <>
      <Radio.Group onChange={onChange} value={value}>
        {data?.map((item, ind) => (
          <Radio className="font-16 font-weight-bold" value={item} key={`${item}-${ind}`}>
            {item?.toUpperCase()}
          </Radio>
        ))}
      </Radio.Group>
    </>
  );
};

export const CatalogueTileOrRadio = (props) => {
  return <CatalogueNdapHeaderRadio data={CATALOG_DOMAIN_DETAILS[domain] || ''} {...props} />;
};

export default CatalogueTileOrRadio;
