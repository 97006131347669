import React, { Fragment, useState, useEffect, useMemo, useContext, useRef } from 'react';
import * as Sentry from '@sentry/browser';
import { t } from 'i18next';
import { isEmpty, isEqual, get, orderBy } from 'lodash';
import { StopOutlined } from '@ant-design/icons';

import {
  Layout,
  Typography,
  Row,
  Col,
  Select,
  Button,
  Spin,
  message,
  Popconfirm,
  FatLanguageButton,
} from '../../../components';
import CustomIcon from '../../CustomIcon';
import {
  INITIAL_CHART_GRIDS_AND_LINES,
  CHART_TYPES,
  CHART_TYPE_LINE,
  FILTER_INPUTS,
  GridFixedSpan,
  TYPE_ALL_DIMENSIONS,
  TYPE_LOCATION,
  TYPE_MAP_HEATMAP,
  TYPE_MAP_BUBBLE,
  CHART_TYPE_MAPVIEW,
  ID_STATE,
  TYPE_TIME,
  ACTIVITIES,
  CHART_TYPE_PIVOT,
  TYPE_INDICATORS_CHART,
  STANDERED_FUNCTIONS,
  WEIGHTED_AVG,
  TYPE_OTHERS,
  TYPE_DIMENSIONS,
  BLUEBROWN,
  VISUALIZE_TYPES,
  MAPBLUE,
  GRANULARITIES,
  DATA_FILTER_TYPES,
  TYPE_CONDITION_VISUALIZATION,
  TYPE_YEAR,
  ALL_DOMAINS,
  ID_DISTRICT,
  DEFAULT_MAP,
  ID_COUNTRY,
  ID_BLOCK,
  MAP_VIEW_DOMAIN,
} from '../../../constants';
import Context from '../../../context';
import DatasetFilters from '../DatasetFilters';
import VisualizationCharts from './VisualizationCharts';
import LeftChartsList, { typeConfig } from './LeftChartsList';
import mergeStyles from './index.module.less';
import { getVisualizeGraph, getDatasetDataWithFilters } from '../../../services/dataset';
import { voidFunction, replaceAll, parseStringToNumber } from '../../../utils';
import { useAppliedFilterContext, useFilterActions, useFilterContext } from '../../../providers/FilterContextProvider';
import { getColorPallets, loadYears } from '../../../services/common';
import { postClickStreamEvent } from '../../../services';
import ChartWarning from './ChartWarnings';
import {
  checkStandarisation,
  ConvertRGBtoHex,
  getLastItem,
  getLocationAndName,
  getSourceID,
} from '../../../utils/common';
import TextSelectedCount from '../DatasetUtils/TextSelectedCount';
import { checkForUpdate } from '../../../hooks/useOnFilterUpdate';

const { Content, Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;

const ChartList = ({
  onSelectChartType = voidFunction,
  view_name = '',
  isStandarised = true,
  merge = false,
  granularity,
}) => {
  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <div className={`${mergeStyles.visualBtnWrapper}`}>
            <Button
              type="link"
              className={`${mergeStyles.visualBtn} d-flex color-light-dark`}
              icon={<CustomIcon type={'AutoComplete'} verticalAlign={3} className="ml-n1" />}
              onClick={() => onSelectChartType(CHART_TYPES[2].type)}
            >
              {CHART_TYPES[0]?.name}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        {CHART_TYPES.slice(1).map((chart) => {
          let isLineChartDisabled = view_name && chart.type === CHART_TYPE_LINE;
          let isMapViewDisable = false;

          if (granularity) {
            isMapViewDisable = true && chart.name === CHART_TYPE_MAPVIEW;
          } else {
            isMapViewDisable = merge ? false : !isStandarised && chart.name === CHART_TYPE_MAPVIEW;
          }

          let tooltip = '';

          if (isLineChartDisabled) {
            tooltip = t('LineChartNotAvailable');
          }

          if (isMapViewDisable) {
            tooltip = t(granularity ? 'DisableDueToCountryLevel' : 'DisableDueToNotStandarized', {
              feature: t(chart.name),
            });
          }

          return (
            <Col span={12} key={chart.name}>
              <div className={`${mergeStyles.visualBtnWrapper}`}>
                <FatLanguageButton
                  disabled={isLineChartDisabled || isMapViewDisable}
                  text={t(chart.name)}
                  type="link"
                  className={`${mergeStyles.visualBtn} color-light-dark`}
                  iconLeft={<CustomIcon type={chart.icon} verticalAlign={3} className="ml-n1" />}
                  onClick={() => onSelectChartType(chart.type)}
                  title={tooltip}
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </Fragment>
  );
};

export const ChartListDropdown = ({
  selectedChart = {},
  selectChartType = voidFunction,
  loading = false,
  view_name = '',
  isStandarised = true,
  merge = false,
  granularity,
}) => (
  <Fragment>
    <Row>
      <Col span={24}>
        <Select value={selectedChart?.type} className="w-100" onSelect={selectChartType} disabled={loading}>
          {CHART_TYPES.slice(1).map((chart) => {
            let isLineChartDisabled = view_name && chart.name === CHART_TYPE_LINE;
            let isMapViewDisable = '';

            if (granularity) {
              isMapViewDisable = true && chart.name === CHART_TYPE_MAPVIEW;
            } else {
              isMapViewDisable = merge ? false : !isStandarised && chart.name === CHART_TYPE_MAPVIEW;
            }

            let tooltip = '';

            if (isLineChartDisabled) {
              tooltip = t('LineChartNotAvailable');
            }

            if (isMapViewDisable) {
              tooltip = t(granularity ? 'DisableDueToCountryLevel' : 'DisableDueToNotStandarized', {
                feature: t(chart.name),
              });
            }

            let option = (
              <Option
                key={chart.name}
                value={chart.type}
                disabled={isLineChartDisabled || isMapViewDisable}
                title={tooltip}
              >
                <CustomIcon type={chart.icon} verticalAlign={3} className={'mr-1'} />

                {t(chart.name)}
              </Option>
            );

            return option;
          })}
        </Select>
      </Col>
    </Row>
  </Fragment>
);

const mapTypeToAllow = (filterSetting) => {
  let data = Object.values(filterSetting);
  let filter = {};
  data.forEach((item) => {
    filter[item.type] = {
      allow: item.allow,
    };
  });

  return filter;
};

const CHART_TITLE = { title: '', by: '' };
const VisualizationsLayout = ({
  title,
  onSelectChartType,
  details = {},
  view_name = '',
  chartType = '',
  onLoad = voidFunction,
  setActiveGraphType = voidFunction,
  setChartType = voidFunction,
  visualTitle = voidFunction,
  isVisualisation = true,
  domain,
  ...rest
}) => {
  const [chart, setChart] = useState({});
  const [previousChart, setPreviousChart] = useState({});
  const [charTitle, setChartTitle] = useState(CHART_TITLE);
  const [chartData, setChartData] = useState(false);
  const [chartTableData, setChartTableData] = useState({});
  const { appliedFilters, getSelectedFilters, getSelectedCount, updateInfo } = useAppliedFilterContext();
  const [loading, setLoading] = useState(false);
  const [loadingYears, setLoadingYears] = useState(false);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState('');

  const [selectMode, setSelectMode] = useState(null);

  const [aggregateOverTime, setAggregateOverTime] = useState(false);
  const [filterSetting, setFillterSettings] = useState({});
  const [colorPallet, setColorPallet] = useState({});
  const [selectedPallet, setSelectedPallet] = useState(BLUEBROWN);
  const [selectedOtherPallet, setSelectedOtherPallet] = useState(MAPBLUE);
  const [gridAndLine, setGridAndLines] = useState(INITIAL_CHART_GRIDS_AND_LINES);
  const [sortBy, setSortBy] = useState({});
  const [initialSortBy, setInitialSortBy] = useState({});
  const [warning, setWarning] = useState(false);
  const [showWarningAction, setShowWarningAction] = useState(false);
  const [loadMap, setLoadMap] = useState(ID_STATE);
  const [mapFilter, setMapFilter] = useState({});
  const [defaultMap, setDefaultMap] = useState(DEFAULT_MAP);
  const [context] = useContext(Context);
  const { switchVisualizationFilters, resetFilters, resetToPrevious, removeExtras, resetConditions } = useFilterActions(
    {
      sourceID: details?.SourceId,
    }
  );
  const { setFilter, filters } = useFilterContext();
  const chartRef = useRef();
  const [conditions, setConditions] = useState([]);
  const [drawChart, setDrawChart] = useState(false);
  const selectedYears = useMemo(() => {
    const selectedFilters = getSelectedFilters({
      sourceID: details.SourceId,
      type: TYPE_TIME,
    });

    let selected = [];
    selectedFilters.forEach((item) => {
      if (item.checked) {
        for (let [key, isChecked] of Object.entries(item.checked)) {
          if (isChecked) {
            selected.push({
              Name: item[key],
              ID: item[`${key}ID`],
              columnname: item[`${key}Column`],
            });
          }
        }
      }
    });

    if (selected.length) {
      if (!selectMode) {
        const isSetCurrentYear = selected.findIndex(({ ID }) => ID === year);

        if (isSetCurrentYear < 0) {
          setYear(get(selected, '0.ID'));
        }
      } else {
        setYear(selected.map(({ Name }) => Name));
      }
    } else {
      if (selectMode) {
        setYear(years.map(({ Name }) => Name));
      } else if (!year || Array.isArray(year)) {
        setYear(get(years, '0.ID'));
      }
    }

    return selected;

    // eslint-disable-next-line
  }, [appliedFilters, selectMode]);

  useEffect(() => {
    chartRef.current = chart;
  }, [chart]);

  useEffect(() => {
    return () => {
      setChartType(chartRef.current);
    };

    // eslint-disable-next-line
  }, []);

  const createTitle = () => {
    let chartConf = Object.values(typeConfig[chart.type]);
    let i = [];
    let d = [];

    chartConf.forEach((item) => {
      if (item.filterType === TYPE_INDICATORS_CHART) {
        i.push(item.type);
      } else if (item.type) {
        d.push(item.type);
      }
    });

    let allIndicaters = getSelectedFilters({ sourceID: details.SourceId, includeOnly: i }).map(({ data }) =>
      data
        .map(
          ({ Description, AggregationFunction }) =>
            `${STANDERED_FUNCTIONS[AggregationFunction] || WEIGHTED_AVG[AggregationFunction]} Of ${Description}`
        )
        .join(' V/S ')
    );

    let allDimensions = getSelectedFilters({ sourceID: details.SourceId, includeOnly: d }).map(({ data }) =>
      data.map(({ Description }) => Description).join(' and ')
    );

    return {
      title: `${allIndicaters.join(' V/S ')} By ${allDimensions.join(' and ')}`,
      by: chart.type === CHART_TYPE_PIVOT ? details.SourceName : `(${details.SourceName})`,
    };
  };

  useEffect(() => {
    setLoadMap(ID_STATE);
    setMapFilter({});
  }, [chart.type]);

  useEffect(() => {
    if (chartType) {
      selectChartType(chartType);
    }

    // eslint-disable-next-line
  }, [chartType]);

  useEffect(() => {
    if (details?.SourceId && chart.type === CHART_TYPE_MAPVIEW) {
      let ID = String(details?.SourceId).split(',')?.[0];
      if (ID) {
        const region = get(appliedFilters, `${ID}.${TYPE_ALL_DIMENSIONS}`, []).slice(0, 1)[0];

        if (region?.ID === ID_STATE && loadMap !== ID_STATE) {
          setLoadMap(ID_STATE);
          setMapFilter({});
        }
      }
    }
  }, [appliedFilters, details.SourceId, chart.type, loadMap]);

  useEffect(() => {
    if (warning) setWarning(false);

    // eslint-disable-next-line
  }, [appliedFilters, chart, filters]);

  useEffect(() => {
    const fetch = async () => {
      try {
        let { data } = await getColorPallets();
        if (data.Plotly) {
          let pallet = Object.entries(data.Plotly);
          for (let [theme, colors] of pallet) {
            data.Plotly[theme] = colors.map(ConvertRGBtoHex);
          }
        }

        setColorPallet(data);
      } catch (e) {
        console.log(e);
        Sentry.captureException(`Failed to fetch visualization colour pallete, ` + e);
      }
    };

    fetch();
  }, []);

  const changeRef = useRef({});

  const fetchYears = async (isMounted, appliedFilters = {}) => {
    if (!view_name && isMounted && !loadingYears) {
      setLoadingYears(true);

      const { data: { records = [], IsError = true } = {} } = await loadYears(details?.SourceId, appliedFilters);

      if (isMounted) {
        setLoadingYears(false);

        if (!IsError) {
          let orderedYears = orderBy(records, ['Name'], ['desc']);
          setYears(orderedYears);
          setYear(orderedYears[0]?.ID);

          if (orderedYears[0]?.ID === year) {
            setDrawChart((ps) => !ps);
          }

          return orderedYears[0]?.Name;
        } else {
          return false;
        }
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (details?.SourceId) {
      fetchYears(isMounted);
    }

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [details]);

  useEffect(() => {
    let isMounted = true;

    if (view_name) {
      setDrawChart((ps) => !ps);
      return;
    }

    const updatedRecords = checkForUpdate({
      sourceID: details.SourceId,
      types: [TYPE_DIMENSIONS, TYPE_OTHERS, TYPE_TIME, TYPE_LOCATION, TYPE_CONDITION_VISUALIZATION],
      current: changeRef.current,
      appliedFilters,
      updateInfo,
    });

    if (updatedRecords) {
      changeRef.current = updatedRecords;
      fetchYears(isMounted, updatedRecords);
    } else {
      setDrawChart((ps) => !ps);
    }

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [appliedFilters, details.SourceId, updateInfo, view_name]);

  useEffect(() => {
    if (chart.type) {
      let allFilters = Object.values(typeConfig[chart.type] || {});
      let filter = allFilters[0];
      let ID = getSourceID(details.SourceId);
      let selected = appliedFilters[ID]?.[filter?.type];
      let setSortByFilter = false;

      if (!sortBy[chart.type]) {
        setSortByFilter = true;
      }

      if (setSortByFilter && selected?.length) {
        const dataItem = {
          ID: selected[0].ID,
          Description: selected[0].Description,
          mode: 'asc',
          type: chart.type,
        };
        setSortBy((ps) => ({
          ...ps,
          [chart.type]: dataItem,
        }));

        setInitialSortBy((ps) => ({ ...ps, [chart.type]: dataItem }));
      }
    }
  }, [chart, appliedFilters, sortBy, details]);

  const reset = (error) => {
    let show = false;
    let rows = 'rows';

    resetToPrevious({ id: details.SourceId, types: DATA_FILTER_TYPES });

    if (error) {
      let {
        Dimensions: { Items = false } = [],
        Indicators: { Items: InItems = false },
      } = details;

      let toLowerCaseError = error?.toLowerCase?.();

      if (Items && toLowerCaseError) {
        Items.every(({ DimensionType, ID, Description }) => {
          let dType = DimensionType?.toLowerCase?.();
          if (
            (dType && Description && toLowerCaseError.includes(Description.toLowerCase())) ||
            (dType && Description && toLowerCaseError.includes(rows))
          ) {
            show = dType === TYPE_OTHERS ? TYPE_DIMENSIONS : dType;
            return false;
          }

          error = replaceAll(error, ID, `${ID === TYPE_YEAR ? ID : Description}`);

          return true;
        });
      }

      if (!show && InItems) {
        InItems.forEach(({ ID, Description }) => {
          error = replaceAll(error, ID, ` ${Description}`);
        });
      }

      setWarning(error);
    }

    setShowWarningAction(show);
    setChartData(false);
    setChartTitle(CHART_TITLE);
    visualTitle(CHART_TITLE);
    setChartTableData({});
    onLoad(false);
  };

  useEffect(() => {
    if (!sortBy[chart.type]) return;

    let allFilters = Object.values(typeConfig[chart.type] || {});
    let ID = getSourceID(details.SourceId);

    let setSortByFilter = false;
    let datasetFilter = appliedFilters[ID];

    allFilters.every((item) => {
      let foundFilter = (datasetFilter?.[item?.type] || []).find(
        (selectedItem) => selectedItem.ID === sortBy[chart.type].ID
      );

      if (foundFilter) {
        setSortByFilter = false;
        return setSortByFilter;
      } else {
        setSortByFilter = true;
      }

      return true;
    });

    const fetch = async () => {
      try {
        if (chart?.type !== CHART_TYPE_MAPVIEW && ((year && !aggregateOverTime) || aggregateOverTime || view_name)) {
          setLoading(true);
          onLoad(false);

          let {
            data = false,
            Data = [],
            chart: chartData = false,
            title = '',
            dataColumns = [],
            IsError = true,
            error = '',
            Message = '',
          } = await getVisualizeGraph({
            datasetID: details.SourceId,
            datasetName: details.SourceName,
            type: chart.apiType,
            appliedFilters,
            year,
            aggregateOverTime,
            filterSetting,
            selectedPallet,
            isFinancialYear: details?.FinancialYear,
            sort: sortBy[chart.type],
            view_name,
          });

          if (IsError === false) {
            if (Message) {
              reset(Message);
            }
            setChartData(chartData);
            const tableData = { data: data || Data, columns: dataColumns };
            setChartTableData(tableData);

            if (title) {
              let splitTitle = title.split('<br>');
              setChartTitle({
                title: splitTitle[0],
                by: splitTitle[1],
              });
              visualTitle(splitTitle[0]);
            } else {
              const t = createTitle();
              setChartTitle(t);
              visualTitle(t.title);
            }

            postClickStreamEvent({
              activity: ACTIVITIES.CREATE_VISUALIZATION,
              userid: context?.profile?.email || '',
              datasetid_1: parseInt(details.SourceId),
              datasetid_2: 0,
              datasetid_3: 0,
            });

            if (tableData?.data?.length) onLoad(chart.type);
          } else {
            reset(error);
          }

          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        message.error(t('ServerError'));
        reset();
        Sentry.captureException(`Failed to fetch graph visualizations, ` + e);
      }
    };

    if (chart?.apiType && filterSetting && sortBy[chart.type] && !setSortByFilter && !loading) {
      fetch();
    } else if (setSortByFilter) {
      setSortBy({});
      reset();
    }

    // eslint-disable-next-line
  }, [chart, year, aggregateOverTime, filterSetting, selectedPallet, sortBy, drawChart]);

  /**
   * Only on Map view
   */
  useEffect(() => {
    if (!sortBy[chart.type]) return;

    let allFilters = Object.values(typeConfig[chart.type] || {});
    let ID = getSourceID(details.SourceId);
    let setSortByFilter = false;
    let datasetFilter = appliedFilters[ID];

    allFilters.every((item) => {
      let foundFilter = (datasetFilter?.[item?.type] || []).find(
        (selectedItem) => selectedItem.ID === sortBy[chart.type].ID
      );

      if (foundFilter) {
        setSortByFilter = false;
        return setSortByFilter;
      } else {
        setSortByFilter = true;
      }

      return true;
    });

    if (
      chart?.type === CHART_TYPE_MAPVIEW &&
      filterSetting &&
      sortBy[chart.type] &&
      loadMap &&
      !setSortByFilter &&
      !loading &&
      ((year && !aggregateOverTime) || aggregateOverTime || view_name)
    ) {
      const fetch = async () => {
        try {
          const updatedRecords = checkForUpdate({
            sourceID: details.SourceId,
            types: [TYPE_DIMENSIONS, TYPE_OTHERS, TYPE_TIME, TYPE_LOCATION, TYPE_CONDITION_VISUALIZATION],
            current: changeRef.current,
            appliedFilters,
            updateInfo,
          });
          const isMerge = rest.merge ? !rest.merge : rest.merge;
          if (updatedRecords && isMerge) {
            changeRef.current = updatedRecords;
            await fetchYears(true, updatedRecords);
          } else {
            setLoading(true);
            onLoad(false);
            const region = (datasetFilter[TYPE_ALL_DIMENSIONS] || []).slice(0, 1);
            const heatMap = (datasetFilter[TYPE_MAP_HEATMAP] || []).slice(0, 1);
            const bubble = (datasetFilter[TYPE_MAP_BUBBLE] || []).slice(0, 1);

            if (region.length && (heatMap.length || bubble.length)) {
              // const dimensionPaths = getLocationAndName(details?.Dimensions?.Items, loadMap);

              /*New Code */
              let dimensionPaths = [];

              if (details?.ActualDomain === ALL_DOMAINS.ndap || (rest?.merge && domain === ALL_DOMAINS.ndap)) {
                if (region[0]?.ID === ID_STATE || region[0]?.ID === ID_COUNTRY || loadMap === ID_STATE) {
                  setLoadMap(ID_STATE);
                  setDefaultMap(DEFAULT_MAP);
                  dimensionPaths = getLocationAndName(details?.Dimensions?.Items, loadMap);
                } else {
                  dimensionPaths = getLocationAndName(details?.Dimensions?.Items, loadMap);
                }
              } else {
                if (region[0]?.ID === ID_STATE || region[0]?.ID === ID_COUNTRY) {
                  setLoadMap(ID_STATE);
                  setDefaultMap(DEFAULT_MAP);
                  dimensionPaths = getLocationAndName(details?.Dimensions?.Items, loadMap);
                } else if (region[0]?.ID === ID_BLOCK && details?.ActualDomain === ALL_DOMAINS.megdap) {
                  setLoadMap(ID_BLOCK);
                  if (rest?.merge) setDefaultMap(`${MAP_VIEW_DOMAIN[domain]}block`);
                  else setDefaultMap(`${MAP_VIEW_DOMAIN[details.ActualDomain]}block`);
                  dimensionPaths = getLocationAndName(details?.Dimensions?.Items, ID_BLOCK);
                } else {
                  setLoadMap(ID_DISTRICT);
                  if (rest?.merge) setDefaultMap(MAP_VIEW_DOMAIN[domain]);
                  else setDefaultMap(MAP_VIEW_DOMAIN[details.ActualDomain || ALL_DOMAINS.ndap]);
                  dimensionPaths = getLocationAndName(details?.Dimensions?.Items, ID_DISTRICT);
                }
              }
              /*End New Code*/

              let ip_filter_params = [];

              if (!aggregateOverTime && year && !view_name) {
                ip_filter_params.push({
                  key: 'Year',
                  value: Array.isArray(year) ? year : [parseStringToNumber(year)],
                  operator: 'IN',
                  type: 'dimension',
                });
              }

              let mapFilterEntries = Object.entries(mapFilter || {});

              for (let [mapFilterKey, mapFilterValue] of mapFilterEntries) {
                ip_filter_params.push({
                  key: mapFilterKey,
                  value: Array.isArray(mapFilterValue) ? mapFilterValue : [{ [mapFilterKey]: mapFilterValue }],
                  operator: 'IN',
                  type: 'dimension',
                });
              }

              let allowedTypes = [
                TYPE_ALL_DIMENSIONS,
                TYPE_MAP_HEATMAP,
                TYPE_MAP_BUBBLE,
                TYPE_LOCATION,
                TYPE_CONDITION_VISUALIZATION,
              ];

              if (!view_name) {
                allowedTypes.push(TYPE_DIMENSIONS);
              }

              if (!view_name && aggregateOverTime) {
                allowedTypes.push(TYPE_TIME);
              }

              const { data: response = {} } = await getDatasetDataWithFilters({
                datasetID: String(details.SourceId).split(','),
                appliedFilters,
                selectionRestrictation: mapTypeToAllow(filterSetting),
                disableCommonDimmension: true,
                onlyData: true,
                sameAsIndicaters: [TYPE_MAP_HEATMAP, TYPE_MAP_BUBBLE],
                ip_limit: 100000000,
                otherFilters: dimensionPaths,
                ip_filter_params,
                allowedTypes,
                view_name,
                avoidTypes: view_name ? [TYPE_TIME] : [],
                ip_map: 1,
                sourceName: details.SourceName,
                conditionType: TYPE_CONDITION_VISUALIZATION,
              });

              const {
                IsError = true,
                Error = '',
                Data = [],
                Headers: { Items = [] } = {},
                title: dataTitle = createTitle(),
                bucket,
              } = response;

              if (!IsError) {
                setChartData(false);
                setChartTableData({
                  data: Data,
                  columns: Items,
                  region,
                  heatMap,
                  bubble,
                  dimensionPaths,
                  bucket,
                });

                setChartTitle(dataTitle);
                visualTitle(dataTitle.title);
                let params = {};

                getSourceID(details.SourceId, true).forEach((ID, i) => {
                  params[`datasetid_${i + 1}`] = ID;
                });

                postClickStreamEvent({
                  activity: ACTIVITIES.CREATE_VISUALIZATION,
                  userid: context?.profile?.email || '',
                  ...params,
                });

                if (Data?.length) onLoad(chart.type);
              } else {
                reset(Error);
              }
            } else if (!isEmpty(chartTableData)) {
              setChartData(false);
              setChartTableData({});
              setChartTitle('');
              visualTitle('');
            }

            setLoading(false);
          }
        } catch (e) {
          setLoading(false);
          reset();
          message.error(t('ServerError'));
          Sentry.captureException(`Failed to fetch dataset data with filters, ` + e);
        }
      };

      fetch();
    }

    // eslint-disable-next-line
  }, [chart, year, aggregateOverTime, filterSetting, selectedPallet, sortBy, loadMap, drawChart]);

  useEffect(() => {
    setConditions(resetConditions());
    // eslint-disable-next-line
  }, [appliedFilters, details.SourceId]);

  const selectChartType = (selectedChart) => {
    if (loading) return;
    const selected = CHART_TYPES.find((f) => f.type === selectedChart?.type || f.type === selectedChart);

    if (selected && !isEqual(selected, chart)) {
      const { type: previousType } = chart;
      const { type } = selected;
      setFillterSettings(typeConfig[type]);

      const previousConfig = Object.values(typeConfig[previousType] || {});
      const currentConfig = Object.values(typeConfig[type] || {});

      if (type === CHART_TYPE_LINE || type === CHART_TYPE_PIVOT) {
        if (!aggregateOverTime) {
          setAggregateOverTime(true);
        }

        setSelectMode('multiple');
      } else {
        setAggregateOverTime(false);
        setSelectMode(null);
      }

      if (previousType) {
        switchVisualizationFilters({ previousConfig, currentConfig, chart, selected });
      }

      let score = getSelectedCount({
        sourceID: details.SourceId,
        includeOnly: previousConfig.map(({ type }) => type),
        oneLevel: true,
      });

      setPreviousChart(score ? chart : {});
      setChart(selected || {});
      setActiveGraphType(type);
      reset();
    }
  };

  let visualTypes = [...VISUALIZE_TYPES, TYPE_LOCATION];

  if (!rest.merge) {
    visualTypes = [...visualTypes, TYPE_TIME, TYPE_DIMENSIONS, TYPE_CONDITION_VISUALIZATION];
  }

  const resetVisulaization = () => {
    resetFilters(visualTypes);
    setChart({});
    setActiveGraphType('');
    setChartTitle({});
    setChartData(false);
    onLoad(false);
    removeExtras();
  };

  const getGranularity = () => {
    const countryLevel = GRANULARITIES.country.toLowerCase();
    if (!rest.merge) return isEqual(details?.LocalityGranularity?.toLowerCase(), countryLevel);
    else {
      const location = details?.Dimensions?.Items;
      if (location?.length) {
        if (location?.length === 1) return isEqual(location[0]?.DisplayName?.toLowerCase(), countryLevel);
        else return isEqual(location[location?.length - 1 || 0]?.DisplayName?.toLowerCase(), countryLevel);
      }
    }
  };

  const selectedLength = useMemo(() => (chart && Object.keys(chart).length) || 0, [chart]);
  const firstSourceID = useMemo(() => getLastItem(details.SourceId), [details.SourceId]);

  return (
    <Layout>
      <Sider className={`${mergeStyles.mergeSider} border-right`} width={300} id="visualize-left-panel">
        <div className="joyride-datasetvisualization-step2 joyride-MergeResultDataVisualize-Step3">
          <Row>
            <Col span={24}>
              <Title className={'font-16 mb-3'}>{title}</Title>
            </Col>
          </Row>

          {!selectedLength ? (
            <Row>
              <Col>
                <h5 className={`font-14 font-weight-normal mb-2`}>{t('SelectChart')}</h5>
              </Col>
              <Col>
                <ChartList
                  onSelectChartType={selectChartType}
                  loading={loading}
                  view_name={view_name}
                  isStandarised={checkStandarisation(details)}
                  merge={rest?.merge}
                  granularity={getGranularity()}
                />
              </Col>
            </Row>
          ) : (
            <ChartListDropdown
              selectedChart={chart}
              selectChartType={selectChartType}
              loading={loading}
              isStandarised={checkStandarisation(details)}
              view_name={view_name}
              merge={rest?.merge}
              granularity={getGranularity()}
            />
          )}

          <Row className="mb-5">
            <Col {...GridFixedSpan.size.full}>
              <LeftChartsList
                selectedChart={chart}
                previousChart={previousChart}
                details={details}
                applyModalTo="#main-content"
                loading={loading}
                selectedFilters={(settings) => {
                  setFillterSettings(settings || {});
                }}
                onSelectChartType={selectChartType}
                merge={rest.merge}
              />
            </Col>
          </Row>
        </div>
      </Sider>
      <Content className={mergeStyles.mergeContent} id="main-content">
        <Spin spinning={loading} tip={t('LoadingVisualization')}>
          <Row justify="start">
            <Col span={17}>
              <DatasetFilters
                exclude={
                  view_name
                    ? [FILTER_INPUTS.ShowHideFilter, FILTER_INPUTS.OtherDimensionFilter]
                    : [FILTER_INPUTS.ShowHideFilter]
                }
                details={details}
                view_name={view_name}
                applyModalTo="#main-content"
                classnames={'visualisationFilter'}
                isVisualisation={true}
                conditions={conditions}
                conditionType={TYPE_CONDITION_VISUALIZATION}
                {...rest}
              />

              <TextSelectedCount sourceID={firstSourceID} dataIndex={'StateName'} />

              <ChartWarning
                onClose={() => {
                  setWarning(false);
                }}
                onClick={(type) => {
                  setWarning(false);
                  setFilter({ sourceID: details.SourceId, type, show: true });
                }}
                isVisible={warning}
                message={warning}
                applyModalTo="#main-content"
                showAction={showWarningAction}
              />
            </Col>
            <Col span={7}>
              <Popconfirm
                title={t('ResetConfirmation')}
                onConfirm={resetVisulaization}
                okText="Yes"
                cancelText="No"
                placement="bottomRight"
              >
                <FatLanguageButton
                  text={t('ResetFilters')}
                  iconLeft={<StopOutlined className="ml-n1" />}
                  className="float-right rounded"
                  type="warning"
                  disabled={
                    getSelectedCount({
                      sourceID: details.SourceId,
                      includeOnly: visualTypes,
                    }) === 0
                  }
                />
              </Popconfirm>
            </Col>
          </Row>

          {!selectedLength ? (
            <Row justify="center">
              <CustomIcon type={'Visulaize'} width={'100%'} height={300} />
            </Row>
          ) : (
            <Row className="mt-3">
              <Col span={24}>
                <VisualizationCharts
                  defaultMap={defaultMap}
                  selectedChart={chart}
                  chart={chartData}
                  sourceID={details.SourceId}
                  loadingYears={loadingYears}
                  selectedYear={year}
                  selectMode={selectMode}
                  years={selectedYears?.length ? selectedYears : years}
                  charTitle={charTitle}
                  onChangeAggregateOverTime={(e) => setAggregateOverTime(e.target.checked)}
                  aggregateOverTime={aggregateOverTime}
                  onChangeYear={setYear}
                  filterSetting={filterSetting}
                  colorPallet={colorPallet}
                  selectedPallet={chart.type === CHART_TYPE_MAPVIEW ? selectedOtherPallet : selectedPallet}
                  setSelectedPallet={(theme) => {
                    if (chart.type === CHART_TYPE_MAPVIEW) {
                      setSelectedOtherPallet(theme);
                    } else {
                      setSelectedPallet(theme);

                      if (selectedOtherPallet) {
                        setSelectedOtherPallet(theme);
                      }
                    }
                  }}
                  setGridAndLines={setGridAndLines}
                  gridAndLine={gridAndLine}
                  chartTableData={chartTableData}
                  setSortBy={(sorted) => setSortBy((ps) => ({ ...ps, [sorted.type]: sorted }))}
                  sorted={sortBy[chart.type]}
                  initialSortBy={initialSortBy[chart.type]}
                  setLoadMap={setLoadMap}
                  setMapFilter={(code) => {
                    setMapFilter(code);
                  }}
                  loadingData={loading}
                  setChartTitle={(t) => {
                    setChartTitle(t);
                    visualTitle(t?.title);
                  }}
                  merge={rest.merge}
                  domain={details?.ActualDomain || domain}
                />
              </Col>
            </Row>
          )}
        </Spin>
      </Content>
    </Layout>
  );
};

export default VisualizationsLayout;
