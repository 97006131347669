import React from 'react';
import { Tooltip } from 'antd';
import i18n from 'i18next';
import { voidFunction } from '../../utils';
import { FATLANGUAGES } from '../../constants';

const FatLanguageText = ({ className = '', text, onClick = voidFunction, ...rest }) => {
  const trimFunction = (text, count) => {
    return text.slice(0, count) + '...';
  };

  const currentLanguage = i18n.language;
  let charCount = text?.length;
  if (FATLANGUAGES.includes(currentLanguage)) {
    charCount = text?.length / 2;
  }

  return (
    <>
      {text?.length > charCount ? (
        <Tooltip title={text}>
          <span className={`${className}`} onClick={onClick} {...rest}>
            {trimFunction(text, charCount)}
          </span>
        </Tooltip>
      ) : (
        <span className={`${className}`} onClick={onClick} {...rest}>
          {text}
        </span>
      )}
    </>
  );
};

export default FatLanguageText;
