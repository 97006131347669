import React from 'react';
import jwt from 'jsonwebtoken';
import * as Sentry from '@sentry/browser';

import { uploadProfilePicture } from '../../services';
import { t } from 'i18next';

import Context from '../../context';
import { Upload, message } from '../../components';
import UserAvatar from '../UserAvatar';

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error(t('UploadJpgOnly'));
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error(t('Imagelimit'));
  }

  return isJpgOrPng && isLt2M;
};

const AvatarUpload = () => {
  const [loading, setLoading] = React.useState(false);
  const [store, setContext] = React.useContext(Context);

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader overflow-hidden"
      showUploadList={false}
      beforeUpload={beforeUpload}
      accept="image/png, image/jpeg, image/jpg, image/JPEG, image/JPG, image/PNG"
      customRequest={({ file }) => {
        const fileName = `${store.profile['cognito:username']}.${file.type.split('/')[1]}`;
        uploadProfilePicture({
          file,
          fileName,
          setLoading,
          setData: ({ avatar, IsError = false, token }) => {
            if (!IsError) {
              setContext((state) => {
                const profile = jwt.decode(token);
                const newState = {
                  ...state,
                  profile,
                  avatar,
                };

                sessionStorage.setItem('token', token);
                return newState;
              });
              message.success(t('UpdatedProfilePic'));
            } else {
              message.error(t('UpdatedProfilePicFailed'));
              Sentry.captureException('Failed to update profile picture');
            }
          },
        });
      }}
    >
      <UserAvatar size={92} color="blue" loading={loading} />
    </Upload>
  );
};

export default AvatarUpload;
