import React from 'react';
import { SymboleIcon } from '../../constants';

const SearchIconType = (props) => {
  const iconName = props?.path ?? '';
  const clasName = props?.className ?? '';
  const size = props?.size ?? '';
  const Icon = SymboleIcon[iconName];
  if (Icon) return <img src={Icon} alt={iconName} className={clasName} width={size} />;
  else return <></>;
};

export default SearchIconType;
