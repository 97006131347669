import React from 'react';

import { Avatar, Spin } from '../../components';
import { presetPalettes } from '@ant-design/colors';
import { LoadingOutlined } from '@ant-design/icons';
import Context from '../../context';

const UserAvatar = ({ name = false, size = 40, color = '', loading = false }) => {
  const [store] = React.useContext(Context);
  if (!name) {
    name = store.profile?.name || 'avatar';
  }

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined />}>
      <Avatar
        src={store?.avatar}
        className="avatar"
        size={size}
        style={{ backgroundColor: presetPalettes[color]?.primary }}
      >
        {name?.substring(0, 2).toUpperCase()}
      </Avatar>
    </Spin>
  );
};

export default UserAvatar;
