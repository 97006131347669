import { t } from 'i18next';
import { Alert } from '../../components';

const AlertServerError = ({
  title = t('ApiError', { error: t('InternalServerError') }),
  description = t('ServerError'),
}) => {
  return <Alert message={title} description={description} type="error" showIcon />;
};

export default AlertServerError;
