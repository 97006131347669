import React, { useState, useEffect, useContext } from 'react';
import Context from '../../context';

const getDynamicPath = (domain) => domain;

const Footer = () => {
  const [dynamicComponent, setDynamicComponent] = useState(null);
  const [context, ,] = useContext(Context);

  useEffect(() => {
    const loadDynamicComponent = async () => {
      const dynamicModule = await import(`./${getDynamicPath(context.domain)}`);
      setDynamicComponent(() => dynamicModule.default);
    };

    loadDynamicComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dynamicComponent) {
    return <></>;
  }

  const DynamicComponent = dynamicComponent;

  return (
    <footer>
      <DynamicComponent />
    </footer>
  );
};

export default Footer;
