import React from 'react';
import { Typography, Row, Col } from '../../components';
import { Link } from 'react-router-dom';
import s from './index.module.less';

const { Paragraph } = Typography;

const ResponsiveHelpScreen = (props) => {
  const { type } = props;
  return (
    <div>
      <Row className={s.responsiveContainer} justify="center" align="middle">
        {type === 'mobile' && (
          <Col span={24}>
            <img alt="rotateimg" src="/static/assets/images/desktopview.svg" className={s.rotateDiv_img}></img>
            <Paragraph className={s.rotateDiv}>Switch to Desktop</Paragraph>
            <Paragraph className={s.rotateDiv_rotateTxt}>
              NDAP website is best viewed on Desktop <br></br>as the datasets cannot be displayed well <br></br>on
              mobile devices. Please browse via<br></br> Desktop for complete functionality.
            </Paragraph>

            <Link to="/" className={s.label_back_link}>
              <Paragraph className={s.label_back}>Back</Paragraph>
            </Link>
          </Col>
        )}
        {type === 'tablet' && (
          <Col span={24}>
            <img alt="rotateimg" src="/static/assets/images/Rotate.svg" className={s.rotateDiv_img}></img>
            <Paragraph className={s.rotateDiv}>Rotate Device</Paragraph>
            <Paragraph className={s.rotateDiv_rotateTxt}>
              Please rotate your device to landscape <br></br> mode to access the website
            </Paragraph>

            <Link to="/" className={s.label_back_link}>
              <Paragraph className={s.label_back}>Back</Paragraph>
            </Link>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ResponsiveHelpScreen;
