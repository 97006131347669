import { useEffect, useContext } from 'react';
import axios from 'axios';
import * as config from './config';
import { Auth } from './services';
import Context from './context';
import isEqual from 'lodash/isEqual';

const instance = axios.create({
  baseURL: config.REACT_APP_API_BASE_URI,
});
let sourceRequest = {};

const routesExemptedForCancellation = [config.REACT_APP_CLICKSTREAM_URI, 'export', 'details'];

const AxiosInterceptor = (props) => {
  const [context, ,] = useContext(Context);
  useEffect(() => {
    const interceptor = instance.interceptors.request.use(
      async (req) => {
        // If the application exists cancel
        if (!routesExemptedForCancellation.find((x) => req.url.includes(x))) {
          if (sourceRequest[req.url] && isEqual(req.data, sourceRequest[req.url].params)) {
            sourceRequest[req.url].cancel('Automatic cancellation');
          }
          // Store or update application token
          const axiosSource = axios.CancelToken.source();
          sourceRequest[req.url] = { cancel: axiosSource.cancel, params: req.data };
          req.cancelToken = axiosSource.token;
        }
        const tryToken = async () => {
          try {
            const bearerToken = await Auth.getTokenAndRefreshOnExpire();
            if (bearerToken) {
              req.headers = {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
              };
            }
          } catch {
            window.history.pushState({}, '', '/');
            sessionStorage.removeItem('token');
          }
        };
        await tryToken();
        let isAwsCreds = true;
        const localKeys = Object.keys(localStorage);
        if (!localKeys.find((a) => a.includes('CognitoIdentityServiceProvider'))) isAwsCreds = false;
        if (!localKeys.find((a) => a.includes('refreshToken'))) isAwsCreds = false;
        if (!localKeys.find((a) => a.includes('accessToken'))) isAwsCreds = false;
        if (!localKeys.find((a) => a.includes('idToken'))) isAwsCreds = false;
        if (context.isAuth && !isAwsCreds) {
          props.logout();
        }
        return req;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

    return () => instance.interceptors.request.eject(interceptor);
    // eslint-disable-next-line
  }, [context.isAuth]);

  return props.children;
};

export default instance;
export { AxiosInterceptor };
