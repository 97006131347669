import React, { Fragment } from 'react';
import { t } from 'i18next';
import { Row, Col, Typography, Table, Spin, Button, Modal, FatLanguageButton } from '../../../components';
import { GridFixedSpan } from '../../../constants';
import { cleanName } from '../../../utils/common';
import uniq from 'lodash/uniq';
import FailedReport from '../../Hoc/FailedReport';
import MatchTypes from './MatchTypes';
import { makeKeyToColumnName, voidFunction } from '../../../utils/common';
import CustomIcon from '../../CustomIcon';
import s from './index.module.less';
import { CloseButton } from '../../../shared';

const { Paragraph, Title, Text } = Typography;

export const FindHeader = (data, defaultValue = '') => {
  for (const [, value] of Object.entries(data)) {
    if (value['header']) return value['header'];
  }

  return defaultValue;
};

const ParseData = (data = {}, parsedData = {}, forcedDatakey = false) => {
  for (let [dataKey, value] of Object.entries(data)) {
    const hasTableHeader = value.table_header;
    dataKey = forcedDatakey || dataKey;
    if (!parsedData[value.subheader]) {
      parsedData[value.subheader] = hasTableHeader ? [{ columns: [], tableData: [], data: false, isTable: true }] : [];
    }

    if (value.table_header) {
      let dataReference = parsedData[value.subheader][0];
      if (!dataReference['columns'].length) {
        dataReference['columns'].push({ title: makeKeyToColumnName(value.table_header), dataIndex: 'key' });
        dataReference['columns'].push({ title: makeKeyToColumnName(value.table_value), dataIndex: 'value' });
      }

      if (!dataReference.data) {
        dataReference.data = value;
      }

      value['dataKey'] = dataKey;
      dataReference['tableData'].push(value);
      parsedData[value.subheader][0] = dataReference;
    } else {
      const firtObject = Object.values(value)[0];
      const isListOfObject = typeof firtObject === 'object';

      if (isListOfObject) {
        parsedData = ParseData(value, parsedData, dataKey);
      } else {
        if (parsedData[value.subheader].length) {
          if (!parsedData[value.subheader][0]['items']) {
            parsedData[value.subheader][0]['items'] = [];
          }

          value['dataKey'] = dataKey;
          parsedData[value.subheader][0]['items'].push(value);
          parsedData[value.subheader][0]['items'] = uniq(parsedData[value.subheader][0]['items']);
        } else {
          value['dataKey'] = dataKey;
          parsedData[value.subheader].push(value);
        }
      }
    }
  }

  return parsedData;
};

const DataValue = ({ title, description = '', unit = '' }) => {
  return (
    <>
      <Title level={5} className="font-16">
        {title}
      </Title>
      <Text type="secondary" className="font-14">
        {description}
        {unit ? (
          <small>
            <i>({unit})</i>
          </small>
        ) : (
          ''
        )}
      </Text>
    </>
  );
};

const DataResult = ({ status = '', showFailedRport = voidFunction }) => {
  const isPassed = status.toLowerCase() === 'pass';

  return (
    <Text type={isPassed ? 'success' : 'danger'} onClick={showFailedRport}>
      <FatLanguageButton
        text={t(isPassed ? 'Complete' : 'Missing')}
        iconLeft={
          <CustomIcon type={isPassed ? 'Checked' : 'ExclamationCircled'} width={14} height={12} verticalAlign={1} />
        }
        type="default"
        size="small"
        className={`rounded font-16 border-0 d-inline-flex align-items-center ${isPassed ? 'btn-gray' : 'btn-warning'}`}
        style={{ cursor: !isPassed ? 'pointer' : 'default' }}
      />
    </Text>
  );
};

const FailDataTable = (props) =>
  FailedReport(({ failedReport, show = false }) => {
    props.callBack(failedReport?.data);
    if (!show) return null;
    return (
      <DataTable
        columns={Object.keys(failedReport?.data?.length > 0 ? failedReport.data[0] : {}).map((key) => ({
          title: makeKeyToColumnName(key),
          dataIndex: key,
          render: (text) => {
            if (!text) return '-';
            if (!isNaN(text)) return Number(text);

            return text;
          },
        }))}
        tableData={failedReport?.data?.map((d, i) => ({ ...d, key: `${i}-key` }))}
        isFetching={failedReport.isFetching}
      />
    );
  }, props)();

export const PaginationRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Button type="link" className="p-0" size="small">
        &#x3c; Prev
      </Button>
    );
  }

  if (type === 'next') {
    return (
      <Button type="link" className="p-0" size="small">
        Next &#x3e;
      </Button>
    );
  }

  return originalElement;
};

const DataTable = ({ columns = [], tableData = [], isFetching = false }) => {
  let scroll = { x: true };
  let columnsCout = columns.length;

  if (columnsCout > 3) {
    scroll.x = columnsCout * 200;
    if (tableData.length > 5) scroll['y'] = 300;
  }

  return (
    <Spin spinning={isFetching}>
      <Table
        className="border-0 mt-2"
        dataSource={tableData}
        columns={columns}
        bordered
        pagination={{
          hideOnSinglePage: true,
          size: 32,
          className: 'paginationStyle',
          itemRender: PaginationRender,
        }}
        scroll={scroll}
        size="small"
      />
    </Spin>
  );
};

export const getUnit = (key) => {
  const staticUnits = { Percentage_Weight: 'source units', Missing_Weights: 'matches', Count_Weight_Sums: 'LGD units' };
  for (const [dataKey, dataValue] of Object.entries(staticUnits)) {
    if (new RegExp(dataKey, 'gi').test(key)) {
      return dataValue;
    }
  }
  return '';
};

const DataContentRow = ({ data, dataUnit = false }) => {
  let unit = dataUnit ? dataUnit : false;
  let value = (data.value || '').split('/');

  return (
    <>
      <Col {...GridFixedSpan.size.six}>
        <Title level={5} className={unit ? 'font-16' : 'text-capitalize font-16'}>
          {data.key}
        </Title>
      </Col>

      <Col xs={12} sm={8} md={8} xl={8} className="font-16">
        <b>{value[0]}</b>
        {(value[1] && <Text type="secondary">{`/${value[1]}`}</Text>) || null}
        <Text type="secondary" className="ml-1">
          {unit || null}
        </Text>
      </Col>
    </>
  );
};

const DataContent = ({ data, sourceID, dataUnit, showMatchType = false, downloading = false }) => {
  const [loadFailedReport, setFailedRport] = React.useState(false);
  const [missingCount, setMissingCount] = React.useState(0);
  const isFailed = data?.status?.toLowerCase() === 'fail';
  const status = data?.status;

  const getMissingCount = (value) => {
    setMissingCount(value?.length || 0);
  };

  return (
    <>
      {data.items ? (
        <div className={(!status && 'border-top p-3') || ''}>
          {data.key && data.dataKey && (
            <Row className={(status && 'border-top p-3') || ''}>
              <DataContentRow data={data} dataUnit={dataUnit} />

              {(status && (
                <Col xs={12} sm={4} md={4} xl={4} className="text-right">
                  <DataResult
                    {...data}
                    loadFailedReport={loadFailedReport}
                    showFailedRport={() => isFailed && setFailedRport(!loadFailedReport)}
                  />
                </Col>
              )) ||
                null}

              {(showMatchType && (
                <Col {...GridFixedSpan.size.two} className="text-right">
                  <MatchTypes downloading={downloading} />
                </Col>
              )) ||
                null}

              {isFailed ? (
                <Modal
                  style={{ top: 20 }}
                  width={'60%'}
                  title={<span className="text-capitalize">{`${data.subheader}: ${data.key}`}</span>}
                  visible={loadFailedReport}
                  onCancel={() => setFailedRport((ps) => !ps)}
                  footer={false}
                  closeIcon={<CloseButton type="black" />}
                >
                  <MissingInfo value={data.value} missing={missingCount} />
                  <FailDataTable callBack={getMissingCount} load={loadFailedReport} sourceID={sourceID} id={data.id} />
                </Modal>
              ) : null}
            </Row>
          )}
          {data.items.map((details, i) => (
            <DataContent
              data={details}
              dataUnit={dataUnit}
              key={`${details.key}-${i}`}
              sourceID={sourceID}
              showMatchType={i === 0 && cleanName(details.subheader).includes('matchtype')}
            />
          ))}
        </div>
      ) : (
        <Row className={(status && 'border-top p-3') || ''}>
          <DataContentRow data={data} dataUnit={dataUnit} />

          {(status && (
            <Col xs={12} sm={4} md={4} xl={4} className="text-right">
              <DataResult
                {...data}
                loadFailedReport={loadFailedReport}
                showFailedRport={() => isFailed && setFailedRport(!loadFailedReport)}
              />
            </Col>
          )) ||
            null}
          {(showMatchType && (
            <Col {...GridFixedSpan.size.two} className="text-right">
              <MatchTypes downloading={downloading} />
            </Col>
          )) ||
            null}

          {isFailed ? (
            <Modal
              style={{ top: 20 }}
              width={'60%'}
              title={<span className="text-capitalize">{`${data.subheader}: ${data.key}`}</span>}
              visible={loadFailedReport}
              onCancel={() => setFailedRport((ps) => !ps)}
              footer={false}
              closeIcon={<CloseButton type="black" />}
            >
              <MissingInfo value={data.value} missing={missingCount} />
              <FailDataTable callBack={getMissingCount} load={loadFailedReport} sourceID={sourceID} id={data.id} />
            </Modal>
          ) : null}
        </Row>
      )}
    </>
  );
};

const DataRow = ({ sourceID, data = {}, length = 0, index = 0, downloading = false }) => {
  const { isTable = false } = data;
  let dataSource = data;

  if (isTable) {
    dataSource = data.data;
  }

  return (
    <Row>
      <Col {...GridFixedSpan.size.four} className={`p-3 background-lightish-grey ${s.reportInfo}`}>
        <DataValue title={dataSource.subheader} description={dataSource.sub_header_desc} sourceID={sourceID} />
      </Col>
      <Col
        {...GridFixedSpan.size.eight}
        className={`${length > 1 && index + 1 === length ? 'border-bottom' : 'border-bottom'} ${s.reportValues}`}
      >
        {isTable ? (
          <DataContent
            data={{ items: data.tableData }}
            sourceID={sourceID}
            dataUnit={t('Matches')}
            downloading={downloading}
          />
        ) : (
          <DataContent data={data} sourceID={sourceID} downloading={downloading} />
        )}
      </Col>
    </Row>
  );
};

const MissingInfo = (props) => {
  const { missing, value } = props;
  return (
    <Paragraph className={`font-12`}>
      <Text>
        <strong>{value}</strong>
      </Text>
      <Text className="mr-2 ml-2">|</Text>
      <Text>
        {t('Missing')} :{' '}
        <Text type={'danger'}>
          <strong>{missing}</strong>
        </Text>
      </Text>
    </Paragraph>
  );
};

const StarProcessing = ({ sourceID, data = {}, star = 1, downloading = false }) => {
  const keys = Object.keys(data);
  if (!keys.length) return null;
  const header = FindHeader(data, t('StarReport'));
  const stars = [];
  for (let i = 1; i <= star; i++) {
    stars.push(<CustomIcon type={'StarFilled'} className="star" key={`star-${i}`} />);
  }

  const content = ParseData(data);
  if (!content) return null;

  const dataKeys = Object.keys(content);
  const dataLength = dataKeys.length;

  return (
    <div id={cleanName(header)} className={`pt-4 ${s.reportSections}`}>
      <div className="font-18 font-weight-bold pb-3 pr-3">
        {header.replace(' Star: ', '. ')} <span className="float-right">{stars}</span>
      </div>
      <div className="mb-5">
        <Row gutter={[0, 4]}>
          {dataKeys.map((header, i) => {
            const item = content[header];

            return (
              <Fragment key={`${i}-key`}>
                {item.map((d, j) => (
                  <Col {...GridFixedSpan.size.full} key={header + '-' + i + j} className={`mb-2 ${s.reportMainCol}`}>
                    <DataRow data={d} sourceID={sourceID} length={dataLength} index={i} downloading={downloading} />
                  </Col>
                ))}
              </Fragment>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default StarProcessing;
