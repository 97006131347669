import NdapStandardDoc from '../../assets/pdf/Standardization_Documentation.pdf';
import kadapStandardDoc from '../../assets/pdf/Standardization_Documentation_KADAP.pdf';
import NdapUseCase from '../../assets/pdf/Use_Cases_list_NDAP.pdf';
import NdapMergeWorks from '../../assets/pdf/how_merge_works.pdf';
import KadapMergeWorks from '../../assets/pdf/how_merge_works_KADAP.pdf';
import NdapProcessingReport from '../../assets/pdf/processing_report.pdf';
import KadapProcessingReport from '../../assets/pdf/processing_report_KADAP.pdf';

const StandardizationDocument = {
  ndap: NdapStandardDoc,
  kadap: kadapStandardDoc,
};

const SampleUseCaseDocument = {
  ndap: NdapUseCase,
};

const MergeDocument = {
  ndap: NdapMergeWorks,
  kadap: KadapMergeWorks,
};

const ProcessingReportDocument = {
  ndap: NdapProcessingReport,
  kadap: KadapProcessingReport,
};

export const getDynamicFile = (domain) => {
  const standardization = StandardizationDocument[domain] || NdapStandardDoc;
  const sampleUseCase = SampleUseCaseDocument[domain] || NdapUseCase;
  const mergeWorks = MergeDocument[domain] || NdapMergeWorks;
  const processingReport = ProcessingReportDocument[domain] || NdapProcessingReport;
  return {
    standardization,
    sampleUseCase,
    mergeWorks,
    processingReport,
  };
};

export default getDynamicFile;
