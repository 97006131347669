import { useContext, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/browser';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
import { t } from 'i18next';
import { CloseOutlined, DownOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { Card, Row, Col, Empty, message, Button, Tooltip, Spin } from '../../components';
import LocationSearch from './LocationSearch';

import { COMBINED_FILTERS, LOCATION_MODES, ScreenSize, TYPE_LOCATION, TYPE_TIME } from '../../constants';
import { useFilterContext } from '../../providers/FilterContextProvider';
import {
  getAllStatesWithDistrict,
  getGeoLocations,
  getLocationFilterSearchIntial,
  getSelectedLocationCount,
} from '../../services/dataset';
import {
  StateName,
  DistrictName,
  SubDistrictName,
  translationKeys,
  StateCode,
  DistrictCode,
  SubDistrictCode,
  LocationCodes,
} from './constant';

import './index.module.less';
import Context from '../../context';
import { useDeviceType } from '../Hooks/useDeviceType';
import { getLastItem, voidFunction } from '../../utils/common';

export const FilterHeader = ({ header, suffix }) => (
  <div className="pt-2 pb-2 pl-3 pr-3 border-bottom bg-lightish-grey overflow-auto">
    <span className="text-uppercase font-weight-500">{header}</span>
    {suffix !== undefined && <div className="float-right availableCount">{suffix}</div>}
  </div>
);

export const FilterContainer = ({ children, isMobile = false, isVisualisation }) => (
  <div
    className={`filterContainer ${isMobile ? 'mobileHeight' : isVisualisation ? 'visualize-height' : 'defaultHeight'}`}
  >
    {children}
  </div>
);

export const getID = (itemData) => itemData.id || itemData.ID || itemData.Id;

export const EmptyContent = ({ dataKey = 'NoFilterSelected' }) => {
  return (
    <div className="d-flex justify-content-center d-flex-column h-100">
      <Empty description={t(dataKey)} />
    </div>
  );
};

const mapLocationCodes = (dataRecords, psCodes = {}, isRecursive = false) => {
  let nodeCode = false;
  let childNode = false;

  if (!Array.isArray(dataRecords)) {
    dataRecords = [dataRecords];
  }

  dataRecords.forEach((dataItem, index) => {
    dataRecords[index]['total'] = 1;

    if (!nodeCode) {
      let { lookUpKey, column } = LocationNodeType(dataItem, false) || {};
      nodeCode = column;
      childNode = lookUpKey;
    }

    if (dataItem[childNode] && !Array.isArray(dataItem[childNode])) {
      dataItem[childNode] = [dataItem[childNode]];
    }

    psCodes[nodeCode] = getID(dataItem);

    if (childNode && dataItem[childNode]?.length) {
      if (!dataItem[nodeCode]) {
        dataRecords[index][childNode] = dataItem[childNode].map((record) => {
          return {
            ...record,
            ...psCodes,
          };
        });

        mapLocationCodes(dataItem[childNode], psCodes, true);
      }

      dataRecords[index]['total'] = dataItem[childNode].length;
    }

    if (!isRecursive) {
      psCodes = {};
    }
  });

  return dataRecords;
};

const createLocationPath = (item) =>
  [getID(item), item[StateCode], item[DistrictCode], item[SubDistrictCode]].filter((id) => id).join('-');

export const useLocationSelectedFilter = ({ checked }) => {
  const [loacationMode, setLoacationMode] = useState(LOCATION_MODES.Available);
  const { isDesktop } = useDeviceType();

  const all = useMemo(() => {
    let obj = {};
    let selected = [];

    checked?.forEach?.((item, index) => {
      let keys = Object.keys(item?.checked || {}) || [];

      keys.forEach((checkedKey) => {
        if (item.checked[checkedKey]) {
          if (!obj[checkedKey]) {
            obj[checkedKey] = {};
          }
          const ID = `${checkedKey}ID`;
          obj[checkedKey][item[ID]] = true;
          obj[checkedKey][createLocationPath(item)] = true;

          selected.push({
            key: checkedKey,
            id: item[ID],
            name: item[checkedKey],
            position: index,
          });
        }
      });
    });

    return { checkedObject: obj, selected };
  }, [checked]);

  return { ...all, isMobile: !isDesktop, loacationMode, setLoacationMode };
};

export const LocationNodeType = (item, levels = []) => {
  const key = Object.keys(translationKeys).find((typeKey) => item[typeKey]);
  let options = translationKeys[key] || {};

  if (levels.length && Array.isArray(options)) {
    options = getOptions(options, levels);
  } else if (levels && !levels.find(({ ID }) => ID?.toLowerCase() === options.lookUpID?.toLowerCase())) {
    options = { ...options, lookUpID: null, lookUpKey: null };
  }

  let result = {
    key,
    ...options,
  };

  return result;
};

export const LocationFilter = ({
  sourceID,
  dimensions,
  type = TYPE_LOCATION,
  isMobile = false,
  mode = LOCATION_MODES.Available,
  height = '50vh',
  isSearchValue = '',
  classnames,
  mergeDimensions,
  granularity = false,
  onSearchDone = voidFunction,
  view_name = '',
  isVisualisation,
}) => {
  const [lastGranularity, setLastGranularity] = useState('');
  const [searchReset, setSearchReset] = useState(isSearchValue);
  const [selectedCount, setSelectedCount] = useState(0);
  const [loadingCount, setLoadingCount] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [initLoading, setLoading] = useState(false);

  const { getAny, setChecked, filters } = useFilterContext();
  const [locationResults, setLocationResults] = useState([]);
  const checked = getAny({
    sourceID,
    type,
    key: 'checked',
  });

  const [context] = useContext(Context);

  const getLevelsByDimensions = (data) => {
    const levelsList =
      data?.filter(
        ({ DimensionType, ID }) => DimensionType?.toLowerCase() === TYPE_LOCATION && !ID?.toLowerCase().includes('code')
      ) || [];
    return levelsList;
  };

  let isVisible = useMemo(() => {
    let firstSourceID = getLastItem(sourceID);
    let status = false;

    if (
      filters[firstSourceID]?.[COMBINED_FILTERS]?.show ||
      filters[firstSourceID]?.[TYPE_LOCATION]?.show ||
      filters[firstSourceID]?.[TYPE_TIME]?.show
    ) {
      status = true;
    }

    return status;
  }, [sourceID, filters]);

  useEffect(() => {
    setSearchReset(isSearchValue);
  }, [isSearchValue]);

  const levels = useMemo(() => {
    let typeLocation = [];
    if (dimensions?.length) {
      typeLocation = getLevelsByDimensions(dimensions);
    } else {
      const uniqueMergeDimensions = uniqBy(mergeDimensions, 'ID');
      typeLocation = getLevelsByDimensions(uniqueMergeDimensions);

      if (granularity) {
        typeLocation = typeLocation.slice(0, typeLocation.findIndex(({ ID }) => ID.includes(granularity)) + 1);
      }
    }

    return typeLocation.reverse();
  }, [dimensions, mergeDimensions, granularity]);

  const lowestGranularity = t(
    !translationKeys[levels[0]?.ID]
      ? 'States'
      : (translationKeys[levels[0]?.ID + '-other'] || translationKeys[levels[0]?.ID])?.totalKey
  );

  const totalAvailableAfterSubstraction = Number(total) - Number(selectedCount);
  const totalAvailableCount = !total
    ? Number(total)
    : totalAvailableAfterSubstraction > 0
    ? totalAvailableAfterSubstraction
    : 0;
  const totalAvailable =
    (loadingCount && (
      <span>
        <LoadingOutlined /> {lowestGranularity}
      </span>
    )) ||
    `${totalAvailableCount.toLocaleString()} ${lowestGranularity}`;
  const totalSelected = `${(!loadingCount && Number(selectedCount).toLocaleString()) || ''} ${lowestGranularity}`;

  const refreshCount = (selectedItems) => {
    setLoadingCount(true);
    getSelectedLocationCount({ sourceID, appliedFilters: selectedItems, granularity }).then(
      ({ data: { IsError, records } }) => {
        if (!IsError) {
          setSelectedCount(records);
        } else {
          message.error(t('ServerError'));
        }

        setLoadingCount(false);
      }
    );
  };

  useEffect(() => {
    const setCount = () => {
      const selected = getAny({
        sourceID,
        type,
        key: 'checked',
      });

      if (selected.length) {
        refreshCount(selected);
      } else {
        setSelectedCount(0);
      }
    };

    const initialLoad = async () => {
      setCount();
      setLoading(true);

      try {
        const { IsError, records = { total: 0, data: [] } } = await getAllStatesWithDistrict({
          sourceID,
          granularity,
          view_name,
        });

        if (IsError === false) {
          let dataRecords = records.data || [];
          mapLocationCodes(dataRecords);
          setData(dataRecords);
          setTotal(records.total);
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
        Sentry.captureException(`Failed to fetch initial filters, ` + e);
      }
    };

    if (sourceID && isVisible && ((!granularity && !data.length) || (granularity && granularity !== lastGranularity))) {
      setLastGranularity(granularity);
      initialLoad();
    }

    if (data?.length && !isVisible) {
      setCount();
    }

    if (!isVisible) {
      resetSearch();
    }

    // eslint-disable-next-line
  }, [sourceID, isVisible, granularity]);

  const locationSearch = async (item) => {
    if (!item) {
      setLocationResults([]);
      onSearchDone();
      return;
    }

    const { LocationType, LGDName, ...others } = item;
    const ip_lgdcode = { LGDName };

    Object.keys(others).forEach((key) => {
      if (LocationCodes.includes(key) && others[key]) {
        ip_lgdcode[key] = others[key];
      }
    });

    const params = {
      ip_sourceid: [sourceID],
      ip_location: LocationType || '',
      ip_lgdcode,
    };

    try {
      setLoading(true);
      const { data } = await getLocationFilterSearchIntial(params);
      const { records, IsError = false } = data;

      if (IsError) {
        message.error(t('FailedFetchingSearchFilters'));
      } else {
        mapLocationCodes(records);
        setLocationResults(records);
      }

      setLoading(false);
    } catch (e) {
      if (axios.isCancel(e)) return;
      Sentry.captureException(`Failed to fetch location filter search results, ` + e);
      message.error(t('FailedFetchingSearchFilters'));
      setLoading(false);
    }

    onSearchDone();
  };

  const onAddItem = async (item, key) => {
    if (!item['checked']) {
      item['checked'] = {};
    }

    item.checked[key] = true;
    let options = translationKeys[`${key}-other`] || translationKeys[key];
    item[`${key}ID`] = getID(item);
    item[`${key}Checked`] = true;
    item[`${key}Column`] = options.column;

    if (item[translationKeys.StateName.lookUpKey]) {
      delete item[translationKeys.StateName.lookUpKey];
    }

    const parents = levels.slice(levels.findIndex(({ ID }) => ID === key) + 1, levels.length + 1);

    let selectedItems = [
      ...(checked.filter((savedItem) => {
        const firstCase = savedItem[options.column] !== getID(item);

        const secondCase = parents.find(({ ID }) => {
          let otherOptions = translationKeys[`${ID}-other`] || translationKeys[ID];
          return savedItem[otherOptions.column] && savedItem[otherOptions.column] !== item[otherOptions.column];
        });

        return firstCase || secondCase;
      }) || []),
      item,
    ];

    setChecked({
      sourceID,
      type,
      data: selectedItems,
    });

    refreshCount(selectedItems);

    if (locationResults.length) {
      resetSearch();
    }
  };

  const resetSearch = () => {
    setLocationResults([]);
    setSearchReset((ps) => (ps === false ? '' : false));
  };

  const onRemoveItem = ({ key, position }) => {
    const psChecked = cloneDeep(checked);
    if (psChecked?.[position]?.['checked']?.[key]) {
      psChecked[position]['checked'][key] = false;

      if (!Object.keys(psChecked[position]['checked']).includes(true)) {
        delete psChecked[position];
      }
    }

    let selectedItems = psChecked.filter((d) => d);

    setChecked({
      sourceID,
      type,
      data: selectedItems,
    });

    refreshCount(selectedItems);
  };

  const onClear = () => {
    setChecked({ sourceID, type, data: [] });
    setSelectedCount(0);
  };

  const { checkedObject, selected } = useLocationSelectedFilter({ checked });

  const sizes = {
    [ScreenSize.Mobile]: {
      height: 40,
      width: 90,
    },
    [ScreenSize.Tablet]: {
      height: 30,
      width: 90,
    },
    [ScreenSize.Desktop]: {
      height: 35,
      width: 75,
    },
  };

  const hasSearchResult = locationResults?.length;

  return (
    <Spin spinning={initLoading}>
      <Row>
        <Col span={24} className="mb-2">
          <LocationSearch
            placeholder={t('SearchLocation')}
            onSelect={locationSearch}
            sourceID={sourceID}
            isSearchValue={searchReset}
            searchFilters={[...(levels || [])].reverse?.() || []}
            classnames={classnames}
            granularity={granularity}
            {...(sizes[context.screenContext] || sizes[ScreenSize.Desktop])}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Col
              span={isMobile ? 24 : 12}
              className={`${isMobile && mode !== LOCATION_MODES.Available ? 'd-none' : ''}`}
            >
              <Card title={null} bodyStyle={{ padding: 0 }}>
                <FilterHeader header={t('Available')} suffix={totalAvailable} />
                <FilterContainer isMobile={isMobile} height={height} isVisualisation={isVisualisation}>
                  <div className={`h-100 ${(!hasSearchResult && 'd-none') || ''}`}>
                    <LocationTree
                      data={locationResults}
                      levels={levels}
                      sourceID={sourceID}
                      onAddItem={onAddItem}
                      checked={checkedObject}
                      type={type}
                      isMobile={isMobile}
                      autoOpen={hasSearchResult > 0}
                      view_name={view_name}
                    />
                  </div>

                  <div className={`h-100 ${(hasSearchResult && 'd-none') || ''}`}>
                    <LocationTree
                      data={data}
                      levels={levels}
                      sourceID={sourceID}
                      onAddItem={onAddItem}
                      checked={checkedObject}
                      type={type}
                      isMobile={isMobile}
                      autoOpen={hasSearchResult > 0}
                      view_name={view_name}
                    />
                  </div>
                </FilterContainer>
              </Card>
            </Col>
            <Col
              span={isMobile ? 24 : 12}
              className={`${isMobile && mode === LOCATION_MODES.Available ? 'd-none' : ''}`}
            >
              <Card title={null} bodyStyle={{ padding: 0 }}>
                <FilterHeader
                  header={t('TextSelected')}
                  suffix={
                    <span>
                      {(loadingCount && <LoadingOutlined />) || ''}
                      {selected?.length > 0 ? totalSelected : `0 ${lowestGranularity}`}
                      <Button onClick={onClear} type="link" size="small">
                        {t('Clear')}
                      </Button>
                    </span>
                  }
                />
                <FilterContainer isMobile={isMobile} height={height} isVisualisation={isVisualisation}>
                  {selected.length ? (
                    selected?.map((item) => {
                      let options = translationKeys[`${item.key}-other`] || translationKeys[item.key];

                      return (
                        <div
                          className=" pt-1 pb-1 pl-3 pr-3 border-bottom position-relative"
                          key={createLocationPath(item)}
                        >
                          <div>
                            <span>{item?.name}</span>
                            <span className="float-right">
                              <CloseOutlined onClick={() => onRemoveItem(item)} />
                            </span>
                          </div>
                          <div className="text-muted text-capitalize font-12">{t(options?.totalKey?.slice(0, -1))}</div>
                        </div>
                      );
                    })
                  ) : (
                    <EmptyContent />
                  )}
                </FilterContainer>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export const LocationTree = (props) => <div className="location-tree-container">{DataTree(props)}</div>;

export const DataTree = ({ data, ...rest }) => {
  const selectedItems = useMemo(
    () =>
      data.filter((item) => {
        const key = Object.keys(translationKeys).find((typeKey) => item[typeKey]);
        return rest.checked?.[key]?.[getID(item)] && rest.checked[key][createLocationPath(item)];
      }),
    [data, rest.checked]
  );

  if (!data?.length) return <EmptyContent dataKey="NoLocationData" />;

  if (selectedItems.length === data?.length)
    return <EmptyContent dataKey={rest.loadMore ? 'AllLocationAddedLoadMore' : 'AllLocationAdded'} />;
  const dataLength = data.length;

  return data?.map((itemData, index) => (
    <DataNode key={`${getID(itemData)}-${index}`} itemData={itemData} {...rest} isSingleParent={dataLength === 1} />
  ));
};

export const getLevel = (levels, key) => levels.find(({ ID }) => ID === key);

export const getOptions = (options, levels) => options.find(({ lookUpID }) => getLevel(levels, lookUpID));

export const DataNode = ({
  itemData,
  levels,
  sourceID,
  state = 0,
  district = 0,
  subdistrict = 0,
  onAddItem,
  checked,
  isMobile,
  autoOpen = false,
  lastNode = false,
  type,
  isSingleParent = false,
  view_name = '',
}) => {
  const { lookUpKey, lookUpID, key, ipLocation, isLastNode, singular } = LocationNodeType(itemData, levels);
  const childData = itemData[lookUpKey] || [];
  const isDataObject = !Array.isArray(childData);

  const [children, setChildren] = useState(isDataObject ? [childData] : childData);
  const [isAction, showAction] = useState(false);
  const [loadError, setLoadError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(itemData.total || 0);
  const [page, setPage] = useState(1);
  const [isOpen, setOpen] = useState(autoOpen && children.length);

  const hasChild =
    lastNode !== true &&
    isLastNode !== true &&
    (children?.length || levels?.find(({ ID }) => ID === lookUpID) !== undefined);

  const selectedChilds = useMemo(() => {
    const selected = children.filter((childData) => {
      let childKey = Object.keys(translationKeys).find((typeKey) => childData[typeKey]);
      return checked?.[childKey]?.[getID(childData)] && checked[childKey][createLocationPath(childData)];
    });

    return selected;

    // eslint-disable-next-line
  }, [checked]);

  useEffect(() => {
    if (checked?.[key]?.[getID(itemData)] && checked[key][createLocationPath(itemData)]) {
      setOpen(false);
      showAction(false);
    }
  }, [checked, itemData, key]);

  if (checked?.[key]?.[getID(itemData)] && checked[key][createLocationPath(itemData)]) return null;

  /**
   * get current id
   */
  state = key === StateName ? getID(itemData) : state;
  district = key === DistrictName ? getID(itemData) : district;
  subdistrict = key === SubDistrictName ? getID(itemData) : subdistrict;
  const childrenLength = children?.length || 0;

  const loadData = async () => {
    if (!childrenLength || (childrenLength && childrenLength + 1 <= +total)) {
      try {
        setLoading(true);
        const { IsError, records, error } = await getGeoLocations({
          sourceID,
          state,
          district,
          subdistrict,
          page,
          type: ipLocation,
          view_name,
        });

        if (!IsError) {
          setPage((ps) => ps + 1);
          setTotal(records.Total);
          setChildren((ps) => [...ps, ...records.result]);
        } else {
          message.error(error);
          setLoadError(error);
        }

        setLoading(false);
        return true;
      } catch (e) {
        setLoading(false);
        Sentry.captureException(`Failed to fetch location sub levels, ` + e);
        return false;
      }
    }
  };

  const openChildNode = async () => {
    if (!itemData[lookUpKey] && !children.length && ipLocation && !isOpen) {
      const status = await loadData();
      setOpen(status);
    } else {
      setOpen((ps) => !ps);
    }
  };

  const locationName =
    itemData.StateName ||
    itemData.DistrictName ||
    itemData.SubDistrictName ||
    itemData.VillageTownName ||
    itemData.BlockName;

  if (selectedChilds.length === +(total || itemData.total)) {
    return isSingleParent ? <EmptyContent dataKey="AllLocationAdded" /> : null;
  }

  const loadMore = childrenLength + 1 <= total && !loadError;

  return (
    <div className={`location-tree-row ${isOpen ? 'open' : 'closed'} `} key={createLocationPath(itemData)}>
      <div
        className={`location-tree-item pt-1 pb-1 pl-3 pr-3 ${hasChild ? 'cursor-pointer' : ''}`}
        onMouseOver={() => showAction(true)}
        onMouseLeave={() => showAction(false)}
        onClick={() => hasChild && !loading && openChildNode()}
      >
        <div>
          {loading ? <LoadingOutlined className="location-tree-icon" /> : ''}
          {hasChild && !loading && <DownOutlined className="location-tree-icon" />}
        </div>

        <div className="location-tree-name">
          {locationName}
          {hasChild && +itemData.numberOfRecords > 0 && (
            <span className="text-muted ml-1 font-12  font-weight-normal">
              ({(+itemData.numberOfRecords).toLocaleString()})
            </span>
          )}
        </div>

        {isAction || isMobile ? (
          <div className="position-absolute" style={{ right: 8 }}>
            <Button
              type="primary"
              className="bg-white border-0 rounded-common px-3 location-add-button"
              ghost
              size="small"
              onClick={(e) => {
                setOpen(false);
                onAddItem(itemData, key);
                e.stopPropagation();
              }}
            >
              {t('Add')} {(singular && <span className="ml-1">{singular}</span>) || ''}
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>

      {(hasChild && (
        <div className={`location-tree-children ${(!isOpen && 'd-none') || 'animate__fadeIn animate__animated'}`}>
          <DataTree
            data={children}
            levels={levels}
            state={state}
            district={district}
            subdistrict={subdistrict}
            sourceID={sourceID}
            onAddItem={onAddItem}
            checked={checked}
            isMobile={isMobile}
            autoOpen={autoOpen}
            lastNode={isLastNode}
            type={type}
            loadMore={loadMore}
            view_name={view_name}
          />

          {loadMore ? (
            <Tooltip
              title={t('LoadMoreLocation', {
                location: t(key === DistrictName ? 'SubDistricts' : 'Villages'),
                locationName,
              })}
              placement="topLeft"
            >
              <Button type="link" block onClick={loadData} size="small" className=" text-left font-12">
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                {t('ShowMore')}
              </Button>
            </Tooltip>
          ) : null}
        </div>
      )) ||
        null}
    </div>
  );
};

export default LocationFilter;
