import React from 'react';
import { t } from 'i18next';
import DimensionIcon from '../CustomIcon/DimensionIcon';
import { PivotData } from './Utilities';
import { exportToCsv } from '../../utils';
import { cleanName } from '../../utils/common';
import { Empty } from '../../components';

const spanSize = function (arr, i, j) {
  let x;
  if (i !== 0) {
    let asc, end;
    let noDraw = true;
    for (x = 0, end = j, asc = end >= 0; asc ? x <= end : x >= end; asc ? x++ : x--) {
      if (arr[i - 1][x] !== arr[i][x]) {
        noDraw = false;
      }
    }
    if (noDraw) {
      return -1;
    }
  }
  let len = 0;
  while (i + len < arr.length) {
    let asc1, end1;
    let stop = false;
    for (x = 0, end1 = j, asc1 = end1 >= 0; asc1 ? x <= end1 : x >= end1; asc1 ? x++ : x--) {
      if (arr[i][x] !== arr[i + len][x]) {
        stop = true;
      }
    }
    if (stop) {
      break;
    }
    len++;
  }
  return len;
};

function redColorScaleGenerator(values) {
  const min = Math.min.apply(Math, values);
  const max = Math.max.apply(Math, values);
  return (x) => {
    // eslint-disable-next-line no-magic-numbers
    const nonRed = 255 - Math.round((255 * (x - min)) / (max - min));
    return { backgroundColor: `rgb(255,${nonRed},${nonRed})` };
  };
}

function makeRenderer(opts = {}) {
  class TableRenderer extends React.PureComponent {
    constructor(props) {
      super(props);
      this.topScroll = React.createRef();
      this.contentScroll = React.createRef();
      this._preventEvent = true;
    }

    onContentScroll = (e) => {
      if (this._preventEvent) {
        this._preventEvent = false;
        return;
      }

      if (e.target.scrollLeft !== this.contentLastScrollLeft) {
        this._preventEvent = true;
        this.topScroll.current.scrollLeft = e.target.scrollLeft;
        this.contentLastScrollLeft = e.target.scrollLeft;
      }
    };

    onTopScroll = (e) => {
      if (this._preventEvent) {
        this._preventEvent = false;
        return;
      }

      this._preventEvent = true;
      this.contentScroll.current.scrollLeft = e.target.scrollLeft;
    };

    render() {
      const icons = this.props.icons || {};
      const pivotData = new PivotData(this.props);
      const colAttrs = pivotData.props.cols;
      const rowAttrs = pivotData.props.rows;
      const rowKeys = pivotData.getRowKeys();
      const colKeys = pivotData.getColKeys();

      let valueCellColors = () => {};
      if (opts.heatmapMode) {
        const colorScaleGenerator = this.props.tableColorScaleGenerator;

        if (opts.heatmapMode === 'full') {
          const allValues = [];
          rowKeys.map((r) => colKeys.map((c) => allValues.push(pivotData.getAggregator(r, c).value())));
          const colorScale = colorScaleGenerator(allValues);
          valueCellColors = (r, c, v) => colorScale(v);
        } else if (opts.heatmapMode === 'row') {
          const rowColorScales = {};
          rowKeys.forEach((r) => {
            const rowValues = colKeys.map((x) => pivotData.getAggregator(r, x).value());
            rowColorScales[r] = colorScaleGenerator(rowValues);
          });
          valueCellColors = (r, c, v) => rowColorScales[r](v);
        } else if (opts.heatmapMode === 'col') {
          const colColorScales = {};
          colKeys.forEach((c) => {
            const colValues = rowKeys.map((x) => pivotData.getAggregator(x, c).value());
            colColorScales[c] = colorScaleGenerator(colValues);
          });
          valueCellColors = (r, c, v) => colColorScales[c](v);
        }
      }

      const getClickHandler =
        this.props.tableOptions && this.props.tableOptions.clickCallback
          ? (value, rowValues, colValues) => {
              const filters = {};
              for (const i of Object.keys(colAttrs || {})) {
                const attr = colAttrs[i];
                if (colValues[i] !== null) {
                  filters[attr] = colValues[i];
                }
              }
              for (const i of Object.keys(rowAttrs || {})) {
                const attr = rowAttrs[i];
                if (rowValues[i] !== null) {
                  filters[attr] = rowValues[i];
                }
              }
              return (e) => this.props.tableOptions.clickCallback(e, value, filters, pivotData);
            }
          : null;

      let rowsLength = rowAttrs?.length || 0;
      let colGroupLength = rowsLength + (colKeys?.length || 0) + 1;
      let colGrop = [];

      for (let ci = 1; ci <= colGroupLength; ci++) {
        colGrop.push(<col style={{ width: rowsLength + 1 === ci ? 0 : 260 }} key={`${ci}`}></col>);
      }

      return (
        <div>
          <TSVExportRenderer {...this.props} />
          <div
            ref={this.topScroll}
            style={{ overflowX: 'scroll', paddingRight: 17 }}
            onScroll={this.onTopScroll}
            className="pivot-table-header"
          >
            <table className="pvtTable text-capitalize">
              <colgroup>{colGrop}</colgroup>
              <thead>
                {colAttrs.map(function (c, j) {
                  let isExtra = j === 0 && rowAttrs.length !== 0;
                  return (
                    <tr key={`colAttr${j}`}>
                      {isExtra && <th colSpan={rowAttrs.length + 1} rowSpan={colAttrs.length} />}
                      {/* <th className="pvtAxisLabel">{c}</th> */}
                      {colKeys.map(function (colKey, i) {
                        let x = spanSize(colKeys, i, j);
                        if (x === -1) {
                          return null;
                        }

                        let y = j === colAttrs.length - 1 && rowAttrs.length !== 0 ? 2 : 1;
                        let icon = icons?.[c.toLowerCase()];

                        return (
                          <th className="pvtColLabel" key={`colKey${i}`} colSpan={x} rowSpan={y}>
                            {colKey[j] !== 'null' ? (
                              <>
                                <div className="position-relative">
                                  <div className="position-absolute">
                                    <DimensionIcon type={icon} className="mr-1" verticalAlign={4} />
                                  </div>
                                  <div className="pl-4"> {colKey[j]}</div>
                                </div>
                              </>
                            ) : (
                              '-'
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}

                {rowAttrs.length !== 0 && (
                  <tr>
                    {rowAttrs.map(function (r, i) {
                      let icon = icons?.[r.toLowerCase()];
                      return (
                        <th className="pvtAxisLabel" key={`rowAttr${i}`} colSpan={1}>
                          <div className="position-relative">
                            <div className="position-absolute">
                              <DimensionIcon type={icon} className="mr-1" verticalAlign={4} />
                            </div>
                            <div className="pl-4">{r}</div>
                          </div>
                        </th>
                      );
                    })}
                    {/* <th className="pvtTotalLabel"></th> */}
                  </tr>
                )}
              </thead>
            </table>
          </div>
          <div
            style={{ maxHeight: '70vh', overflowY: 'scroll' }}
            ref={this.contentScroll}
            onScroll={this.onContentScroll}
          >
            <table className="pvtTable text-capitalize">
              <colgroup>{colGrop}</colgroup>

              <tbody>
                {(!rowKeys.length && <Empty className="p-5" description={t('NoData')} />) || null}
                {rowKeys.map(function (rowKey, i) {
                  return (
                    <tr key={`rowKeyRow${i}`}>
                      {rowKey.map(function (txt, j) {
                        const x = spanSize(rowKeys, i, j);
                        if (x === -1) {
                          return null;
                        }

                        return (
                          <th
                            key={`rowKeyLabel${i}-${j}`}
                            className="pvtRowLabel"
                            rowSpan={x}
                            colSpan={j === rowAttrs.length - 1 && colAttrs.length !== 0 ? 2 : 1}
                          >
                            {txt}
                          </th>
                        );
                      })}
                      {colKeys.map(function (colKey, j) {
                        const aggregator = pivotData.getAggregator(rowKey, colKey);
                        return (
                          <td
                            className="pvtVal"
                            key={`pvtVal${i}-${j}`}
                            onClick={getClickHandler && getClickHandler(aggregator.value(), rowKey, colKey)}
                            style={valueCellColors(rowKey, colKey, aggregator.value())}
                          >
                            {aggregator.format(aggregator.value())}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  TableRenderer.defaultProps = PivotData.defaultProps;
  TableRenderer.propTypes = PivotData.propTypes;
  TableRenderer.defaultProps.tableColorScaleGenerator = redColorScaleGenerator;
  TableRenderer.defaultProps.tableOptions = {};
  return TableRenderer;
}

class TSVExportRenderer extends React.PureComponent {
  render() {
    const pivotData = new PivotData(this.props);
    const rowKeys = pivotData.getRowKeys();
    const colKeys = pivotData.getColKeys();
    if (rowKeys.length === 0) {
      rowKeys.push([]);
    }
    if (colKeys.length === 0) {
      colKeys.push([]);
    }

    const headerRow = pivotData.props.rows.map((r) => r);
    if (colKeys.length === 1 && colKeys[0].length === 0) {
      headerRow.push(this.props.aggregatorName);
    } else {
      colKeys.map((c) => headerRow.push(c.join('-')));
    }

    const result = rowKeys.map((r) => {
      const row = r.map((x) => x);
      colKeys.forEach((c) => {
        const v = pivotData.getAggregator(r, c).value();
        row.push(v ? v : '');
      });
      return row;
    });

    result.unshift(headerRow);

    if (!result.length) return null;

    return (
      <div
        id="download-graph"
        className="d-none"
        onClick={() => exportToCsv(cleanName(this?.props?.title || [...headerRow].reverse().join('-'), '-'), result)}
      ></div>
    );
  }
}

TSVExportRenderer.defaultProps = PivotData.defaultProps;
TSVExportRenderer.propTypes = PivotData.propTypes;

const allRenders = {
  Table: makeRenderer(),
  'Table Heatmap': makeRenderer({ heatmapMode: 'full' }),
  'Table Col Heatmap': makeRenderer({ heatmapMode: 'col' }),
  'Table Row Heatmap': makeRenderer({ heatmapMode: 'row' }),
  'Exportable TSV': TSVExportRenderer,
};

export default allRenders;
