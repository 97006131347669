import { t } from 'i18next';
import { useMemo, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { useAppliedFilterContext, useFilterActions, useFilterContext } from '../../providers/FilterContextProvider';
import { Modal, Button, Badge } from '../../components';
import DynamicDimensionFilter from './DynamicDimensionFilter';
import StaticDimensionFilter from './StaticDimensionFilter';
import DatasetModalTitle from '../DatasetModalTitle';
import { CloseButton } from '../../shared';
import { voidFunction } from '../../utils';
import LocationFilter, { useLocationSelectedFilter } from '../LocationFilter';
import { LOCATION_MODES, TYPE_LOCATION } from '../../constants';

const DatasetDimension = ({
  sourceID,
  sourceName = '',
  title = '',
  type = '',
  placeholder = '',
  applyModalTo = null,
  icon = 'Clock',
  dimensions: dimensionInput = [],
  mergeDimensions: mergeDimensionInput = [],
  category = 'location',
  colorCode = 0,
  mode = 'dynamic',
  width = '80%',
  height = '30vh',
  view_name = '',
  forcedShow = false,
  skipable = false,
  onSkip = voidFunction,
  multipleDataset = false,
  raw = false,
  classnames,
  granularity = false,
  upperLimit = false,
}) => {
  const [skipped, setSkipped] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  forcedShow = forcedShow && !skipped;

  let dimensions = useMemo(() => cloneDeep(dimensionInput), [dimensionInput]);
  let mergeDimensions = useMemo(() => cloneDeep(mergeDimensionInput), [mergeDimensionInput]);

  let { clearFilters, showFilter, setChecked, getAny } = useFilterContext();
  let { resetToPrevious } = useFilterActions({ sourceID });
  let { setAppliedFilter } = useAppliedFilterContext();

  const targetParams = useMemo(
    () => ({
      sourceID,
      type: type || category,
    }),
    [sourceID, type, category]
  );

  const onApply = () => {
    setAppliedFilter({
      appliedFilter: getAny({
        ...targetParams,
        key: 'checked',
      }),
      quickPaths: getAny({
        ...targetParams,
        key: 'quickPaths',
      }),
      ...targetParams,
    });
    clearFilters();
    setResetSearch((ps) => !ps);
  };

  const {
    selected: selectedLocations,
    loacationMode,
    setLoacationMode,
    isMobile,
  } = useLocationSelectedFilter({
    checked: getAny({ sourceID, type, key: 'checked' }),
  });

  const isVisible =
    showFilter({
      sourceID: sourceID,
      type: type || category,
    }) || forcedShow;

  const filterByMode = () => {
    const properties = {
      sourceID,
      onSelect: (data, searchID, cleanNames = false, root = '') => {
        setChecked({
          ...targetParams,
          searchID,
          data,
          cleanNames,
          root,
        });
      },
      height,
      category: category,
      type: type || category,
      dimensions,
      mergeDimensions,
      placeholder,
      quickPaths: getAny({ ...targetParams, key: 'quickPaths' }),
      checked: getAny({ ...targetParams, key: 'checked' }),
      multipleDataset,
      resetSearch,
      isMobile,
      isVisible,
      granularity,
      upperLimit,
    };

    const types = {
      location: () => <LocationFilter {...properties} view_name={view_name} classnames={classnames} />,
      dynamic: () => {
        return <DynamicDimensionFilter {...properties} view_name={view_name} />;
      },
      static: () => {
        return <StaticDimensionFilter {...properties} view_name={view_name} />;
      },
    };

    return types[mode] && types[mode]();
  };

  if (!sourceID || (!dimensions.length && !mergeDimensions.length)) return null;

  let footer = [
    <Button type="primary" onClick={() => onApply()} key={`${type || category}-apply`}>
      {t('Apply')}
    </Button>,
  ];

  if (mode === TYPE_LOCATION && isMobile) {
    footer.push(
      <Button
        type="primary"
        ghost
        onClick={() =>
          setLoacationMode((ps) =>
            LOCATION_MODES.Available === ps ? LOCATION_MODES.Selected : LOCATION_MODES.Available
          )
        }
      >
        {loacationMode === LOCATION_MODES.Available ? (
          <span>
            {t('ViewSelected')}
            {(selectedLocations?.length && <Badge count={selectedLocations?.length} className="ml-1" />) || ''}
          </span>
        ) : (
          t('ViewAvailable')
        )}
      </Button>
    );
    footer = footer.reverse();
  }

  if (forcedShow && skipable) {
    footer.push(
      <Button
        type="dashed"
        onClick={() => {
          onSkip(sourceID, type || category);
          setSkipped(true);
        }}
        key={`${type || category}-skipped`}
      >
        {t('Skip')}
      </Button>
    );
  }

  const onCancel = () => {
    setResetSearch((ps) => !ps);
    resetToPrevious({ id: sourceID, types: [type || category] });
  };

  if (raw) {
    return {
      component: filterByMode,
      actions: footer,
      title: <DatasetModalTitle title={title} sourceName={sourceName} colorCode={colorCode} icon={icon} />,
      onCancel,
    };
  }

  return (
    <Modal
      keyboard={false}
      maskClosable={false}
      onCancel={onCancel}
      width={width}
      title={<DatasetModalTitle title={title} sourceName={sourceName} colorCode={colorCode} icon={icon} />}
      style={{ top: 40 }}
      closable={!forcedShow}
      visible={isVisible}
      getContainer={applyModalTo}
      footer={footer}
      closeIcon={<CloseButton />}
      className={`${isMobile ? 'mobile full-screen-modal' : ''}`}
    >
      {filterByMode()}
    </Modal>
  );
};

export default DatasetDimension;
