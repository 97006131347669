import React from 'react';
import { CloseCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import s from './index.module.less';

const CloseButton = (props) => {
  return (
    <>
      {(props?.type === 'black' || props?.type === undefined) && (
        <CloseCircleFilled
          className={`${s.closeButtonBlack} ${props?.className}`}
          onClick={props?.onClick}
          title={props?.title}
        />
      )}
      {props?.type === 'white' && (
        <CloseCircleFilled
          className={`${s.closeButtonWhite} ${props?.className}`}
          onClick={props?.onClick}
          title={props?.title}
        />
      )}
      {props?.type === 'blue' && (
        <CloseCircleOutlined
          className={`${s.closeButtonBlue} ${props?.className}`}
          onClick={props?.onClick}
          title={props?.title}
        />
      )}
      {props?.type === 'red' && (
        <CloseCircleOutlined
          className={`${s.closeButtonRed} ${props?.className}`}
          onClick={props?.onClick}
          title={props?.title}
        />
      )}
    </>
  );
};

export default CloseButton;
