import axios from '../axios';

export const getSearchResults = (params = {}) => {
  const url = `/v1/search`;
  return axios.get(url, { params });
};

export const getInsertSuggestions = (params = {}) => {
  const url = `/v1/search/suggestion/insert`;
  return axios.post(url, params);
};

export const getSearchFilters = (params = {}) => {
  const url = `/v1/search/filters`;
  return axios.get(url, { params });
};

export const getSearchDefaultAutocomplete = (params = {}) => {
  const url = `/v1/search/suggestion/default`;
  return axios.get(url, { params });
};

export const getSearchAutocomplete = (params = {}) => {
  const url = `/v1/search/suggestion/typeahead`;
  return axios.get(url, { params });
};

export const getHelpSearchResults = (params = {}) => {
  const url = `/v1/faq`;
  return axios.get(url, { params });
};
