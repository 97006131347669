import { t } from 'i18next';
import { getDomainParameters } from '../../constants';
const { domain } = getDomainParameters();

export const steps = () => {
  return [
    {
      element: '.joyride-dataset-step1',
      intro: <div className="product-content">{t('Joyride-Dataset-Step1')}</div>,
      position: 'auto',
      title: t('ProfileTab'),
    },
    {
      element: '.joyride-dataset-step2',
      intro: <div className="product-content">{t('Joyride-Dataset-Step2')}</div>,
      position: 'auto',
      title: t('DataTab'),
      disableBeacon: true,
      hideCloseButton: true,
    },
    {
      element: '.joyride-dataset-step3',
      intro: <div className="product-content">{t('Joyride-Dataset-Step3')}</div>,
      position: 'auto',
      title: t('VisualizationTab'),
    },
    {
      element: '.joyride-dataset-step4',
      intro: <div className="product-content">{t('Joyride-Dataset-Step4')}</div>,
      position: 'auto',
      title: t('Share'),
    },
    {
      element: '.joyride-dataset-step5',
      intro: <div className="product-content">{t('Joyride-Dataset-Step5', { domain: t(domain) })}</div>,
      position: 'bottom',
      title: t('Subscribe'),
    },
    {
      element: '.joyride-dataset-step6',
      intro: <div className="product-content">{t('Joyride-Dataset-Step6')}</div>,
      position: 'bottom',
      title: t('Bookmark'),
    },
    {
      element: '.joyride-dataset-step7',
      intro: <div className="product-content">{t('Joyride-Dataset-Step7', { domain: t(domain) })}</div>,
      position: 'bottom',
      title: t('Merge_Datasets'),
    },
    {
      element: '.joyride-dataset-step8',
      intro: <div className="product-content">{t('Joyride-Dataset-Step8', { domain: t(domain) })}</div>,
      position: 'left',
      title: t('ExportData'),
    },
    {
      element: '.joyride-dataset-step9',
      intro: <div className="product-content">{t('Joyride-Dataset-Step9')}</div>,
      position: 'auto',
      title: t('Overview'),
    },
    {
      element: '.joyride-dataset-step10',
      intro: <div className="product-content">{t('Joyride-Dataset-Step10')}</div>,
      position: 'auto',
      title: t('SourceLinks'),
    },
    {
      element: '.joyride-dataset-step11',
      intro: <div className="product-content">{t('Joyride-Dataset-Step11', { domain: t(domain) })}</div>,
      position: 'left',
      title: t('ProcessingReport'),
    },
    {
      element: '.joyride-dataset-step12',
      intro: <div className="product-content">{t('Joyride-Dataset-Step12')}</div>,
      position: 'bottom',
      title: t('Data_Preview'),
    },
    {
      element: '.joyride-dataset-step13',
      intro: <div className="product-content">{t('Joyride-Dataset-Step13')}</div>,
      position: 'bottom',
      title: t('Meta'),
    },
    {
      element: '.joyride-dataset-step14',
      intro: <div className="product-content">{t('Joyride-Dataset-Step14')}</div>,
      position: 'bottom',
      title: t('MetadataDownload'),
    },
    {
      element: '.joyride-dataset-step15',
      intro: <div className="product-content">{t('Joyride-Dataset-Step15')}</div>,
      position: 'top',
      title: t('Get_API'),
    },
  ];
};

export const stepsDatatab = () => {
  return [
    {
      element: '.joyride-datasetdata-stepOne',
      intro: <div className="product-content">{t('Joyride-Datasetdata-Step1')}</div>,
      position: 'left',
      title: t('ExportData'),
    },
    {
      element: '.joyride-datasetdata-stepTwo',
      intro: <div className="product-content">{t('Joyride-Datasetdata-Step2')}</div>,
      position: 'auto',
      title: t('FilterLabel'),
    },
    {
      element: '.joyride-datasetdata-stepThree',
      intro: <div className="product-content">{t('Joyride-Datasetdata-Step3')}</div>,
      position: 'auto',
      title: t('ShowHideColumns'),
    },
  ];
};

export const stepsVisualization = () => {
  return [
    {
      element: '.joyride-datasetvisualization-step1',
      intro: <div className="product-content">{t('Joyride-Datasetvisualization-Step1')}</div>,
      position: 'bottom',
      title: t('FilterLabel'),
    },
    {
      element: '.joyride-datasetvisualization-step2',
      intro: <div className="product-content">{t('Joyride-Datasetvisualization-Step2')}</div>,
      position: 'auto',
      title: t('ChartOptions'),
    },
    {
      element: '.joyride-logo-step',
      intro: <div className="product-content">{t('ClickGoBackHome')}</div>,
      position: 'right',
      title: t('GoBackHome'),
    },
  ];
};

export const stepsVisualizationCharts = () => {
  return [
    {
      element: '.joyride-datasetvisualizationchart-step1',
      intro: <div className="product-content">{t('Joyride-DatasetvisualizationChart-Step1')}</div>,
      position: 'left',
      title: t('DownloadChart'),
    },
    {
      element: '.joyride-datasetvisualizationchart-step2',
      intro: <div className="product-content">{t('Joyride-DatasetvisualizationChart-Step2')}</div>,
      position: 'auto',
      title: t('ChartOptions'),
    },
  ];
};

export const stepsMerge = () => {
  return [
    {
      element: '.joyride-mergedataset-step1',
      intro: <div className="product-content">{t('Joyride-mergedataset-step1')}</div>,
      position: 'auto',
      title: t('AddDataset'),
    },
    /* wip */
    // {
    //   element: '.joyride-mergedataset-step2',
    //   intro: <div className="product-content">{t('Joyride-mergedataset-step2')}</div>,
    //   position: 'auto',
    //   styles: {
    //     options: {
    //       width: 400,
    //     },
    //   },
    //   title: `${t('Preview')}`,
    //   disableBeacon: true,
    //   hideCloseButton: true,
    // },
    {
      element: '.joyride-mergedataset-step3',
      intro: <div className="product-content">{t('Joyride-mergedataset-step3')}</div>,
      position: 'auto',
      title: t('QuickMerge'),
    },
    {
      element: '.joyride-mergedataset-step4',
      intro: <div className="product-content">{t('Joyride-mergedataset-step4')}</div>,
      position: 'auto',
      title: t('CustomizeFilter'),
    },
    {
      element: '.joyride-mergedataset-step5',
      intro: <div className="product-content">{t('Joyride-mergedataset-step5')}</div>,
      position: 'auto',
      title: t('BacktoDataset'),
    },
  ];
};
