import React from 'react';
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

import { getAutoSearchResultDatasets, getDatasetFilters, getDatasetBySearch } from '../../services/dataset';
import { voidFunction } from '../../utils';
import { APIConfinguration } from '../../services';

const api = {
  default: getAutoSearchResultDatasets,
  bySearch: getDatasetBySearch,
};

const ProductWiseSearchKeys = {
  sectors: 'ip_sector',
  ministries: 'ip_ministry',
  ministriesType: 'ministry',
  sectorsType: 'sectors',
};

const ProductWiseFilterKeys = {
  sectors: 'ip_sectorid',
  ministries: 'ip_ministryid',
  collection: 'ip_collection',
  granularity: 'ip_Granularity',
  frequency: 'ip_Frequency',
  timerange: ['ip_minyear', 'ip_maxyear'],
  ministry: 'ip_ministryid',
  sector: 'ip_sectorid',
};

const fetchDataList = async (params = {}) => {
  const {
    apiParams,
    productSearched,
    productName,
    search,
    loadFromAPI = 'default',
    query = {},
    page = 1,
    limit = 10,
    isLoading = voidFunction,
    onDataLoaded = voidFunction,
    setData = voidFunction,
  } = params;

  const searchFilters = {
    ip_offset: String((page - 1) * limit),
    ip_limit: `'${String(limit)}'`,
    ...(apiParams || {}),
  };

  isLoading(true);
  const ProductSearch = [productSearched];

  if (ProductWiseSearchKeys[productName]) {
    searchFilters[ProductWiseSearchKeys[productName]] = ProductSearch;
  }

  if (search) {
    searchFilters['ip_query'] = `'${search}'`;
  }

  const otherFilters = Object.keys(query);
  if (otherFilters.length) {
    otherFilters.forEach((searchKey) => {
      const queryKey = ProductWiseFilterKeys[searchKey];
      if (queryKey) {
        const targetData = query[searchKey];
        const values = Array.isArray(targetData) ? targetData : Object.keys(query[searchKey]);

        if (values.length) {
          if (searchFilters[searchKey]) {
            values.push(searchFilters[searchKey]);
          }

          if (Array.isArray(queryKey)) {
            queryKey.forEach((aKey, index) => {
              if (values[index]) searchFilters[aKey] = String(values[index]);
            });
          } else {
            searchFilters[queryKey] = values;
          }
        }
      }
    });
  }

  const caller = api[loadFromAPI];

  caller(searchFilters)
    .then((data) => {
      setData(data);
      isLoading(false);
      onDataLoaded(data);
    })
    .catch(() => {
      const data = APIConfinguration.apiErrorResponse;
      setData(data);
      isLoading(false);
      onDataLoaded(data);
    });
};

const waitAndFetchDataList = debounce(fetchDataList, 500);

const DatasetResults = (
  WrapperComponent,
  {
    productName,
    activeService = {},
    page = 1,
    limit = 10,
    appliedFilters = {},
    onlyFilters = false,
    onLoading = voidFunction,
    onDataLoaded = voidFunction,
    onProductSearched = voidFunction,
    setPage = voidFunction,
    mode = '',
    loadFromAPI = 'default',
    search = '',
    apiParams = false,
    onPageChange = voidFunction,
    handleFilterChange = voidFunction,
    excludeFilters = [],
  }
) => {
  const [dataList, setDataList] = React.useState([]);
  const [filter, setFilters] = React.useState({});
  const [isFetchingList, setIsFetching] = React.useState(false);
  const [isFetchingFilters, setFetchingFilters] = React.useState(false);
  const [productSearched, setProductSearch] = React.useState(false);
  const [productSearchedDisplayName, setProductSearchDisplayName] = React.useState(false);

  const [query, setQuery] = React.useState(appliedFilters);

  const activeServiceID = activeService?.id;
  const activeServiceName = activeService?.name;

  React.useEffect(() => {
    setQuery((previousQueries) => {
      return isEqual(previousQueries, appliedFilters) ? previousQueries : appliedFilters;
    });
  }, [appliedFilters]);

  React.useEffect(() => {
    const fetchAllFilters = async () => {
      setFetchingFilters(true);
      setIsFetching(true);

      let params = {};

      if (ProductWiseFilterKeys[productName] && activeServiceID) {
        params[ProductWiseFilterKeys[productName]] = String(activeServiceID);
      }

      try {
        const { data } = await getDatasetFilters(params);
        const { IsError, ...allFilters } = data;
        const parsedFilters = {};

        if (IsError === false) {
          Object.keys(allFilters).forEach((filterKey) => {
            if (allFilters[filterKey]?.records) {
              allFilters[filterKey].records.forEach((filterItem) => {
                const type = filterItem.type.toLowerCase();
                if (!parsedFilters[type]) {
                  parsedFilters[type] = [];
                }

                if (filterItem.name)
                  parsedFilters[type].push({ ...filterItem, key: filterItem.name, display: filterItem.name });
              });
            }
          });

          setFilters(parsedFilters);
        } else {
          setIsFetching(false);
        }

        if (mode !== 'separated') {
          let ps = get(parsedFilters, `${ProductWiseSearchKeys[productName + 'Type']}[0].id`, activeServiceID);
          setProductSearch(ps);
          onProductSearched(ps);
        }

        setFetchingFilters(false);
      } catch (e) {
        setFetchingFilters(false);
        Sentry.captureException(`Failed to fetch dataset filters , ` + e);
      }
    };

    if (activeServiceID || (onlyFilters && productName) || mode === 'separated') fetchAllFilters();
  }, [productName, activeServiceID, activeServiceName, onlyFilters, onProductSearched, mode]);

  React.useEffect(() => {
    if ((productSearched || mode === 'separated') && !onlyFilters) {
      const fetchDataset = search ? waitAndFetchDataList : fetchDataList;
      fetchDataset({
        limit,
        page,
        productName,
        productSearched,
        query,
        onlyFilters,
        mode,
        loadFromAPI,
        search,
        apiParams,
        isLoading: setIsFetching,
        setData: setDataList,
        onDataLoaded: (data) => {
          if (mode !== 'separated') {
            setProductSearchDisplayName(productSearched);
          }
          onDataLoaded(data);
        },
      });
    }
  }, [
    limit,
    page,
    productName,
    productSearched,
    query,
    onlyFilters,
    onDataLoaded,
    mode,
    loadFromAPI,
    search,
    apiParams,
  ]);

  React.useEffect(() => {
    onLoading(isFetchingFilters || isFetchingList);
  }, [isFetchingFilters, isFetchingList, onLoading]);

  return () => (
    <WrapperComponent
      isFetchingDataList={isFetchingList}
      dataList={dataList}
      activeService={activeService}
      isFetchingFilters={isFetchingFilters}
      dataFilters={filter}
      productSearched={productSearchedDisplayName}
      appliedFilters={query}
      page={page}
      limit={limit}
      onPageChange={onPageChange}
      setPage={setPage}
      handleFilterChange={handleFilterChange}
      excludeFilters={excludeFilters}
    />
  );
};

export default DatasetResults;
