import { useEffect, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { t } from 'i18next';
import { useFilterByCategory } from './DimensionProviders';
import { getDimensionValues } from './DimensionHelpers';
import { voidFunction } from '../../utils';
import { TableInput } from '../CustomInputs';
import { useAppliedFilterContext, useFilterContext } from '../../providers/FilterContextProvider';
import { cleanName, parseStringToNumber } from '../../utils/common';
import { DATAINDEX_DIMENSION_VALUE } from '../../constants';

const DIMENSION_VALUE = DATAINDEX_DIMENSION_VALUE;
const DIMENSION_NAME = 'DisplayName';

const mergeData = (dataList, filteredDimensions, root) => {
  if (dataList.length) {
    const list = uniqBy(
      dataList.map(({ ID, ...rest }) => ({ ID, dataID: ID, ...rest })),
      'dataID'
    );

    const allRecords = [...filteredDimensions.map((item) => ({ ...item, root }))];

    list.forEach((item, index) => {
      item = { ...item };
      if (!allRecords[index]) {
        allRecords[index] = {};
      }

      allRecords[index][DIMENSION_VALUE] = item.Name;
      allRecords[index][DIMENSION_VALUE + 'ID'] = item.ID;
      allRecords[index]['root'] = root;
    });

    return allRecords;
  }

  return false;
};

const loadDimensionValue = ({ setLoading, sourceID, setData, filteredDimensions, selected, view_name }) => {
  let query = {
    ip_columns: selected.ID,
    view_name,
    ip_limit: 500,
  };

  if (selected.search) {
    query['ip_filter'] = [{ value: parseStringToNumber(selected.search) }];
  }

  getDimensionValues({
    setLoading,
    sourceID,
    setData: (dataList) => {
      const mergedData = mergeData(dataList, filteredDimensions, selected.ID);

      if (mergedData) {
        setData(mergedData, dataList);
      }
    },
    query,
  });
};

const addOtherCheckedItems = (resetData = [], checked = []) => {
  if (checked.length) {
    let addedList = {};
    resetData.forEach((item, index) => {
      let key = `${item.root} ${item['DimensionValueID']}`;
      if (typeof addedList[key] !== 'undefined') {
        resetData[addedList[key]]['deleted'] = true;
      } else if (item['DimensionValueID']) {
        addedList[key] = index;
      }
    });

    resetData = resetData.filter(({ deleted }) => !deleted);

    checked.forEach((item) => {
      if (typeof addedList[`${item.root} ${item['DimensionValueID']}`] === 'undefined') {
        resetData.push(item);
      }
    });
  }

  return resetData;
};

const mapQuickpaths = (data, quickPaths, checked = []) => {
  const resetData = data.map((item) => {
    let searchKey = cleanName(item[DIMENSION_VALUE]);
    let isChecked = get(quickPaths, `${DIMENSION_VALUE}.selected.${item.root}["${searchKey}"]`, false);
    item[DIMENSION_VALUE + 'Checked'] = isChecked;

    if (!item.checked) {
      item.checked = {};
    }

    item.checked[DIMENSION_VALUE] = isChecked;
    return item;
  });

  return addOtherCheckedItems(resetData, checked);
};

const StaticDimensionFilter = ({
  sourceID,
  height = 200,
  onSelect = voidFunction,
  category = 'location',
  type = '',
  dimensions = [],
  mergeDimensions = [],
  placeholder = '',
  quickPaths = {},
  view_name = '',
  checked = [],
  upperLimit = false,
}) => {
  const refrenceType = type || category;
  const [psQuickPaths, setPsQuickPaths] = useState({});

  const { setAny, getAny } = useFilterContext();
  const [loading, setLoading] = useState(false);
  const [resetChecked, setResetChecked] = useState(true);
  const { getSelectedFilters } = useAppliedFilterContext();
  const [filterParamas] = useState({ sourceID, type: refrenceType });
  const active = getAny({ ...filterParamas, key: 'active' });
  const { filteredDimensions, intialValue } = useFilterByCategory(
    dimensions,
    category,
    mergeDimensions,
    getSelectedFilters({ ...filterParamas }),
    active,
    upperLimit
  );

  const [alreadyLoaded, setAlreadyLoaded] = useState({});

  useEffect(() => {
    const data = getAny({ ...filterParamas, key: `data` });

    if (!isEqual(quickPaths, psQuickPaths)) {
      setPsQuickPaths(quickPaths);
      setResetChecked((ps) => !ps);
      const resetData = mapQuickpaths(data, quickPaths, checked);

      setAny({
        ...filterParamas,
        data: {
          data: resetData,
        },
      });
    }

    // eslint-disable-next-line
  }, [quickPaths, resetChecked]);

  useEffect(() => {
    setAny({ ...filterParamas, data: { data: filteredDimensions } });
  }, [filteredDimensions, filterParamas]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (intialValue && !loading) {
      setAny({ ...filterParamas, data: { active: intialValue } });
      setAlreadyLoaded({ [intialValue.ID]: true });

      loadDimensionValue({
        sourceID,
        selected: intialValue,
        setLoading,
        setData: (data) => {
          // data = uniqBy(data, 'ID');
          setAny({
            ...filterParamas,
            data: {
              data: mapQuickpaths(data, quickPaths, checked),
              active: intialValue,
            },
          });

          setResetChecked(true);
        },
        filteredDimensions,
        view_name,
      });
    }

    // eslint-disable-next-line
  }, [intialValue, filteredDimensions, sourceID, filterParamas, view_name]);

  const handleSelect = (selected, searchID) => {
    if (selected.ID) {
      if (selected.ID === active.ID && !selected.search && !selected.searchReset) {
        return;
      }

      let currentDataState = [...getAny({ ...filterParamas, key: `data` })];

      if (selected.search || selected.searchReset) {
        currentDataState = currentDataState.filter((item) => item.root !== selected.ID);
      }

      const isAlreadedLoaded = alreadyLoaded[selected.ID] && !selected.search && !selected.searchReset;

      if (!isAlreadedLoaded) {
        setAlreadyLoaded((ps) => ({ ...ps, [selected.ID]: true }));
        loadDimensionValue({
          sourceID,
          selected,
          searchID,
          setLoading,
          setData: (apiData) => {
            let newState = [...currentDataState, ...apiData];

            setAny({
              ...filterParamas,
              data: {
                data: mapQuickpaths(newState, quickPaths, checked),
                active: selected,
              },
            });

            setResetChecked(true);
          },
          filteredDimensions,
          view_name,
        });
      } else {
        setAny({ ...filterParamas, data: { active: selected } });
        setResetChecked(true);
      }
    } else {
      onSelect(selected, searchID, true, active.ID);
      setResetChecked(true);
    }
  };

  const dataSource = getAny({ ...filterParamas, key: `data` });

  const columns = [
    {
      title: t('Dimension'),
      dataIndex: DIMENSION_NAME,
      key: DIMENSION_NAME,
      width: 250,
      showNumberOfRecord: true,
      enableRadioSelect: true,
    },
    {
      title: t('Values'),
      dataIndex: DIMENSION_VALUE,
      key: DIMENSION_VALUE,
      width: 250,
      showNumberOfRecord: true,
      enableCheckbox: true,
      showPlus: true,
    },
  ];

  return (
    <TableInput
      splitColumns={true}
      active={active}
      columns={columns}
      dataSource={dataSource}
      root={active.ID}
      height={height}
      loading={loading}
      onSelect={handleSelect}
      showNumberOfRecord={true}
      placeholder={placeholder}
      disableTranslation={true}
    />
  );
};

export default StaticDimensionFilter;
