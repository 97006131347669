import { useState, useEffect, useRef } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import max from 'lodash/max';
import { Table } from '../../components';
import { emptyContent } from '../../shared/Empty/EmptyContent';
import { ROW_HEIGHT } from '../../constants';

const VirtualTable = (props) => {
  const { columns, scroll } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const widthColumnCount = columns.filter(({ width }) => !width).length;
  const mergedColumns = columns.map((column) => {
    if (column.width) {
      return column;
    }

    return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
  });
  const gridRef = useRef();

  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });
    return obj;
  });

  const resetVirtualGrid = () => {
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate: true,
      });
    }
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * 50;
    const rowHeight = (index) => {
      const obj = rawData[index];
      const strLen = max(Object.values(obj).map((a) => a?.length));
      let rowLen = ROW_HEIGHT.small;
      if (strLen > ROW_HEIGHT.sm) rowLen = ROW_HEIGHT.medium;
      if (strLen > ROW_HEIGHT.md) rowLen = ROW_HEIGHT.large;

      return rowLen;
    };

    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={(index) => {
          const { width } = mergedColumns[index];
          return totalHeight > scroll.y && index === mergedColumns.length - 1 ? width - scrollbarSize - 1 : width;
        }}
        height={scroll.y}
        rowCount={rawData.length}
        rowHeight={rowHeight}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style, ...rest }) => {
          const value = rawData[rowIndex][mergedColumns[columnIndex].dataIndex];
          const renderFunction = mergedColumns[columnIndex].render;

          return (
            <div
              className={`virtual-table-cell ${rowIndex % 2 ? 'virtual-table-cell-odd' : 'virtual-table-cell-even'} ${
                columnIndex === mergedColumns.length - 1 ? 'virtual-table-cell-last' : ''
              } ${mergedColumns[columnIndex].rowAlign === 'right' ? 'text-right' : 'text-left'}`}
              style={{ ...style, borderRight: '1px solid #f0f0f0', borderBottom: '1px solid #f0f0f0' }}
            >
              {(renderFunction && renderFunction(value, rawData[rowIndex])) || value}
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      {mergedColumns.length < 12 ? (
        <Table bordered {...props} locale={emptyContent} />
      ) : (
        <Table
          bordered
          {...props}
          className="virtual-table"
          columns={mergedColumns}
          pagination={false}
          components={
            (props?.dataSource?.length && {
              body: !props.loading && renderVirtualList,
            }) ||
            {}
          }
          locale={emptyContent}
        />
      )}
    </ResizeObserver>
  );
};

export default VirtualTable;
