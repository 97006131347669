import React, { useContext } from 'react';
import { t } from 'i18next';
import { PageHeader, List, Row, Col } from '../../../components';
import { SoundFilled } from '@ant-design/icons';
import { screenReaderItems } from './constant';
import s from './index.module.less';
import { CloseButton } from '../../../shared';
import { STATE_DOMAIN } from '../../../constants';
import Context from '../../../context';

const ScreenReader = ({ onClose }) => {
  const [context, ,] = useContext(Context);
  return (
    <div className={s.screenReaderContainer}>
      <Row className="pb-3">
        <Col xs={16} md={18} lg={18}>
          <PageHeader
            className="p-0"
            title={
              <>
                <SoundFilled /> {t('Screen_Readers')}
              </>
            }
          />
        </Col>
        <Col className="d-flex align-items-center justify-content-end" xs={8} md={6} lg={6}>
          <CloseButton type="black" onClick={onClose} />
        </Col>
      </Row>

      <div className={s.content}>
        <h4>{t('Screen_text', { statedomain: t(STATE_DOMAIN[context.domain]) })}</h4>
        <List
          itemLayout="horizontal"
          dataSource={screenReaderItems}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <>
                    {item.title}
                    <span>
                      <h5 className={s.typeText}>{item.type}</h5>
                    </span>
                  </>
                }
                description={
                  <h3>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      {item.link}
                    </a>
                  </h3>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default ScreenReader;
