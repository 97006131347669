import { ReactComponent as Ministry } from '../../assets/svg/icons/ministry.svg';
import { ReactComponent as Sector } from '../../assets/svg/icons/sector.svg';
import { ReactComponent as Collection } from '../../assets/svg/icons/collection.svg';
import { ReactComponent as Dataset } from '../../assets/svg/icons/datasets.svg';
import { ReactComponent as DatasetKadap } from '../../assets/svg/icons/dataset-new.svg';
import { ReactComponent as MapPin } from '../../assets/svg/icons/map-pin.svg';
import { ReactComponent as Clock } from '../../assets/svg/icons/clock.svg';
import { ReactComponent as Merge } from '../../assets/svg/icons/merge.svg';
import { ReactComponent as RightChevron } from '../../assets/svg/icons/right-chevron.svg';
import { ReactComponent as Link } from '../../assets/svg/icons/link.svg';
import { ReactComponent as PrimaryKey } from '../../assets/svg/icons/primary-key.svg';
import { ReactComponent as FilePin } from '../../assets/svg/icons/file-pin.svg';
import { ReactComponent as Indicater } from '../../assets/svg/icons/indicater.svg';
import { ReactComponent as Warning } from '../../assets/svg/icons/warning.svg';
import { ReactComponent as DoubleArrowDown } from '../../assets/svg/icons/double-arrow-down.svg';
import { ReactComponent as Edit } from '../../assets/svg/icons/edit.svg';
import { ReactComponent as Curved } from '../../assets/svg/icons/curved.svg';
import { ReactComponent as Visulaize } from '../../assets/svg/visualize-default.svg';
import { ReactComponent as AutoComplete } from '../../assets/svg/icons/gantt-chart.svg';
import { ReactComponent as LineChart } from '../../assets/svg/icons/line-chart.svg';
import { ReactComponent as ColumnChart } from '../../assets/svg/icons/column-chart.svg';
import { ReactComponent as BubbleChart } from '../../assets/svg/icons/bubble-chart.svg';
import { ReactComponent as PieChart } from '../../assets/svg/icons/pie-chart.svg';
import { ReactComponent as ScatterChart } from '../../assets/svg/icons/scatter-chart.svg';
import { ReactComponent as MapView } from '../../assets/svg/icons/geo-map.svg';
import { ReactComponent as PivotTable } from '../../assets/svg/icons/pivot-table.svg';
import { ReactComponent as TreeMap } from '../../assets/svg/icons/tree-map.svg';
import { ReactComponent as Location } from '../../assets/svg/icons/location-pointer.svg';
import { ReactComponent as EditIcon } from '../../assets/svg/icons/edit-icon.svg';
import { ReactComponent as ChartOptions } from '../../assets/svg/icons/chart-options.svg';
import { ReactComponent as PlusIcon } from '../../assets/svg/icons/plus-icon.svg';
import { ReactComponent as AddIcon } from '../../assets/svg/icons/add-icon.svg';
import { ReactComponent as AddIconFilled } from '../../assets/svg/icons/add-icon-filled.svg';
import { ReactComponent as ChartIndicator } from '../../assets/svg/icons/chart-indicator.svg';
import { ReactComponent as ChartLocation } from '../../assets/svg/icons/chart-location.svg';
import { ReactComponent as ChartOthers } from '../../assets/svg/icons/chart-others.svg';
import { ReactComponent as ChartTime } from '../../assets/svg/icons/chart-time.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/icons/close-icon.svg';
import { ReactComponent as ReplaceIcon } from '../../assets/svg/icons/replace-icon.svg';
import { ReactComponent as SwitchToTreeMap } from '../../assets/svg/icons/swith-to-tree-map.svg';
import { ReactComponent as SearchSector } from '../../assets/svg/icons/search-sector.svg';
import { ReactComponent as Filter } from '../../assets/svg/icons/filter.svg';
import { ReactComponent as ReorderDimension } from '../../assets/svg/icons/reorder-dimension.svg';
import { ReactComponent as MinistryBlue } from '../../assets/svg/icons/ministry-blue.svg';
import { ReactComponent as DataCatalogue } from '../../assets/svg/data-catalogue.svg';
import { ReactComponent as SortAtoZ } from '../../assets/svg/icons/sort-atoz.svg';
import { ReactComponent as SortZtoA } from '../../assets/svg/icons/sort-ztoa.svg';
import { ReactComponent as CollectionBlueIcon } from '../../assets/svg/icons/collection-blue.svg';
import { ReactComponent as Checked } from '../../assets/svg/icons/checked.svg';
import { ReactComponent as ExclamationCircled } from '../../assets/svg/icons/exclamation-circled.svg';
import { ReactComponent as StarFilled } from '../../assets/svg/icons/star-filled.svg';
import { ReactComponent as Merged } from '../../assets/svg/icons/merged.svg';
import { ReactComponent as Share } from '../../assets/svg/icons/share-icon.svg';
import { ReactComponent as DragIcon } from '../../assets/svg/icons/drag-icon.svg';
import { ReactComponent as ExclamationTriangle } from '../../assets/svg/icons/exclamation-triangle.svg';
import { ReactComponent as Conditions } from '../../assets/svg/icons/conditions.svg';
import { ReactComponent as ShowAndHide } from '../../assets/svg/icons/show-and-hide.svg';
import { ReactComponent as SearchResultsNotFound } from '../../assets/svg/searchResultsNotFound.svg';
import { ReactComponent as DepictionOne } from '../../assets/svg/depiction-1.svg';
import { ReactComponent as DepictionMany } from '../../assets/svg/depiction-2.svg';
import { ReactComponent as DepictionManyToOne } from '../../assets/svg/depiction-3.svg';
import { ReactComponent as DepictionManyToMany } from '../../assets/svg/depiction-4.svg';
import { ReactComponent as EllipseIcon } from '../../assets/svg/icons/ellipse-icon.svg';
import { ReactComponent as NotifySuccess } from '../../assets/svg/icons/notify-success.svg';
import { ReactComponent as SortDescending } from '../../assets/svg/icons/sort-descending.svg';
import { ReactComponent as SortAscending } from '../../assets/svg/icons/sort-ascending.svg';
import { ReactComponent as DocumentAndResources } from '../../assets/svg/documentAndResources.svg';
import { ReactComponent as ListIcon } from '../../assets/svg/icons/ellipsis.svg';
import { ReactComponent as NonStandardDataset } from '../../assets/svg/icons/non-standard-dataset.svg';
import { ReactComponent as DiscoverIcon } from '../../assets/svg/icons/discover-icon.svg';
import { ReactComponent as QueryIcon } from '../../assets/svg/icons/query-icon.svg';
import { ReactComponent as MergeIcon } from '../../assets/svg/icons/merge-icon.svg';
import { ReactComponent as VisualizeIcon } from '../../assets/svg/icons/visualise-icon.svg';
import { ReactComponent as NdapHomeIcon } from '../../assets/svg/icons/ndap-hometext.svg';
import { ReactComponent as DatasetNew } from '../../assets/svg/icons/datasets.svg';
import { ReactComponent as DatasetGreen } from '../../assets/svg/icons/dataset-green.svg';
import { ReactComponent as SectorNew } from '../../assets/svg/icons/sector-new.svg';
import { ReactComponent as MinistriesNew } from '../../assets/svg/icons/ministries-new.svg';
import { ReactComponent as MinistriesNewColor } from '../../assets/svg/icons/ministries-newcolor.svg';
import { ReactComponent as SectorKadap } from '../../assets/svg/icons/sector-kadap.svg';
import { ReactComponent as MinistriesKadap } from '../../assets/svg/icons/ministries_kadap.svg';
import { ReactComponent as MostViewedIcon } from '../../assets/svg/icons/mostviewed-icon.svg';
import { ReactComponent as RecentlyAddedIcon } from '../../assets/svg/icons/recentlyadded-icon.svg';
import { ReactComponent as RecentlyAddedIconColor } from '../../assets/svg/icons/recentlyadded-iconcolor.svg';
import { ReactComponent as MostViewedColorIcon } from '../../assets/svg/icons/mostviewedicon-color.svg';
import { ReactComponent as DatasetBlue } from '../../assets/svg/icons/dataset-blue.svg';

const AllCustomIcons = {
  Ministry,
  Sector,
  Collection,
  Dataset,
  MapPin,
  Clock,
  Merge,
  RightChevron,
  Link,
  PrimaryKey,
  FilePin,
  Indicater,
  Warning,
  DoubleArrowDown,
  Edit,
  Curved,
  Visulaize,
  AutoComplete,
  LineChart,
  ColumnChart,
  BubbleChart,
  PieChart,
  ScatterChart,
  MapView,
  PivotTable,
  TreeMap,
  Location,
  EditIcon,
  ChartOptions,
  PlusIcon,
  AddIcon,
  AddIconFilled,
  ChartIndicator,
  ChartLocation,
  ChartOthers,
  ChartTime,
  CloseIcon,
  ReplaceIcon,
  SwitchToTreeMap,
  SearchSector,
  MinistryBlue,
  DataCatalogue,
  SortAtoZ,
  SortZtoA,
  CollectionBlueIcon,
  Filter,
  ReorderDimension,
  Checked,
  ExclamationCircled,
  StarFilled,
  Merged,
  Share,
  DragIcon,
  ExclamationTriangle,
  Conditions,
  ShowAndHide,
  SearchResultsNotFound,
  DepictionOne,
  DepictionMany,
  DepictionManyToOne,
  DepictionManyToMany,
  EllipseIcon,
  NotifySuccess,
  SortDescending,
  SortAscending,
  DocumentAndResources,
  ListIcon,
  NonStandardDataset,
  VisualizeIcon,
  DiscoverIcon,
  QueryIcon,
  MergeIcon,
  NdapHomeIcon,
  DatasetNew,
  SectorNew,
  MinistriesNew,
  MinistriesNewColor,
  DatasetGreen,
  SectorKadap,
  MinistriesKadap,
  MostViewedIcon,
  RecentlyAddedIcon,
  RecentlyAddedIconColor,
  MostViewedColorIcon,
  DatasetBlue,
  DatasetKadap,
};

export default AllCustomIcons;
