import { t } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import Plotly from 'plotly.js-strict-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import { Condition, Empty, Row } from '../../../components';
import {
  GRID,
  TICK_MARKS,
  LODARITHIMIC_Y,
  LODARITHIMIC_X,
  CHART_TYPE_COLUMN,
  CHART_TYPE_LINE,
  CHART_TYPE_PIE,
  CHART_TYPE_TREEMAP,
} from '../../../constants';
import { voidFunction } from '../../../utils';
import { cleanName, wrapString } from '../../../utils/common';
import GraphCard from '../../Plotly/GraphCard';

const Plot = createPlotlyComponent(Plotly);

const Graph = ({
  loadingYears,
  years,
  onChangeAggregateOverTime,
  onChangeYear,
  chart,
  aggregateOverTime = false,
  divId = '',
  gridAndLine = [],
  showTitle = false,
  charTitle = '',
  onDownload = voidFunction,
  chartType,
  selectedYear,
  selectMode,
  merge,
}) => {
  let showgrid = gridAndLine.includes(GRID);
  let ticks = gridAndLine.includes(TICK_MARKS) && 'outside';

  let xaxis = {
    showgrid,
    ticks,
    type: gridAndLine.includes(LODARITHIMIC_X) && 'log',
    ...cloneDeep(chart?.layout?.xaxis || {}),
  };

  let yaxis = {
    showgrid,
    ticks,
    type: gridAndLine.includes(LODARITHIMIC_Y) && 'log',
    ...cloneDeep(chart?.layout?.yaxis || {}),
  };

  let titleMargin = [CHART_TYPE_PIE, CHART_TYPE_TREEMAP, CHART_TYPE_LINE].includes(chartType) ? 130 : 200;
  let height = chart?.layout?.height;

  if (showTitle && chartType === CHART_TYPE_COLUMN) {
    titleMargin = 300;
    height += titleMargin;
  }

  return (
    <GraphCard
      loadingYears={loadingYears}
      years={years}
      onChangeAggregateOverTime={onChangeAggregateOverTime}
      onChangeYear={onChangeYear}
      aggregateOverTime={aggregateOverTime}
      divId={divId}
      onDownload={onDownload}
      chartTitle={cleanName(charTitle, '-')}
      chartType={chartType}
      selectedYear={selectedYear}
      selectMode={selectMode}
      merge={merge}
    >
      <Condition show={!Boolean(chart?.data?.length)}>
        <Row justify="center">
          <Empty
            imageStyle={{
              width: 300,
            }}
            description={t('NoData')}
          />
        </Row>
      </Condition>

      <Condition show={Boolean(chart?.data?.length)}>
        <Plot
          {...chart}
          layout={{
            ...chart?.layout,
            height,
            title: {
              text: showTitle
                ? `<span style="font-size: 12px;text-align:center;">${wrapString(charTitle, 150)}</span>`
                : '',
              y: 0.9,
              x: 0.5,
              xanchor: 'center',
              yanchor: 'top',
            },
            xaxis,
            yaxis,
            margin: {
              ...(chart?.layout?.margin || {}),
              t: showTitle ? titleMargin : chart?.layout?.margin.t || 0,
            },
          }}
          config={{
            displaylogo: false,
            displayModeBar: 'hover',
          }}
          divId={divId}
        />
      </Condition>
    </GraphCard>
  );
};

export default Graph;
