import CustomIcon from './index';
import {
  TYPE_DIMENSIONS,
  TYPE_INDICATORS,
  TYPE_OTHERS,
  TYPE_TIME,
  TYPE_LOCATION,
  MEREGE_COLOR_CODES,
} from '../../constants';

const IconByType = {
  location: 'MapPin',
  other: 'FilePin',
  others: 'FilePin',
  time: 'Clock',
  measure: 'Indicater',
  [TYPE_DIMENSIONS]: 'FilePin',
  [TYPE_INDICATORS]: 'Indicater',
  [TYPE_OTHERS]: 'FilePin',
  [TYPE_TIME]: 'Clock',
  [TYPE_LOCATION]: 'MapPin',
};

const DimensionIcon = ({ type, colorCode = false, merge = false, ...rest }) => {
  if (!type) return null;
  let props = {};

  if (merge && colorCode !== false) {
    let colors = MEREGE_COLOR_CODES.length;
    props['fill'] = MEREGE_COLOR_CODES[colorCode < 0 ? 0 : colorCode > colors ? colors : colorCode];
  }

  type = type.toLowerCase();
  const icon = IconByType[type] || IconByType[type + 's'] || IconByType[type.substring(0, type.length - 1)];

  return <CustomIcon type={icon || type} {...rest} {...props} />;
};

export default DimensionIcon;
