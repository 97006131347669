import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { Typography, Row, Col } from '../../../components';
import { GridFixedSpan } from '../../../constants';
import CustomIcon from '../../CustomIcon';

const { Text } = Typography;
const expectedData = ['Identiers', t('Number_of_Variables'), 'Number_of_Rows'];
const icons = { Identiers: 'FilePin', Number_of_Variables: 'Indicater', Number_of_Rows: 'PivotTable' };
const sizes = { Identiers: 'five', Number_of_Variables: 'three', Number_of_Rows: 'two' };

const ProcessingSummary = ({ data = {}, stars = 1 }) => {
  if (isEmpty(data)) return null;

  const rows = expectedData.map((key) => ({ ...data[key], icon: icons[key], size: sizes[key] }));
  const star = [];

  for (let i = 1; i <= data?.Star_Rating?.StarRating; i++) {
    star.push(<CustomIcon type={'StarFilled'} className="star" key={`star-${i}`} />);
  }

  return (
    <div className="border rounded-common p-4">
      <Typography.Text className="font-18 font-weight-bold">{t('ProcessingSummary')}</Typography.Text>
      <Row gutter={[0, 20]} className="pt-3">
        {rows.map((item, i) => (
          <Col key={item.subheader + i} {...GridFixedSpan.size[item.size]} className="pl-2 pr-2">
            <div className="position-relation pl-2">
              <span className="position-absolute text-primary" style={{ left: -4 }}>
                <CustomIcon type={item.icon} width={16} />
              </span>
              <div className="font-12 text-muted">{item.subheader?.replace('Identiers', 'Dimensions')}</div>
              <div>
                <strong>
                  <Text className="font-14">{item.value}</Text>
                </strong>
              </div>
            </div>
          </Col>
        ))}
        <Col {...GridFixedSpan.size.two}>
          <div className="font-12 text-muted">{t('StarRating')}</div>
          <div>
            <strong>
              <Text className="font-14">{star}</Text>
            </strong>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProcessingSummary;
