import React from 'react';
import { getFailedReport } from '../../services/dataset';

const FailedReport = (WrapperComponent, { load = false, sourceID, id }) => {
  const [failedReport, setFailedReport] = React.useState([]);
  const [hasError, setError] = React.useState(false);
  const [isFetching, setFetching] = React.useState(false);

  React.useEffect(() => {
    const fetchFailedReport = async () => {
      setFetching(true);
      const report = await getFailedReport({ sourceID, id });
      const { data, IsError } = report;

      if (!IsError) {
        setFailedReport(data.records);
      } else {
        setError(true);
      }

      setFetching(false);
    };

    if (load && !failedReport?.length) {
      fetchFailedReport();
    }
  }, [sourceID, id, load, failedReport]);

  return () => (
    <WrapperComponent
      show={load}
      failedReport={{
        data: failedReport,
        hasError,
        isFetching,
      }}
    />
  );
};

export default FailedReport;
