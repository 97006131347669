import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Context from '../../context';

export const useNavigate = () => {
  const [context, setContext] = useContext(Context);
  const history = useHistory();

  return (url, authenticated = true) => {
    if (!context.isAuth && authenticated) {
      setContext((ps) => ({ ...ps, showLogin: true, afterLoginRedirect: url }));
    } else {
      history.push(url);
    }
  };
};

export default useNavigate;
