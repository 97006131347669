import React from 'react';
import { voidFunction } from '../../../utils';
import { Multiselect } from '../../MultiselectDropdown';
import RangeDropdown from '../../RangeDropdown';
import { Row, Col } from '../../../components';

const defaultValue = [];

const Filters = ({
  onFilterSelect = voidFunction,
  frequency = defaultValue,
  granularity = defaultValue,
  ministry = defaultValue,
  sectors = defaultValue,
  timerange = defaultValue,
  appliedFilters = {},
  excludeFilters = [],
  keyName = 'key',
}) => {
  const range = (timerange[0]?.name || '').split(',');

  const allFilters = React.useMemo(() => {
    if (!frequency.length && !granularity.length && !ministry.length && !sectors.length && !timerange.length) {
      return null;
    }

    const notExcluded = (key) => {
      return !excludeFilters.includes(key) ? true : false;
    };

    const {
      sector: appliedSectors,
      frequency: appliedFrequency,
      granularity: appliedFranularity,
      ministry: appliedMinistry,
      fromyear,
      toyear,
    } = appliedFilters;

    const appliedTimerange = fromyear && toyear ? [fromyear, toyear] : [];

    return (
      <div style={{ lineHeight: 0 }}>
        <Row gutter={[10, 10]} className={'joyride-sector-step3'}>
          {notExcluded('sectors') ? (
            <Col>
              <Multiselect
                data={sectors}
                size={20}
                text={'filtersSectors'}
                mr={10}
                applyOnly={true}
                input={'checkbox'}
                keyName={keyName}
                onApply={(filter) => onFilterSelect(filter, 'sector')}
                apply={appliedSectors}
              />
            </Col>
          ) : null}

          {notExcluded('timerange') ? (
            <Col>
              <RangeDropdown
                min={range[0] || 0}
                max={range[1] || 0}
                text={'filtersTimeRange'}
                onApply={(filter) => onFilterSelect(filter, 'timerange')}
                apply={appliedTimerange}
              />
            </Col>
          ) : null}

          {notExcluded('frequency') ? (
            <Col>
              <Multiselect
                data={frequency}
                size={20}
                text={'filtersFrequency'}
                input={'checkbox'}
                keyName={keyName}
                applyOnly={true}
                onApply={(filter) => onFilterSelect(filter, 'frequency')}
                apply={appliedFrequency}
              />
            </Col>
          ) : null}

          {notExcluded('granularity') ? (
            <Col>
              <Multiselect
                data={granularity}
                text={'filtersGranularity'}
                size={20}
                input={'checkbox'}
                keyName={keyName}
                applyOnly={true}
                onApply={(filter) => onFilterSelect(filter, 'granularity')}
                apply={appliedFranularity}
              />
            </Col>
          ) : null}

          {notExcluded('ministry') ? (
            <Col>
              <Multiselect
                data={ministry}
                text={'filtersMinistries'}
                size={20}
                input={'checkbox'}
                keyName={keyName}
                applyOnly={true}
                onApply={(filter) => onFilterSelect(filter, 'ministry')}
                apply={appliedMinistry}
              />
            </Col>
          ) : null}
        </Row>
      </div>
    );
  }, [
    excludeFilters,
    frequency,
    granularity,
    ministry,
    onFilterSelect,
    range,
    sectors,
    timerange,
    appliedFilters,
    keyName,
  ]);

  return allFilters;
};

export default Filters;
