import React from 'react';
import { BellFilled, SyncOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { presetPalettes } from '@ant-design/colors';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import Context from '../../context';
import { ACTIVITIES } from '../../constants';
import { subscribeData, postClickStreamEvent } from '../../services';
import { Modal, FatLanguageButton } from '../../components';
import notify from '../NotificationModal';
import { RoutePaths } from '../../routePaths';

const { confirm } = Modal;
const getStoreSubscription = (subscriptions, id) =>
  (subscriptions?.records || []).find((data) => String(data.id) === String(id));

const SubscribeButton = ({ type, id, name = '', ml = 3 }) => {
  const [store, setContext] = React.useContext(Context);
  const [subscriptions, setSubscription] = React.useState(getStoreSubscription(store.subscriptions, id));
  const [isLoading, setLoading] = React.useState(false);
  const histroy = useHistory();
  const subscriptionType = type === 'merge' ? 'datasetNew' : type;

  React.useEffect(() => {
    setSubscription(getStoreSubscription(store.subscriptions, id));
  }, [store.subscriptions, id]);

  const handleClick = () => {
    if (!store.profile?.email) {
      setContext((state) => ({
        ...state,
        showLogin: true,
      }));
    } else {
      const doSubscribe = () =>
        subscribeData({
          userID: store.profile.email,
          ip_sourcecode: id,
          ip_type: type,
          subscribe: subscriptions ? false : true,
          onError: () => {
            notify.error({
              message: subscriptions ? 'UnSubscriptionFailed' : 'SubscriptionFailed',
            });
            Sentry.captureException('Failed to fetch subscription details');
          },
          setLoading,
          setData: (data) => {
            notify.success({
              message: subscriptions ? 'DataUnsubscribed' : 'DataSubscribed',
              onLinkClick: () => {
                histroy.push(`${RoutePaths.PROFILE}/subscriptions`);
              },
              linkText: 'ViewSubscriptions',
            });

            postClickStreamEvent({
              activity: subscriptions ? ACTIVITIES.UNSUBSCRIBE : ACTIVITIES.SUBSCRIBE,
              userid: store?.profile?.email || '',
              subscribe_object_id: `${id}`,
              subscribe_object_type: `${type}`,
            });

            setContext((ps) => ({ ...ps, subscriptions: data }));
          },
        });

      if (subscriptions) {
        confirm({
          title: t('ConfirmUnSubscription'),
          icon: <ExclamationCircleOutlined />,
          content: name,
          onOk() {
            doSubscribe();
          },
        });
      } else {
        doSubscribe();
      }
    }
  };

  return (
    <span>
      {subscriptions ? (
        <FatLanguageButton
          text={t('UnSubscribe')}
          tooltip={t('Subscribed', { type: `$t(${subscriptionType})` })}
          className={`ml-${ml} font-16 font-weight-semi-bold text-capitalize joyride-dataset-step5`}
          iconLeft={(isLoading && <SyncOutlined spin />) || <BellFilled />}
          onClick={handleClick}
          style={{ color: presetPalettes.gold.primary }}
          type="link"
        />
      ) : (
        <FatLanguageButton
          text={t('Subscribe')}
          tooltip={t('ClickToSubscribe', { type: `$t(${subscriptionType})` })}
          type="link"
          className={`ml-${ml} font-16 font-weight-semi-bold text-capitalize joyride-dataset-step5`}
          iconLeft={(isLoading && <SyncOutlined spin />) || <BellFilled />}
          onClick={handleClick}
        />
      )}
    </span>
  );
};

export default SubscribeButton;
