import { useContext, useState, useEffect } from 'react';
import Context from '../../context';
import { ScreenSize } from '../../constants';

export const useDeviceType = () => {
  const [context] = useContext(Context);
  const [isMobile, setMobile] = useState(false);
  const [isTablet, setTablet] = useState(false);

  useEffect(() => {
    setMobile(context.screenContext === ScreenSize.Mobile);
    setTablet(context.screenContext === ScreenSize.Tablet);
  }, [context.screenContext]);

  return {
    isDesktop: !isMobile && !isTablet,
    isMobile,
    isTablet,
  };
};
