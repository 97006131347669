const APIConfinguration = {
  db: {
    default: 'NDAPDB',
  },
  route: {
    bookmark: 'getbookmark',
  },
  apiErrorResponse: {
    data: {
      IsError: true,
      records: [],
    },
  },
};

export default APIConfinguration;
