import { cloneDeep, isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { useAppliedFilterContext } from '../providers/FilterContextProvider';
import { getSourceID } from '../utils/common';

export const checkForUpdate = ({ sourceID, types = [], current = {}, updateInfo = {}, appliedFilters }) => {
  const sources = getSourceID(sourceID, true) || [];
  let updatedRecords = {};

  if (sources.find((ID) => types.includes(updateInfo[ID]))) {
    sources.forEach((ID) => {
      if (appliedFilters[ID]) {
        types.forEach((type) => {
          if (!updatedRecords[ID]) {
            updatedRecords[ID] = {};
          }

          updatedRecords[ID][type] = appliedFilters[ID][type] || [];
        });
      }
    });
  } else {
    return false;
  }

  return !isEqual(current, updatedRecords) ? updatedRecords : false;
};

const useOnFilterUpdate = ({ sourceID, types = [] }) => {
  const changeRef = useRef({});
  const { updateInfo, appliedFilters } = useAppliedFilterContext();

  useEffect(() => {
    if (types.length) {
      const updatedRecords = checkForUpdate({
        sourceID,
        current: changeRef.current,
        appliedFilters,
        updateInfo,
        types,
      });

      if (updatedRecords) {
        changeRef.current = cloneDeep(updatedRecords);
      }
    } else {
      changeRef.current = cloneDeep(appliedFilters);
    }
  }, [updateInfo, sourceID, types, appliedFilters]);

  return changeRef.current;
};

export default useOnFilterUpdate;
