import React, { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Steps } from 'intro.js-react';
import { JoyridePages } from '../../constants';
import Context from '../../context';
import 'intro.js/introjs.css';
import s from './index.module.less';

const IntroJoyRide = (props) => {
  const [context] = useContext(Context);
  const { steps, enabled, page } = props;

  const [stepsEnabled, setStepsEnabled] = useState(enabled);

  useEffect(() => {
    setStepsEnabled(enabled);
  }, [enabled]);

  const getLocalStorageJoyride = (page) => {
    let pageList = [];
    const joyrideLocalStorage = localStorage.getItem(JoyridePages.Joyride);
    const splitStorage = joyrideLocalStorage && joyrideLocalStorage?.split(',');
    if (splitStorage) {
      pageList = [...splitStorage, page];
    } else {
      pageList = [page];
    }
    let uniqueList = pageList.filter((c, index) => {
      return pageList.indexOf(c) === index;
    });
    return uniqueList;
  };

  const onExit = (data) => {
    setStepsEnabled(false);
    let Unique_PageList = [];
    Unique_PageList = getLocalStorageJoyride(page);
    localStorage.setItem(JoyridePages.Joyride, Unique_PageList);
  };

  return (
    <>
      {context.screenContext === 'desktop' && (
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={0}
          onExit={onExit}
          options={{
            nextLabel: t('Next'),
            prevLabel: t('Back'),
            doneLabel: t('Done'),
            hidePrev: false,
            hideNext: false,
            tooltipPosition: 'bottom',
            exitOnEsc: true,
            exitOnOverlayClick: true,
            showStepNumbers: true,
            showBullets: false,
            showButtons: true,
            nextToDone: true,
            showProgress: false,
            buttonClass: `ant-btn ant-btn-primary ${s.primaryBtn}`,
            keyboardNavigation: true,
            tooltipClass: s.joyrideTooltip,
            highlightClass: 'joyride-highlight',
          }}
        />
      )}
    </>
  );
};

export default React.memo(IntroJoyRide);
