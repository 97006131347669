import React from 'react';
import get from 'lodash/get';

import { getDataService } from '../../services';

export const ProductWiseColumn = {
  ministries: {
    id: 'id',
    name: 'ministry_name',
  },
  sectors: {
    id: 'sector_code',
    name: 'sector_name',
    icon: 'icon',
  },
};

const getColumn = (productName, key) => (ProductWiseColumn[productName] || {})[key];
const DataService = (WrapperComponent, { productName, ...rest }) => {
  const [isFetchingServices, setIsFetching] = React.useState(false);
  const [services, setService] = React.useState([]);
  const [activeService, setActiveService] = React.useState({});
  const activeServiceID = rest?.match?.params?.id;

  React.useEffect(() => {
    const fetchData = async () => {
      const idColumn = getColumn(productName, 'id');
      const nameColumn = getColumn(productName, 'name');
      const iconColumn = getColumn(productName, 'iconName');

      setIsFetching(true);
      const {
        data: { records, IsError },
      } = await getDataService(productName);

      const dataRecords = records.map((d) => ({
        name: get(d, nameColumn, get(d, 'name', '')),
        id: get(d, idColumn, get(d, 'id', '')),
        icon: get(d, iconColumn, get(d, 'iconName', '')),
        key: get(d, nameColumn, get(d, 'name', '')),
        display: get(d, nameColumn, get(d, 'name', '')),
        raw: d,
      }));

      setService(IsError ? [] : dataRecords);
      setIsFetching(false);
    };

    fetchData();
  }, [productName]);

  React.useEffect(() => {
    if (activeServiceID && services?.length) {
      const active = services.find((dataService) => String(dataService.id) === String(activeServiceID)) || {};
      setActiveService(active);
    }
  }, [activeServiceID, services]);

  return () => (
    <WrapperComponent
      activeService={activeService}
      dataService={services}
      isFetchingService={isFetchingServices}
      productName={productName}
      {...rest}
    />
  );
};

export default DataService;
