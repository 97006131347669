import React from 'react';
import { Button } from 'antd';
import { Tooltip } from 'antd';
import i18n from 'i18next';
import { voidFunction } from '../../utils';
import { FATLANGUAGES } from '../../constants';
import s from '../../shared/Dataset/index.module.less';
const FatLanguageButton = ({
  className = '',
  text,
  iconLeft,
  iconRight,
  tooltip,
  tooltipPlacement,
  onClick = voidFunction,
  ...rest
}) => {
  const ButtonContent = (props) => (
    <Button className={`d-flex align-items-center ${className}`} onClick={onClick} {...rest}>
      {iconLeft && iconLeft}
      {props?.content}
      {iconRight && iconRight}
    </Button>
  );

  const trimFunction = (text, count) => {
    return text.slice(0, count) + '...';
  };
  const currentLanguage = i18n.language;
  let charCount = text?.length;

  if (FATLANGUAGES.includes(currentLanguage)) {
    charCount = text?.length / 2;
  }

  return (
    <>
      {text?.length > charCount ? (
        <Tooltip
          title={tooltip ? tooltip : text}
          placement={tooltipPlacement ? tooltipPlacement : 'top'}
          // getPopupContainer={(triggerNode) => triggerNode} WIP : enabling this is causing Tooltip Issues, kindly do no enable
        >
          <div>
            <Button className={`fatbtn d-flex align-items-center ${className}`} onClick={onClick} {...rest}>
              {iconLeft && iconLeft}
              <span className={charCount > 7 ? `text-truncate d-block  ${s.width60}` : ''}>
                {trimFunction(text, charCount)}
              </span>
              {iconRight && iconRight}
            </Button>
          </div>
        </Tooltip>
      ) : tooltip ? (
        <Tooltip
          title={tooltip}
          placement={tooltipPlacement ? tooltipPlacement : 'top'}
          // getPopupContainer={(triggerNode) => triggerNode} WIP : enabling this is causing Tooltip Issues, kindly do no enable
          className="text-truncate d-block"
        >
          <div>
            <Button className={`fatbtn d-flex align-items-center ${className}`} onClick={onClick} {...rest}>
              {iconLeft && iconLeft}
              <span>{text}</span>
              {iconRight && iconRight}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <ButtonContent content={text !== '' && <span>{text}</span>} />
      )}
    </>
  );
};

export default FatLanguageButton;
