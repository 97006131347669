import { Avatar, Tooltip, Typography, Row } from '../../../components';
import { MEREGE_COLOR_CODES } from '../../../constants';

const MergePlaceholder = ({
  name = 'D',
  colorCode = 0,
  mt = 'n2',
  size = 30,
  className = '',
  showSourceName = false,
  fonSize = '16',
  fontWeight = 'bold',
  rowClassName = '',
  titleClassname = '',
}) => {
  let colorCodes = [...MEREGE_COLOR_CODES];
  let avatar = (
    <Avatar
      style={{ backgroundColor: colorCodes[colorCode] || colorCodes[0] }}
      size={size}
      className={`mt-${mt} mr-1 ${className}`}
    >
      {name?.substring(0, 1).toUpperCase()}
    </Avatar>
  );

  if (showSourceName) {
    return (
      <Row className={`${rowClassName} position-relative w-100`}>
        <div className=" position-absolute" style={{ top: -6 }}>
          {avatar}
        </div>
        <div className=" ml-4 pl-1 w-100">
          <Tooltip title={name}>
            <Typography.Text className={`m-0 font-${fonSize} font-weight-${fontWeight} ${titleClassname} w-100`}>
              {name}
            </Typography.Text>
          </Tooltip>
        </div>
      </Row>
    );
  }

  return avatar;
};

export default MergePlaceholder;
