import get from 'lodash/get';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import axios from '../axios';
import * as Sentry from '@sentry/browser';
import {
  STANDERED_FUNCTIONS,
  TYPE_ALL_DIMENSIONS,
  TYPE_BUBBLE_NAME,
  TYPE_BUBBLE_SIZE,
  TYPE_COLUMN,
  TYPE_CONDITION,
  TYPE_DIMENSIONS,
  TYPE_INDICATORS,
  TYPE_LOCATION,
  TYPE_OTHERS,
  TYPE_PIVOT_COLUMNS,
  TYPE_TIME,
  TYPE_VALUE,
  TYPE_X_INDICATER,
  TYPE_Y_INDICATER,
  TYPE_Z_AXSIS_DIMENSIONS,
  VIEW_MODE_PREVIEW,
  WEIGHTED_AVG,
  BLUEBROWN,
  TYPE_INDICATORS_CHART,
  TYPE_GRANULARITY,
  TYPE_OPEN_API_INDICATORS,
  TYPE_OPEN_API_LOCATION,
  OPEN_API_TYPES,
  ANONYMOUS_USER,
  GRANULARITIES,
  INDICATER_DIMENSION_I,
  NOT_APPLICABLE,
  TYPE_YEAR,
  TYPE_CONDITION_VISUALIZATION,
  VISUALISATIONS_ORDER_BY,
} from '../constants';
import {
  BlockName,
  Country,
  DistrictName,
  LocationCodes,
  StateName,
  SubDistrictName,
  VillageTownName,
  LocationNames,
} from '../shared/LocationFilter/constant';
import { voidFunction, replaceAll, parseStringToNumber, parseDataTableYear } from '../utils';

export const ParseParams = (params) => {
  const parseText = (text, double = true) => {
    if (!text.includes || !text) {
      return text;
    }

    return text.includes(double ? "''" : "'") ? text : double ? text : text;
  };

  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      params[key] = `${params[key]
        .map((t) => parseText(t))
        .join(',')
        .trim(',')}`;
    } else if (typeof params[key] !== 'object') {
      params[key] = parseText(params[key], false);
    }
  });

  return params;
};

export const getDatasetByAdavanceSearchSuggest = (params = {}) => {
  const url = `/autosearchsuggest`;
  return axios.post(url, params);
};

export const getDatasetDefaultParams = () => ({
  ip_sourceid: [],
  ip_columns: {},
  ip_filter: [],
  ip_orderby: [],
  ip_sourcemaster: 1,
  ip_datavariables: 1,
  ip_datasetprofile: 1,
  ip_limit: 100,
  ip_offset: 0,
  view_name: '',
});

export const hasValidSources = (sources) => sourceIDToNumberArray(sources).filter((id) => !isNaN(id)).length > 0;

export const invalidAxiosRejection = (error = '') =>
  new Promise((resolve) => resolve({ data: { IsError: false, records: 0, error } }));

export const getDatasetData = (params = {}) => {
  const defaultParams = getDatasetDefaultParams();
  const url = `/v1/dataset/details`;

  params.ip_sourceid = sourceIDToNumberArray(params.ip_sourceid);

  return axios.post(url, { ...defaultParams, ...params });
};

export const getDatasetDetails = (params = {}) => {
  const url = `/datasets`;
  return axios.post(url, params);
};

export const topDatasets = (params = {}) => axios.post('/v1/dataset/top', params);

export const datasetParams = (params = {}) => {
  const defaultParams = {
    ip_Frequency: "'*'",
    ip_Granularity: "'*'",
    ip_as_collection: "'*'",
    ip_as_dataset: "'*'",
    ip_as_indicator: "'*'",
    ip_as_location: "'*'",
    ip_as_ministrys: "'*'",
    ip_as_sector: "'*'",
    ip_collection: "'*'",
    ip_department: "'*'",
    ip_excludesource: "'*'",
    ip_levelOfDetail: "'Full'",
    ip_limit: "'100'",
    ip_maxyear: '0',
    ip_ministry: "'*'",
    ip_minyear: '0',
    ip_offset: '0',
    ip_query: "'*'",
    ip_sector: "'*'",
    ip_sourceid: "'*'",
    ip_techquery: "'*'",
    ip_username: `'${ANONYMOUS_USER}'`,
  };

  return ParseParams({ ...defaultParams, ...params });
};

export const getDatasetDownloadLink = (params = {}) => {
  const url = `/v1/dataset/export`;
  return axios.get(url, { params: params });
};

export const getDatasetBySearch = (params = {}) => {
  const url = `/searchresultdatasets`;
  return axios.post(url, datasetParams(params));
};

export const getAutoSearchResultDatasets = (params = {}) => {
  return getDatasetBySearch(params);
};

export const searchDatasetFilters = (params = {}) => {
  const defaultParams = {
    ip_collection: "'*'",
    ip_query: "'*'",
    ip_dataset: "'*'",
    ip_indicator: "'*'",
    ip_location: "'*'",
    ip_ministrys: "'*'",
    ip_sector: "'*'",
  };

  const url = `/searchdatasetfilters`;
  return axios.post(url, ParseParams({ ...defaultParams, ...params }));
};

export const getDatasetFilters = (params = {}) => {
  const defaultParams = {
    ip_collection: "'0'",
    ip_ministryid: '0',
    ip_sectorid: '0',
  };

  const url = `/datasetfilters`;
  return axios.post(url, ParseParams({ ...defaultParams, ...params }));
};

export const getDatasetProcessingReportSummary = (sourceID) => {
  const url = `/v1/dataset/report/summary`;
  return axios.get(url, {
    params: {
      sourceID,
    },
  });
};

export const getFailedReport = ({ sourceID, id }) => {
  const url = `/v1/dataset/report/detail`;
  return axios.get(url, {
    params: {
      sourceID,
      ID: id,
    },
  });
};

export const getDatasets = (params = {}) => {
  return getDatasetBySearch({ ip_limit: '10000', ...params });
};

export const getMetaDataValues = (params = {}) => {
  let limit = params.limit || 100;
  let page = params.page || 1;

  let defaultParams = {
    ip_sourceid: [],
    ip_columns: [],
    ip_limit: limit,
    ip_filter: [],
    ip_search: [],
    view_name: '',
  };

  params['ip_offset'] = params.offset ? params.offset : (page - 1) * limit;

  if (params.ip_sourceid) {
    params.ip_sourceid = sourceIDToNumberArray(params.ip_sourceid);
  }

  let apiParamas = { ...defaultParams, ...params };
  return axios.post(`/v1/dataset/metadatavalues`, apiParamas);
};

export const getDimensions = async ({
  sourceID,
  ip_columns,
  ip_search = [],
  ip_limit = 10000,
  ip_filter = [],
  ip_offset = 0,
  setLoading = voidFunction,
  setData = voidFunction,
  setError = voidFunction,
  view_name = '',
  rawCall = false,
}) => {
  let sourceIDs = sourceIDToNumberArray(sourceID);

  if (!sourceIDs?.length) return null;

  let formatSearch = [];
  if (ip_search?.length) {
    ip_search.forEach((item) => {
      if (item.key === TYPE_YEAR) {
        formatSearch.push({ ...item, value: item?.value?.map((val) => +val) });
      } else formatSearch.push(item);
    });
  }

  const params = {
    ip_sourceid: sourceIDs,
    ip_columns,
    ip_filter,
    ip_search: formatSearch || [],
    ip_limit,
    ip_offset,
    view_name,
  };

  setLoading(true);

  try {
    if (rawCall) {
      return axios.post(`/v1/dataset/getdimensionvalues`, params);
    }

    const { data } = await axios.post(`/v1/dataset/getdimensionvalues`, params);
    // setData(data?.records?.filter((d) => d.ID) || []);

    const tempRecords =
      data?.records
        ?.filter((d) => d.ID)
        ?.map((item) => {
          return { ...item, Name: parseDataTableYear(item?.Name, params?.ip_columns, params?.ip_sourceid) };
        }) || [];
    setData(tempRecords);

    setLoading(false);
    return data;
  } catch (error) {
    if (axios.isCancel(error)) return;
    Sentry.captureException(`Failed to get dimension values data by search , ` + error);
    const isCancel = (error.toString?.() || '').toLowerCase().includes('cancel');
    if (!isCancel) setError(error);
    setLoading(false);

    return error;
  }
};

export const getDatasetLocation = async ({
  sourceID,
  states = [],
  districts = [],
  setLoading = voidFunction,
  setData = voidFunction,
  setError = voidFunction,
  view_name = '',
}) => {
  let ip_columns = 'StateName';
  let ip_search = [];

  if (states.length) {
    ip_columns = 'DistrictName';
    ip_search = [{ key: 'StateCode', operator: 'IN', value: states, type: 'dimension' }];
  }

  if (districts.length) {
    ip_columns = "SubDistrictName'";
    ip_search = [{ key: 'DistrictCode', operator: 'IN', value: districts, type: 'dimension' }];
  }

  return await getDimensions({
    sourceID,
    ip_columns,
    setLoading,
    setData,
    setError,
    ip_search,
    view_name,
  });
};

export const getCommonDimensions = (sources) => {
  if (!sources) return;

  const sourceID = sourceIDToNumberArray(sources);
  if (sourceID.length > 1) {
    return axios.post(`/v1/dimension/common`, { ip_sourceid: sourceID });
  } else return [];
};

export const getCommonDimensionChange = (level) => {
  if (!level) return;

  return axios.get(`/v1/dimension/commondynamic`, {
    params: {
      ip_option_selected: level,
    },
  });
};

export const createIPFilterString = (key, value) => {
  if (!value || (Array.isArray(value) && !value.length)) {
    return '';
  }

  if (['string', 'number'].includes(typeof value)) {
    value = [value];
  }

  return `(lower("${key}") in ('${(value || []).map((v) => `'${String(v).toLowerCase()}'`).join(',')}'))`;
};

export const openApiFilterDataValue = (item, dataKey) => {
  const { checked } = item;
  let parsedKeys = Object.keys(checked);

  let filterDataValue = {};
  if (parsedKeys?.indexOf(dataKey)) {
    parsedKeys?.forEach((k) => {
      filterDataValue = { ...filterDataValue, [LocationNames[k]]: item[k + 'ID'] };
    });
  } else {
    return { [LocationNames[dataKey]]: item[dataKey + 'ID'] };
  }
  return filterDataValue;
};

export const getAppliedFilterKeyAndValue = ({ item, key }) => {
  let dataKey = item[`${key}Column`] || item.root || item.ID || key;
  let dataValue = item[`${key}ID`] || item[key];

  if (LocationCodes.includes(dataKey)) {
    let locationTree = {};
    LocationCodes.forEach((locationCodeKey) => {
      if (item[locationCodeKey]) {
        locationTree[locationCodeKey] = item[locationCodeKey];
      }
    });

    locationTree[dataKey] = dataValue;
    dataValue = locationTree;
  }

  return {
    dataKey: LocationNames[dataKey] || dataKey,
    dataValue: LocationNames[dataKey] ? openApiFilterDataValue(item, dataKey) : dataValue,
  };
};

export const buildIPFilterTree = ({ filters = [], exclude = [] }) => {
  let usedFilters = {};

  filters.forEach((item) => {
    for (let [key, isChecked] of Object.entries(item.checked)) {
      if (isChecked && !exclude.includes(key)) {
        const { dataKey, dataValue } = getAppliedFilterKeyAndValue({ key, item });

        if (!usedFilters[dataKey]) {
          usedFilters[dataKey] = [];
        }

        usedFilters[dataKey].push(dataValue);
      }
    }
  });

  return usedFilters;
};

export const buildFiltersQuery = async ({
  datasets = [],
  datasetID = [],
  appliedFilters = false,
  disableCommonDimmension = false,
  avoidTypes = [],
  exclude = [],
  commonColumns = [],
  sameAsDimensions = [],
  sameAsIndicaters = [],
  selectionRestrictation = false,
  otherFilters = [],
  allowedTypes = [],
  pickDimensions = [TYPE_DIMENSIONS, TYPE_LOCATION, TYPE_OTHERS, TYPE_TIME],
  conditionType = TYPE_CONDITION,
  downloadColumns = [],
}) => {
  pickDimensions = pickDimensions.filter((pick) => !avoidTypes.includes(pick));
  let columns = [];
  let columnsWithAs = [];
  let columnKeys = [];
  let ip_dimension = false;
  let ipFilters = [];
  let groupby = [];
  let dimAndIndIpColumns = {};
  let dimIpColumns = [];
  let indIpColumns = [];
  let formatCommonColumns = [];
  let orderByColumns = [];

  const ProcessIndicaterAndDimension = (item) => {
    if (item.AggregationFunction) {
      let { column } = getIndicaterColumn(item);
      columns.push(column);
      columnsWithAs.push(column);
      indIpColumns.push(column);
      columnKeys.push(column);
    } else {
      columns.push({ key: item.ID, name: item.DisplayName });
      columnsWithAs.push({ key: item.ID, name: item.DisplayName });
      dimIpColumns.push({ key: item.ID, name: item.DisplayName });
      columnKeys.push(item.ID);

      if (disableCommonDimmension) {
        groupby.push(`"${item.DisplayName}"`);
        orderByColumns.push({ key: item.ID, sort: 'asc' });
      }
    }
  };

  datasetID = sourceIDToNumberArray(datasetID);

  if (otherFilters.length) {
    otherFilters.forEach(ProcessIndicaterAndDimension);
  }

  if (appliedFilters) {
    let usedFilters = {};

    datasetID.forEach((id) => {
      let filters = appliedFilters[id];

      if (filters) {
        if (allowedTypes.length) {
          let slicedFilters = {};
          allowedTypes.forEach((allowed) => {
            slicedFilters[allowed] = filters[allowed];
          });

          filters = slicedFilters;
        }

        let indicaters = [];
        if (filters.indicators?.length) {
          indicaters = [...filters.indicators];
        }

        let indicaterSize = get(selectionRestrictation, 'indicaters.allow', false);

        if (indicaterSize) {
          indicaters = indicaters.slice(0, indicaterSize);
        }

        if (sameAsIndicaters.length) {
          sameAsIndicaters.forEach((type) => {
            let allFilters = (filters[type] || []).filter(
              (item) => item.checked && Object.values(item.checked).includes(true)
            );

            let size = get(selectionRestrictation, `${type}.allow`, false);

            if (size) {
              allFilters = allFilters.slice(0, size);
            }

            if (allFilters?.length) {
              indicaters = [...indicaters, ...allFilters];
            }
          });
        }

        if (indicaters.length && !avoidTypes.includes(TYPE_INDICATORS)) {
          indicaters.forEach(ProcessIndicaterAndDimension);
        }

        let granularity = get(filters, 'granularity[0].selected', false);

        if (ip_dimension !== granularity) {
          ip_dimension = granularity;
        }

        const filterTypes = [...pickDimensions, ...sameAsDimensions];

        filterTypes.forEach((type) => {
          let allFilters = (filters[type] || []).filter(
            (item) => item.checked && Object.values(item.checked).includes(true)
          );

          let size = get(selectionRestrictation, `${type}.allow`, false);

          if (size) {
            allFilters = allFilters.slice(0, size);
          }

          if (allFilters?.length) {
            usedFilters = { ...usedFilters, ...buildIPFilterTree({ filters: allFilters, exclude }) };
          }
        });

        let conditions = [...get(filters, `${conditionType}`, [])];
        conditions?.forEach((item) => {
          ipFilters.push({
            key: item[TYPE_COLUMN],
            value: Array.isArray(item[TYPE_VALUE]) ? item[TYPE_VALUE] : [item[TYPE_VALUE]],
            operator: item.condition,
            type: 'indicator',
          });
        });
      }
    });

    if (Object.keys(usedFilters).length) {
      for (let [dataKeys, dataValues] of Object.entries(usedFilters)) {
        let value = Array.isArray(dataValues) ? dataValues : [dataValues];

        ipFilters.push({
          key: dataKeys,
          value,
          operator: 'IN',
          type: 'dimension',
        });
      }
    }
  }

  if (datasets.length && !columns.length) {
    datasets.forEach((item) => {
      item?.sourcedetails?.Data?.forEach((source) => {
        source.Indicators?.Items.forEach((indicater) => {
          if (indicater.AggregationFunction) {
            ProcessIndicaterAndDimension(indicater);
          }
        });
      });
    });
  }

  if (!disableCommonDimmension) {
    let commonDimensions = await buildCommonDimension({ ip_dimension, datasetID });
    commonDimensions?.forEach((column) => {
      formatCommonColumns.push({ key: column, name: column });
    });
    commonDimensions?.forEach((column) => {
      orderByColumns.push({ key: column, sort: 'asc' });
    });
    columns = [...commonDimensions, ...columns];
    columnsWithAs = [...commonDimensions, ...columnsWithAs];
    columnKeys = [...commonDimensions, ...columnKeys];
    groupby = [...commonDimensions];
    dimIpColumns = [...formatCommonColumns];
  }

  if (commonColumns?.length) {
    commonColumns?.forEach((column) => {
      formatCommonColumns.push({ key: column, name: column });
    });

    commonColumns?.forEach((column) => {
      orderByColumns.push({ key: column, sort: 'asc' });
    });
    columns = [...commonColumns, ...columns];
    columnsWithAs = [...commonColumns, ...columnsWithAs];
    columnKeys = [...commonColumns, ...columnKeys];
    groupby = [...commonColumns];
    dimIpColumns = [...formatCommonColumns];
  }

  if (downloadColumns?.length) {
    downloadColumns.forEach(
      ({
        isIndicator,
        ID,
        aggregationFunction,
        weightedVariable,
        scalingFactor,
        name,
        display,
        indicator_dimension,
        ScalingFactor,
        Weighing_Variable,
        default_aggregation_location,
        DisplayName,
      }) => {
        const colObject = {
          key: ID,
          name: name || display || DisplayName,
        };

        if (isIndicator || indicator_dimension?.toLowerCase() === INDICATER_DIMENSION_I) {
          indIpColumns.push({
            ...colObject,
            agg: aggregationFunction || default_aggregation_location,
            wv: weightedVariable || (Weighing_Variable !== NOT_APPLICABLE ? Weighing_Variable : ''),
            sf: scalingFactor || ScalingFactor,
          });
        } else {
          dimIpColumns.push(colObject);
        }
      }
    );
  }

  if (indIpColumns?.length || dimIpColumns?.length) {
    dimAndIndIpColumns = { ind: uniqBy(indIpColumns, 'key'), dim: uniqBy(dimIpColumns, 'key') };
  }

  return {
    ip_columns: columns,
    ip_columns_with_as: dimAndIndIpColumns,
    column_keys: columnKeys,
    ip_filter: ipFilters,
    ip_groupby: groupby,
    ip_orderby: orderByColumns,
  };
};

const parseCommonDimensions = (commonDimensions) => {
  if (!commonDimensions) return [];
  let columns = [];

  if (commonDimensions?.data?.length) {
    commonDimensions.data.forEach((item) => {
      let columnData = item.ipcolumns_join || item.ipcolumns;

      if (typeof columnData === 'string') {
        columnData = columnData?.replaceAll('"', '')?.split(',');
      }

      if (columnData?.length) columns = [...columnData, ...columns];
    });
  }

  return columns;
};

export const buildCommonDimension = async ({ ip_dimension, datasetID }) => {
  let commonDimensions = false;

  if (!ip_dimension) {
    const { data: result } = await getCommonDimensions(datasetID);
    commonDimensions = result;
  } else {
    const { data: result } = await getCommonDimensionChange(ip_dimension);
    commonDimensions = result;
  }

  return parseCommonDimensions(commonDimensions);
};

export const getFixedFilters = (sourceID) => {
  return axios.post(`/v1/dataset/fixedfilters`, {
    ip_sourceid: sourceIDToNumberArray(sourceID),
  });
};

export const getDatasetMergePreview = async ({
  datasets = [],
  datasetID = [],
  appliedFilters = false,
  ip_limit = 100,
  page = 1,
  avoidTypes = [],
  disablePageAvoidList = false,
  mode = VIEW_MODE_PREVIEW,
  granularity = false,
  allowedTypes = [],
  timeFilter = false,
  applyFixedFilters = false,
}) => {
  let apiParams = {};
  datasetID = sourceIDToNumberArray(datasetID);
  if (datasetID.length < 1) return {};

  let commonColumns = parseCommonDimensions(granularity);
  let filteredGranularity = appliedFilters[datasetID[0]]?.[TYPE_GRANULARITY]?.[0]?.selected;

  if (!commonColumns?.length || filteredGranularity) {
    commonColumns = await buildCommonDimension({ ip_dimension: filteredGranularity, datasetID });
  }

  for (let index = 0; index < datasetID.length; index++) {
    let defaultParams = { ...getDatasetDefaultParams() };
    const sourceID = datasetID[index];
    const source = datasets[index];
    let builtQuery = await buildFiltersQuery({
      datasets: [source],
      datasetID: [sourceID],
      appliedFilters,
      disableCommonDimmension: true,
      avoidTypes,
      commonColumns,
      allowedTypes,
    });

    let columns = builtQuery.ip_columns_with_as;
    let ip_filter = builtQuery.ip_filter;

    if (timeFilter?.[sourceID]) {
      ip_filter.push({
        key: 'Year',
        operator: 'IN',
        type: 'dimension',
        value: [parseStringToNumber(timeFilter?.[sourceID])],
      });
    }

    if (applyFixedFilters) {
      const { data: { filters = [] } = {} } = await getFixedFilters(sourceID);

      if (filters.length) {
        ip_filter = [...ip_filter, ...filters];
      }
    }

    let params = {
      ip_sourceid: [sourceID],
      ip_orderby: builtQuery.ip_orderby,
    };

    if (columns.length || Object.keys(columns)?.length > 0) {
      params['ip_columns'] = columns;
    }

    if (ip_filter.length) {
      params['ip_filter'] = ip_filter;
    }

    params['ip_limit'] = ip_limit;
    params['ip_offset'] = (page - 1) * ip_limit;

    if (page > 1 && !disablePageAvoidList) {
      params['ip_sourcemaster'] = 0;
      params['ip_datasetprofile'] = 0;
      params['ip_datavariables'] = 0;
    }

    apiParams[`ds${index + 1}`] = { ...defaultParams, ...params };
  }

  return axios.post(`/v1/dataset/mergeview?mode=${mode}`, apiParams);
};

export const getDatasetDataWithFilters = async ({
  datasets = [],
  datasetID = [],
  appliedFilters = false,
  ip_limit = 100,
  page = 1,
  disablePageAvoidList = false,
  disableCommonDimmension = false,
  avoidTypes = [],
  view_name = false,
  onlyData = false,
  selectionRestrictation = false,
  sameAsIndicaters = [],
  otherFilters = [],
  ip_filter_params = [],
  allowedTypes = [],
  download = false,
  pickDimensions = false,
  conditionType = TYPE_CONDITION,
  ip_map = 0,
  sourceName = false,
  downloadColumns = [],
  offset = 0,
}) => {
  datasetID = sourceIDToNumberArray(datasetID);
  let params = {
    ip_sourceid: datasetID,
  };

  params['ip_limit'] = ip_limit;
  params['ip_offset'] = offset ? offset : (page - 1) * ip_limit;

  if (download) {
    params['ip_limit'] = 1000000000000;
    params['ip_offset'] = 0;
  }

  if ((page > 1 && !disablePageAvoidList) || onlyData) {
    params['ip_sourcemaster'] = 0;
    params['ip_datasetprofile'] = 0;
    params['ip_datavariables'] = 0;
  }

  if (view_name) {
    params['view_name'] = view_name;
  }

  let buildQueryParams = {
    datasets,
    datasetID,
    appliedFilters,
    disableCommonDimmension,
    avoidTypes,
    sameAsIndicaters,
    selectionRestrictation,
    otherFilters,
    allowedTypes,
    conditionType,
  };

  if (pickDimensions) {
    buildQueryParams['pickDimensions'] = pickDimensions;
  }

  let builtQuery = await buildFiltersQuery(buildQueryParams);

  let columns = builtQuery.ip_columns_with_as;
  let ip_filter = [...builtQuery.ip_filter, ...ip_filter_params];

  params['ip_columns'] = columns;

  if (ip_filter.length) {
    params['ip_filter'] = ip_filter;
  }

  if (builtQuery?.ip_orderby?.length) {
    params['ip_orderby'] = builtQuery.ip_orderby;
  }

  if (download) {
    if (sourceName) {
      params['dataset_name'] = sourceName;
    }

    let builtQueryDownload = await buildFiltersQuery({ downloadColumns });

    return getFilteredDatasetDownload({
      ...params,
      ...{ ip_columns: builtQueryDownload.ip_columns_with_as, ip_orderby: [] },
    });
  }

  params['ip_map'] = ip_map;
  return getDatasetData(params);
};

export const getFilteredDatasetDownload = (params = {}) => {
  const defaultParams = getDatasetDefaultParams();
  if (params.ip_sourceid) {
    params.ip_sourceid = sourceIDToNumberArray(params.ip_sourceid);
  }
  const url = `/v1/dataset/download`;
  return axios.post(url, { ...defaultParams, ...params });
};

export const getSingleOrMergeDownloadTable = async ({
  datasets = [],
  datasetID = [],
  appliedFilters = {},
  user,
  view_name,
  ip_filter_params,
  sourceName = false,
}) => {
  let builtQuery = await buildFiltersQuery({ datasets, datasetID, appliedFilters });
  let columns = builtQuery.ip_columns_with_as;
  let ip_filter = [...builtQuery.ip_filter, ...ip_filter_params];

  datasetID = sourceIDToNumberArray(datasetID);
  let params = {
    ip_sourceid: `'${datasetID.join(',')}'`,
  };

  if (columns.length) {
    params['ip_columns'] = uniq(columns);
  }

  if (ip_filter.length) {
    params['ip_filter'] = `'${ip_filter.map((d) => replaceAll(d, "''", "'")).join(' and ')}'`;
  }

  if (builtQuery?.ip_groupby?.length) {
    params['ip_orderby'] = `'${builtQuery.ip_groupby.slice(0, 1).join("','")}'`;
    params['ip_groupby'] = builtQuery.ip_groupby;
  }

  if (user) {
    params['comment_'] = `'${user}'`;
  }

  if (view_name) {
    params['view_name'] = view_name;
  }

  if (sourceName) {
    params['dataset_name'] = sourceName;
  }

  return getFilteredDatasetDownload({ ...params, ...{ ip_columns: {}, ip_orderby: [] } });
};

export const getShareBookmarkString = async ({ datasets = [], datasetID = [], appliedFilters = {}, user }) => {
  const defaultParams = {
    ip_sourceid: "'*'",
    ip_state: "'*'",
    ip_district: "'*'",
    ip_block: "'*'",
    ip_columns: "'*'",
    ip_filter: "'*'",
    ip_orderby: "'*'",
    ip_joincolumns: "'*'",
    ip_sourcemaster: '1',
    ip_datavariables: '1',
    ip_datasetprofile: '1',
    ip_limit: '10000',
    ip_offset: '0',
    comment_: `'${ANONYMOUS_USER}'`,
  };

  let builtQuery = await buildFiltersQuery({ datasets, datasetID, appliedFilters });
  let columns = builtQuery.ip_columns;
  let ip_filter = builtQuery.ip_filter;

  datasetID = sourceIDToNumberArray(datasetID);

  let params = {
    ip_sourceid: `'${datasetID.join(',')}'`,
  };

  if (columns.length) {
    params['ip_columns'] = `'${builtQuery.ip_columns.join(',')}'`;
  }

  if (ip_filter.length) {
    params['ip_filter'] = `'${ip_filter.join(' and ')}'`;
  }

  if (user) params['comment_'] = `'${user}'`;

  return { ...defaultParams, ...params };
};

const getIndicaterColumn = (item) => {
  if (!item.AggregationFunction) {
    item.AggregationFunction = 'sum';
  }

  let name = `${STANDERED_FUNCTIONS[item.AggregationFunction]} of ${item.Description}`.trim().toLowerCase();
  let column = { key: item.ID, agg: item.AggregationFunction, wv: '', name: name, sf: item.ScalingFactor || 1 };
  let lowerAgFuction = (item.AggregationFunction || '').toLowerCase();
  let isWeighted = Object.keys(WEIGHTED_AVG).find((i) => WEIGHTED_AVG[i].toLowerCase() === lowerAgFuction);

  if (isWeighted) {
    item.AggregationFunction = isWeighted;
  }

  if (WEIGHTED_AVG[item.AggregationFunction] || isWeighted) {
    name = `${WEIGHTED_AVG[item.AggregationFunction]} ( ${item.Description} )`.trim().toLowerCase();
    column = { key: item.ID, agg: 'wavg', wv: item.AggregationFunction, name: name, sf: item.ScalingFactor || 1 };
  }

  return {
    name,
    column,
    item,
  };
};

export const getVisualizeGraph = async ({
  appliedFilters = {},
  type = 'bar',
  datasetName = '',
  datasetID = '6000',
  year = '',
  aggregateOverTime = false,
  filterSetting = {},
  selectedPallet = BLUEBROWN,
  isFinancialYear = false,
  sort = {},
  view_name = '',
}) => {
  const sourceID = sourceIDToNumberArray(datasetID);
  const defaultParams = {
    source: sourceID,
    ip_columns: {},
    filters: [],
    having: "'*'",
    orderby: [],
    color_theme: `${selectedPallet}`,
    limit: 1000000000,
    offset: 0,
    x_axis: [],
    y_axis: [],
    title: ``,
    dataset_name: datasetName,
    y_axis_units: [],
  };

  let validationError = { validationError: true };
  if (view_name) {
    defaultParams['view_name'] = view_name;
    aggregateOverTime = true;
  }

  let params = {};
  let filters = appliedFilters[sourceID[0]];

  if (!filters) return validationError;

  let indicators = filters[TYPE_INDICATORS_CHART];
  let allDimensions = filters[TYPE_ALL_DIMENSIONS];
  let bubbleNames = filters[TYPE_BUBBLE_NAME];
  let xIndicaters = filters[TYPE_X_INDICATER];
  let yIndicater = filters[TYPE_Y_INDICATER];
  let bubbleSizes = filters[TYPE_BUBBLE_SIZE];
  let pivotColumns = filters[TYPE_PIVOT_COLUMNS];
  let zAxisDimension = [];
  let allowedZAxis = false;
  let xAxis = [];
  let groupbyXAxis = [];
  let indicatorQuery = [];
  let yAxis = [];
  let yAxisUnits = [];
  let isBubble = type === 'bubble';
  let isScatter = type === 'scatter';
  let isPivot = type === 'pivot';
  let dataColumns = [];
  let dimColumns = [];
  let dimAndIndColumns = {};

  if (isBubble || isScatter) {
    if (isBubble) {
      defaultParams['z_axis'] = [];
      defaultParams['z_axis_units'] = [];
    }

    allDimensions = [];

    defaultParams['x_axis_units'] = [];

    if (!bubbleNames.length || !xIndicaters.length || !yIndicater.length || (isBubble && !bubbleSizes.length)) {
      return validationError;
    }

    let allowedBubbleName = get(filterSetting, 'ChartDimensions-0.allow', false);
    let allowedXIndicaters = get(filterSetting, 'ChartIndicators-0.allow', false);
    let allowedYIndicaters = get(filterSetting, 'ChartIndicators-1.allow', false);
    let allowedyBubbleSizes = get(filterSetting, 'ChartIndicators-2.allow', false);

    if (allowedBubbleName) {
      bubbleNames = bubbleNames.slice(0, allowedBubbleName);
    }

    if (allowedXIndicaters) {
      xIndicaters = xIndicaters.slice(0, allowedXIndicaters);
    }

    if (allowedYIndicaters) {
      yIndicater = yIndicater.slice(0, allowedYIndicaters);
    }

    if (allowedyBubbleSizes) {
      bubbleSizes = bubbleSizes.slice(0, allowedyBubbleSizes);
    }

    indicators = yIndicater;

    params['dimension'] = [];

    bubbleNames.forEach((item) => {
      dataColumns.push({
        name: item.DisplayName,
        icon: item.DimensionType || item.dimension_type,
        colorIndicator: item.colorIndicator,
      });
      params['dimension'].push(item.DisplayName);
      groupbyXAxis.push(`"${item.DisplayName}"`);
      dimColumns.push({ key: item.ID, name: item.DisplayName });
    });

    xIndicaters.forEach((item) => {
      let { name, column } = getIndicaterColumn(item);
      indicatorQuery.push(column);
      xAxis.push(name);
      defaultParams.x_axis_units.push(item.UnitsofMeaseure);
      dataColumns.push({
        name,
        icon: TYPE_INDICATORS,
        colorIndicator: item.colorIndicator,
      });
    });
  } else {
    if (!indicators?.length || !allDimensions?.length) return validationError;
  }

  let allowedIndicaters = get(filterSetting, 'ChartIndicators.allow', false);
  let allowedDimensions = get(filterSetting, 'ChartDimensions.allow', false);

  if (allowedIndicaters) {
    indicators = indicators.slice(0, allowedIndicaters);
  }

  if (['line'].includes(type)) {
    defaultParams['z_axis'] = [];
    defaultParams['is_financialyear'] = isFinancialYear;

    if (indicators.length === 1) {
      allowedZAxis = get(filterSetting, 'ChartDimensions-1.allow', false);
      zAxisDimension = filters[TYPE_Z_AXSIS_DIMENSIONS];
    }
  }

  if (zAxisDimension?.length) {
    if (allowedZAxis) {
      zAxisDimension = zAxisDimension?.slice(0, allowedZAxis);
    }

    zAxisDimension?.forEach((item) => {
      groupbyXAxis.push(`"${item.DisplayName}"`);
      defaultParams['z_axis'].push(item.DisplayName);
      dimColumns.push({ key: item.ID, name: item.DisplayName });
      dataColumns.push({
        name: item.DisplayName,
        icon: item.DimensionType || item.dimension_type,
        colorIndicator: item.colorIndicator,
      });
    });
  }

  let avoidVisualTypes = [TYPE_INDICATORS];
  let excludeTypes = [];

  if (view_name) {
    avoidVisualTypes.push(TYPE_DIMENSIONS);
  }

  if (!aggregateOverTime || view_name) {
    avoidVisualTypes.push(TYPE_TIME);
    excludeTypes.push(TYPE_YEAR);
  }

  let builtQuery = await buildFiltersQuery({
    datasetID: [sourceID[0]],
    appliedFilters,
    disableCommonDimmension: true,
    exclude: excludeTypes,
    avoidTypes: avoidVisualTypes,
    conditionType: TYPE_CONDITION_VISUALIZATION,
  });

  let ip_filter = builtQuery.ip_filter;

  if (!aggregateOverTime && year) {
    ip_filter.push({
      key: 'Year',
      value: Array.isArray(year) ? year : [parseStringToNumber(year)],
      operator: 'IN',
      type: 'dimension',
    });
  }

  if (ip_filter.length) {
    params['filters'] = ip_filter;
  }

  if (allDimensions?.length) {
    if (allowedDimensions) {
      allDimensions = allDimensions?.slice(0, allowedDimensions);
    }

    if (isPivot) {
      let pivotColumnsAllowed = get(filterSetting, 'ChartDimensions-0.allow', false);

      if (pivotColumnsAllowed) {
        pivotColumns = pivotColumns?.slice(0, pivotColumnsAllowed);
      }

      if (pivotColumns?.length) {
        allDimensions = [...allDimensions, ...pivotColumns];
      }
    }

    allDimensions?.forEach((item) => {
      xAxis.push(item.DisplayName);
      groupbyXAxis.push(`"${item.DisplayName}"`);
      dimColumns.push({ key: item.ID, name: item.DisplayName });
      dataColumns.push({
        name: item.DisplayName,
        icon: item.DimensionType || item.dimension_type,
        colorIndicator: item.colorIndicator,
      });
    });
  }

  params['orderby'] = [{ key: VISUALISATIONS_ORDER_BY[sort.ID] || sort.ID, sort: sort.mode }];
  params['x_axis'] = xAxis;

  indicators.forEach((item) => {
    let { name, column } = getIndicaterColumn(item);

    indicatorQuery.push(column);
    yAxis.push(name);
    yAxisUnits.push(item.UnitsofMeaseure);
    dataColumns.push({
      name,
      icon: TYPE_INDICATORS,
      colorIndicator: item.colorIndicator,
    });
  });

  if (isBubble) {
    bubbleSizes.forEach((item) => {
      let { name, column } = getIndicaterColumn(item);
      indicatorQuery.push(column);
      defaultParams['z_axis'].push(name);
      defaultParams['z_axis_units'].push(item.UnitsofMeaseure);
      dataColumns.push({
        name,
        icon: TYPE_INDICATORS,
        colorIndicator: item.colorIndicator,
      });
    });
  }

  dimAndIndColumns = { ind: indicatorQuery, dim: dimColumns };
  params['ip_columns'] = dimAndIndColumns;
  params['y_axis'] = yAxis;
  params['y_axis_units'] = yAxisUnits;

  let allParams = { ...defaultParams, ...params };
  const { data } = await axios.post(`/v1/viz/${type}`, allParams);

  return {
    ...data,
    dataColumns,
  };
};

export const sourceIDToNumberArray = (sourceID) => {
  if (!sourceID) {
    return [];
  }

  const splitSources = (sID) =>
    String(sID)
      .split(',')
      .map((id) => +id)
      .filter((id) => Boolean(id));

  if (Array.isArray(sourceID)) {
    let sources = [];

    sourceID.forEach((ID) => {
      sources = [...sources, ...splitSources(ID)];
    });

    return sources;
  }

  return splitSources(sourceID);
};

export const getSourceDetails = async ({ sourceID }) => {
  const { data } = await axios.post(`/v1/sourcedetails`, {
    ip_sourceid: sourceIDToNumberArray(sourceID),
  });

  return data;
};

export const getOpenApiURL = async ({ sourceID, appliedFilters = {}, API_Key, dimensions = [] }) => {
  const sources = sourceIDToNumberArray(sourceID);
  const { ip_filter, ip_columns_with_as: ip_columns } = await buildFiltersQuery({
    datasetID: sources,
    appliedFilters,
    disableCommonDimmension: true,
    allowedTypes: OPEN_API_TYPES,
    sameAsIndicaters: [TYPE_OPEN_API_INDICATORS],
    sameAsDimensions: [TYPE_OPEN_API_LOCATION],
  });

  dimensions.forEach((item) => {
    ip_columns.dim.push({
      key: item.ID,
      name: item.DisplayName,
    });
  });

  const { data } = await axios.post(`/v1/openapi/getdetails`, {
    ip_sourceid: sources,
    ip_filter,
    ip_columns,
    API_Key,
  });

  return data;
};

export const getSourceArray = (sourceID) => sourceIDToNumberArray(sourceID);

export const getAllStatesWithDistrict = async ({ sourceID, granularity, view_name }) => {
  const params = {
    ip_sourceid: sourceIDToNumberArray(sourceID),
  };

  if (granularity) {
    params['granularity'] = GRANULARITIES[granularity.toLowerCase()] || granularity;
  }

  if (view_name) {
    params['view_name'] = view_name;
  }

  const { data } = await axios.post('/v1/filters/initial', params);
  return data;
};

export const getGeoLocations = async ({ sourceID, page = 1, type, state, district, subdistrict, view_name = '' }) => {
  const params = {
    ip_sourceid: sourceIDToNumberArray(sourceID),
    ip_location: type,
    ip_page: page,
    ip_state: state,
    ip_district: district,
    ip_subdistrict: subdistrict,
    view_name,
  };

  const { data } = await axios.post('/v1/filters/sub', params);

  return data;
};

export const getLocationFilterSearch = ({ granularity, ...params }) => {
  const url = `v1/filters/search`;
  params.ip_sourceid = sourceIDToNumberArray(params.ip_sourceid);

  if (granularity) {
    params['granularity'] = GRANULARITIES[granularity.toLowerCase()] || granularity;
  }

  return axios.post(url, params);
};

export const getLocationFilterSearchIntial = (params = {}) => {
  const url = `v1/filters/search/results`;
  params.ip_sourceid = sourceIDToNumberArray(params.ip_sourceid);

  return axios.post(url, params);
};

export const getSelectedLocationCount = ({ sourceID = [], appliedFilters = [], granularity = false }) => {
  if (!sourceID || !appliedFilters?.length) return invalidAxiosRejection();

  let locations = {
    [Country]: [],
    [StateName]: [],
    [DistrictName]: [],
    [SubDistrictName]: [],
    [BlockName]: [],
    [VillageTownName]: [],
  };

  appliedFilters.forEach((item) => {
    if (item?.checked) {
      for (const key in item.checked) {
        if (locations[key] && item.checked[key]) {
          const { dataValue } = getAppliedFilterKeyAndValue({ item, key });
          locations[key].push(dataValue);
        }
      }
    }
  });

  let params = {
    ip_sourceids: sourceIDToNumberArray(sourceID),
    ip_states: locations[StateName],
    ip_districts: locations[DistrictName],
    ip_subdistricts: locations[SubDistrictName],
    ip_blocks: locations[BlockName],
    ip_villages: locations[VillageTownName],
  };

  if (granularity) {
    params['granularity'] = GRANULARITIES[granularity.toLowerCase()] || granularity;
  }

  return axios.post('v1/filters/count', params);
};

export const getDatasetBasicDetails = (params = {}) => {
  return axios.post('v1/dataset/basicdetails', params);
};
