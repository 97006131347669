import { useContext, createContext, useState, useMemo } from 'react';
import { get, sum, set, isEqual, cloneDeep, groupBy, isEmpty, orderBy, unionBy, uniqBy } from 'lodash';
import {
  FILTER_TYPES,
  RULE_ONLY,
  RULE_OTHER_THAN,
  RULE_RESTRICTED_UPTO,
  TYPE_CONDITION_VISUALIZATION,
  TYPE_EXTRAS,
  TYPE_GRANULARITY,
  TYPE_YEAR,
  VISUALIZE_TYPE_INDICATORS,
} from '../constants';
import { cleanName, getSourceID, uniqID } from '../utils/common';
import { getShareBookmarkString } from '../services';
import { LocationCodes } from '../shared/LocationFilter/constant';

const appliedFilterProperties = [];

const filterStateProperties = {
  show: false,
  data: [],
  active: {},
  cache: {},
  checked: [],
  quickPaths: {},
};

const InitFilter = (ps, sourceID, initObject = filterStateProperties) => {
  const filters = { ...ps };

  if (!filters[sourceID]) {
    filters[sourceID] = {};
    FILTER_TYPES.forEach((TYPE) => {
      filters[sourceID][TYPE] = cloneDeep(initObject);
    });
  }

  return filters;
};

const ClearFilterObject = (currentState = {}) => {
  let clearedObject = {};

  Object.keys(currentState).forEach((key) => {
    clearedObject[key] = { ...currentState[key] };
    FILTER_TYPES.forEach((TYPE) => {
      if (clearedObject[key]?.[TYPE]?.show) {
        clearedObject[key][TYPE].show = false;
      }
    });
  });

  return clearedObject;
};

const getOccurrence = (item, value) => {
  const loopAndCheck = (item, value) => {
    if (item.hasOwnProperty('isOutOfLimit')) return uniqBy(Object.values(item).filter((v) => v === value)).length;
    else return Object.values(item).filter((v) => v === value).length;
  };

  if (!item) return 0;

  if (typeof item !== 'object') return item;

  if (Array.isArray(item)) {
    return sum(item.map((d) => loopAndCheck(d, value)));
  }

  return loopAndCheck(item, value);
};

const GetCount = (appliedFilter) => {
  const length = appliedFilter.length;

  return length > 1
    ? appliedFilter.reduce((total, item) => {
        return getOccurrence(total.data || total, true) + getOccurrence(item.data || item, true);
      })
    : getOccurrence(appliedFilter[0]?.data || appliedFilter[0], true);
};

const getSources = ({ sourceID, ...rest }, callback = false) => {
  const sources = Array.isArray(sourceID) ? sourceID : String(sourceID).split(',');

  if (typeof callback === 'function') {
    sources.forEach((ID, index) => {
      callback({ ID, ...rest, index });
    });
  }

  return sources;
};

const parseCheckedData = ({ psQuickies = {}, data = [], targetKey = 'ID', root = '', cleanNames = false, ...rest }) => {
  let paths = {};
  let levels = {};
  let psQuickPaths = psQuickies;

  data?.length &&
    data.forEach((item) => {
      if (item?.checked) {
        for (let [key, isChecked] of Object.entries(item.checked)) {
          if (isChecked) {
            let value = `${item[key + 'ID'] || item[targetKey] || item[key]}`;

            if (cleanNames) {
              value = cleanName(value);
            }

            if (!levels[key]) {
              Object.keys(item.checked).forEach((lKey, index) => {
                if (!levels[lKey]) levels[lKey] = index + 1;
              });
            }

            let level = levels[key];

            if (!paths[key]) {
              paths[key] = { selected: {}, level, key };
            }

            let rootID = item.root;

            if (rootID) {
              if (!paths[key]['selected'][rootID]) {
                if (!Object.keys(paths[key]['selected']).length) {
                  let psChecked = get(psQuickPaths, `[${key}]['selected']`) || {};
                  paths[key]['selected'] = { ...psChecked };
                }

                if (root) paths[key]['selected'][root] = {};
                if (!paths[key]['selected'][rootID]) paths[key]['selected'][rootID] = {};
              }

              paths[key]['selected'][rootID][value] = isChecked;
              paths[key]['selected'][rootID][`${value}_order`] = item.order || 0;
            } else {
              paths[key]['selected'][value] = isChecked;
              paths[key]['selected'][`${value}_order`] = item.order || 0;
            }
          }
        }
      }
    });
  let sortedPaths = {};
  (orderBy(paths, ['level', 'asc']) || []).forEach((item) => (sortedPaths[item.key] = item));

  return {
    checked: data,
    quickPaths: sortedPaths,
    ...rest,
  };
};

export const FilterContext = createContext({});
export const AppliedFilterContext = createContext({});

export const getFromLocalstorage = (key) => JSON.parse(localStorage.getItem(key) || '{}');

const AppliedFilterProvier = (props) => {
  const [updateInfo, setUpdateInfo] = useState({});
  const [appliedFilters, setAppliedState] = useState({});
  const [paths, setPath] = useState({});
  const [selectedCount, setSelectedCount] = useState({});

  const destroyAppliedFilters = () => {
    setAppliedState({});
    setPath({});
    setSelectedCount({});
  };

  const importAppliedFilters = ({ filters = {}, quickPaths = {}, selectedCounts: counts = {} }) => {
    let allFilters = Object.entries(filters);
    let shipFilters = {};
    let isCountsEmpty = isEmpty(counts);
    for (let [sourceID, list] of allFilters) {
      shipFilters[sourceID] = { ...InitFilter({}, sourceID, appliedFilterProperties)[sourceID], ...list };

      if (isCountsEmpty) {
        let sourceEntries = Object.entries(shipFilters[sourceID]);
        for (let [type, items] of sourceEntries) {
          if (!counts[sourceID]) {
            counts[sourceID] = {};
          }

          counts[sourceID][type] = items.reduce(
            (total, item) => total + Object.values(item.checked || {}).filter((t) => t).length,
            0
          );
        }
      }
    }

    setAppliedState((ps) => Object.assign({}, ps, shipFilters));
    setPath((ps) => ({ ...ps, ...quickPaths }));
    setSelectedCount((ps) => ({ ...ps, ...counts }));
  };

  const exportAppliedFilters = (datasetID, user, types = false, params = '') => {
    let datasetIDList = typeof datasetID === 'string' ? datasetID.split(',') : datasetID;
    let filters = {};
    let quickPaths = {};
    let counts = {};

    datasetIDList.forEach((ID) => {
      if (appliedFilters[ID]) {
        filters[ID] = {};

        if (paths[ID]) {
          quickPaths[ID] = cloneDeep(paths[ID]);
        }

        if (selectedCount[ID]) {
          counts[ID] = cloneDeep(selectedCount[ID]);
        }

        let entries = Object.entries(appliedFilters[ID]);
        for (let [key, list] of entries) {
          let skip = false;
          if (types && !types.includes(key)) {
            skip = true;
          }

          if (list?.length && !skip) {
            if (!filters[ID][key]) {
              filters[ID][key] = [];
            }

            list.forEach((item) => {
              if (item.checked) {
                let selectedObject = false;
                let checkedEntries = Object.entries(item.checked);

                for (let [column, isChecked] of checkedEntries) {
                  if (isChecked) {
                    let columnChecked = column + 'Checked';
                    let columnCode = column + 'Column';
                    let columnID = column + 'ID';

                    if (!selectedObject) {
                      selectedObject = {};
                    }

                    if (!selectedObject['checked']) {
                      selectedObject['checked'] = {};
                    }

                    selectedObject['checked'][column] = true;

                    const exportableColumns = [
                      column,
                      columnChecked,
                      columnCode,
                      columnID,
                      'root',
                      'ID',
                      'AggregationFunction',
                      'UnitsofMeaseure',
                      'DimensionType',
                      'colorIndicator',
                      'DisplayName',
                      'Description',
                      ...LocationCodes,
                    ];

                    for (let index = 0; index < exportableColumns.length; index++) {
                      let exColumn = exportableColumns[index];

                      if (item[exColumn]) {
                        selectedObject[exColumn] = item[exColumn];
                      }
                    }
                  }
                }

                if (selectedObject) {
                  filters[ID][key].push(selectedObject);
                }
              } else if (item.skip) {
                filters[ID][key].push(item);
              }
            });
          }
        }
      }
    });

    return {
      datasets: datasetIDList.join(','),
      filters,
      quickPaths,
      selectedCounts: counts,
      payload: getShareBookmarkString({
        datasetID: datasetIDList,
        appliedFilters,
        user,
      }),
      params,
    };
  };

  const setAppliedFilter = ({ sourceID, type, appliedFilter = [], quickPaths = {} }) => {
    getSources({ sourceID }, ({ ID }) => {
      const state = InitFilter(appliedFilters, ID, appliedFilterProperties);

      if (!isEqual(state[ID]?.[type] || {}, appliedFilter)) {
        setUpdateInfo({ [sourceID]: type });
        setAppliedState((ps) => {
          let state = set(InitFilter(ps, ID, appliedFilterProperties), `[${ID}].[${type}]`, appliedFilter);

          setSelectedCount((ps) => {
            let countState = {
              ...ps,
              [ID]: {
                ...(ps[ID] || {}),
                [type]: GetCount(getSelectedFilters({ sourceID, type, state })),
              },
            };

            return countState;
          });

          return state;
        });

        setPath((ps) => {
          let state = {
            ...ps,
            [ID]: {
              [type]: quickPaths,
            },
          };

          return state;
        });
      }
    });
  };

  const getSelectedFilters = ({ sourceID, type, includeOnly = [], state = false }) => {
    const source = (state || appliedFilters)[getSourceID(sourceID)];
    if (!source) {
      return [];
    }

    if (!type) {
      let selectFilters = [];
      let testInclude = includeOnly.length > 0;

      Object.entries(source).forEach(([key, value]) => {
        let add = true;

        if (testInclude) {
          add = includeOnly.includes(key);
        }

        if (add && value.length) {
          selectFilters.push({
            data: value,
            filterType: key,
          });
        }
      });

      return cloneDeep(selectFilters);
    }

    return cloneDeep(source?.[type] || []);
  };

  const getSelectedCount = ({ sourceID, type, includeOnly = [], oneLevel = false }) => {
    if (oneLevel) {
      sourceID = getSourceID(sourceID);
    }

    return getSources({ sourceID })
      .map((ID) => {
        let counts = selectedCount[ID] || {};
        let appliedFilterCount = 0;

        if (!type && includeOnly.length) {
          includeOnly.forEach((filterType) => {
            appliedFilterCount += counts[filterType] || 0;
          });

          return appliedFilterCount;
        }

        if (!type) {
          return Object.values(counts).reduce((total, value) => total + value, 0);
        }

        return counts[type] || 0;
      })
      .reduce((total, value) => total + value, 0);
  };

  const resetAllFilters = ({ sourceID, types = [] }) => {
    const selectedCounts = getSelectedCount({ sourceID, includeOnly: types });

    if (!selectedCounts) return true;

    setAppliedState((state) => {
      let ps = cloneDeep(state);
      let counts = cloneDeep(selectedCount);

      getSources({ sourceID }).forEach((ID) => {
        if (types.length) {
          let data = Object.keys(ps[ID] || {});
          data.forEach((key) => {
            if (types.includes(key)) {
              ps[ID][key] = cloneDeep(appliedFilterProperties);

              if (counts[ID]?.[key]) counts[ID][key] = 0;
            }
          });
        } else if (ps[ID]) {
          ps[ID] = InitFilter(ps, ID, appliedFilterProperties);

          if (counts[ID]) {
            Object.keys(counts[ID]).forEach((key) => (counts[ID][key] = 0));
          }
        }
      });

      setSelectedCount(counts);
      return ps;
    });
  };

  const changeRoot = (root, type = 'filter') => {
    let rootChanger = {
      filter: () => setAppliedState(root),
      count: () => setSelectedCount(root),
      path: () => setPath(root),
    };

    rootChanger[type]?.();
  };

  return (
    <AppliedFilterContext.Provider
      value={{
        appliedFilters,
        paths,
        selectedCount,
        setAppliedFilter,
        getSelectedCount,
        getSelectedFilters,
        resetAllFilters,
        exportAppliedFilters,
        importAppliedFilters,
        changeRoot,
        destroyAppliedFilters,
        updateInfo,
      }}
    >
      {props.children}
    </AppliedFilterContext.Provider>
  );
};

const FilterContextProvider = (props) => {
  const [filterState, setFilterState] = useState({});

  const destroyFilters = () => setFilterState({});

  const setAny = useMemo(
    () =>
      ({ sourceID, type, data = {} }) => {
        getSources({ sourceID }, ({ ID }) => {
          setFilterState((ps) => {
            const currentState = InitFilter(ps, ID);
            let newState = set(currentState, `[${ID}][${type}]`, {
              ...currentState[ID][type],
              ...data,
            });

            return newState;
          });
        });
      },
    []
  );

  const importChecked = (filters = {}, quickPaths = {}) => {
    const filtered = { ...filters };
    let checkedFilters = {};
    const entries = Object.entries(filtered);

    for (let [sourceID, filterList] of entries) {
      const allFilters = Object.entries(filterList);

      if (!checkedFilters[sourceID]) {
        checkedFilters[sourceID] = InitFilter({}, sourceID)[sourceID];
      }

      for (let [type, data] of allFilters) {
        let quickie = quickPaths?.[sourceID]?.[type];
        checkedFilters[sourceID][type] = {
          ...filtered[sourceID][type],
          ...(quickie
            ? { checked: data, quickPaths: quickie }
            : parseCheckedData({ data, psQuickies: quickPaths?.[sourceID]?.[type] })),
        };
      }
    }

    setFilterState((ps) => ({
      ...ps,
      ...checkedFilters,
    }));
  };

  const setChecked = ({ sourceID, type, data = [], targetKey = 'ID', root = '', cleanNames = false, ...rest }) => {
    const psQuickies = getAny({ sourceID, type, key: 'quickPaths' });

    let params = {
      sourceID,
      type,
      data: parseCheckedData({
        psQuickies,
        data,
        targetKey,
        root,
        cleanNames,
        ...rest,
      }),
    };

    setAny(params);
    return params;
  };

  const addToCache = useMemo(
    () =>
      ({ sourceID, type, key = '_', data = [] }) => {
        getSources({ sourceID }, ({ ID }) => {
          setFilterState((ps) => {
            return set(InitFilter(ps, ID), `[${ID}][${type}].cache[${key}]`, data);
          });
        });
      },
    []
  );

  const getAny = ({ sourceID, type, key = 'show', empty = [] }) => {
    const ID = getSourceID(sourceID);
    return cloneDeep(get(filterState, `${ID}.${type}.${key}`, empty));
  };

  const setFilter = ({ sourceID, type, show = true }) => {
    getSources({ sourceID }, ({ ID, index }) => {
      setFilterState((ps) => {
        let currentState = InitFilter(ps, ID);
        if (index === 0) {
          currentState = ClearFilterObject(currentState);
        }

        set(currentState, `[${ID}][${type}][show]`, show);
        return currentState;
      });
    });
  };

  const clearFilters = () => {
    setFilterState((ps) => {
      return ClearFilterObject(ps);
    });
  };

  const isFilterShown = ({ sourceID }) =>
    Object.values(filterState[getSourceID(sourceID)] || {}).findIndex(({ show }) => show) !== -1;

  const showFilter = ({ sourceID, type }) => filterState[getSourceID(sourceID)]?.[type]?.show || false;
  const getData = ({ sourceID, type, key }) => filterState[getSourceID(sourceID)]?.[type]?.data?.[key] || false;

  const resetAllSelectedFilters = ({ sourceID, types = [] }) => {
    setFilterState((state) => {
      let ps = cloneDeep(state);

      getSources({ sourceID }).forEach((ID) => {
        if (types.length) {
          let data = Object.keys(ps[ID] || {});
          data.forEach((key) => {
            if (types.includes(key)) {
              ps[ID][key] = cloneDeep(filterStateProperties);
            }
          });
        } else if (ps[ID]) {
          ps[ID] = InitFilter(ps, ID);
        }
      });

      return ps;
    });
  };

  return (
    <FilterContext.Provider
      value={{
        filters: filterState,
        setFilter,
        clearFilters,
        showFilter,
        getData,
        setAny,
        getAny,
        addToCache,
        setChecked,
        resetAllSelectedFilters,
        importChecked,
        isFilterShown,
        destroyFilters,
      }}
    >
      <AppliedFilterProvier>{props.children}</AppliedFilterProvier>
    </FilterContext.Provider>
  );
};

export const useFilterActions = ({ sourceID, type } = {}) => {
  const { setChecked, getAny, setAny, resetAllSelectedFilters, clearFilters, filters, destroyFilters } =
    useFilterContext();
  const {
    setAppliedFilter,
    getSelectedFilters,
    exportAppliedFilters,
    appliedFilters,
    paths,
    selectedCount,
    changeRoot,
    getSelectedCount,
    resetAllFilters,
    destroyAppliedFilters,
  } = useAppliedFilterContext();

  const destroy = () => {
    destroyFilters();
    destroyAppliedFilters();
  };

  const resetFilters = (types = [...FILTER_TYPES], ID = false) => {
    resetAllFilters({ sourceID: ID || sourceID, types });
    resetAllSelectedFilters({ sourceID: ID || sourceID, types });
  };

  const addOrReplaceExtras = (item, replaceItem = true) => {
    const extras = getAny({ sourceID, type: TYPE_EXTRAS, key: 'extras' });

    if (extras.length) {
      const index = extras.findIndex((eItem) => item.ID === eItem.ID);
      const currentSelected = getSelectedFilters({
        sourceID,
        type: item.chartType,
      });

      const extraItem = cloneDeep(extras[index]);

      if (replaceItem) {
        let removedItem = currentSelected.shift();
        currentSelected.unshift(extraItem);
        removeAppliedCondition(removedItem);

        extras[index] = removedItem;
        extras[index]['reason'] = extraItem.reason;
        extras[index]['reasonValue'] = extraItem.reasonValue;
        extras[index]['chartTitle'] = extraItem.chartTitle;
        extras[index]['chartType'] = extraItem.chartType;
      } else {
        currentSelected.push(extraItem);
        extras.splice(index, 1);
      }

      setAny({
        sourceID,
        type: TYPE_EXTRAS,
        data: {
          extras,
        },
      });

      let { data: { quickPaths = {} } = {} } = setChecked({
        sourceID,
        type: item.chartType,
        data: currentSelected,
      });

      setAppliedFilter({
        sourceID,
        type: item.chartType,
        appliedFilter: currentSelected,
        quickPaths,
      });
    }
  };

  const removeExtras = () => {
    setAny({
      sourceID,
      type: TYPE_EXTRAS,
      data: {
        extras: {},
        from: {},
        to: {},
      },
    });
  };

  const getExtras = () => {
    let extras = getAny({ sourceID, type: TYPE_EXTRAS, key: 'extras' });
    let groupedBy = groupBy(extras, 'filterType');
    let entries = Object.entries(groupedBy);

    for (let [key, data] of entries) {
      groupedBy[key] = groupBy(data, 'chartTitle');
    }

    return {
      extras: getAny({ sourceID, type: TYPE_EXTRAS, key: 'extras' }),
      from: getAny({ sourceID, type: TYPE_EXTRAS, key: 'from' }),
      to: getAny({ sourceID, type: TYPE_EXTRAS, key: 'to' }),
      groupedBy,
    };
  };

  const switchVisualizationFilters = ({ previousConfig = [], currentConfig = [], chart = {}, selected = {} }) => {
    if (previousConfig?.length) {
      let applyingFilters = [];
      let savedFilters = {};

      let userFilters = getSelectedFilters({
        sourceID: getSourceID(sourceID),
        includeOnly: previousConfig.map(({ type }) => type),
      });

      let apFilter = cloneDeep(appliedFilters);
      let apPaths = cloneDeep(paths);
      let apSelecteCount = cloneDeep(selectedCount);

      previousConfig.forEach(({ type }) => {
        setChecked({ sourceID, type, data: [] });
        getSources({ sourceID }).forEach((ID) => {
          if (apFilter[ID]) {
            apFilter[ID][type] = [];
          }

          if (apPaths[ID]) {
            apPaths[ID][type] = {};
          }

          if (apSelecteCount[ID]) {
            apSelecteCount[ID][type] = 0;
          }
        });
      });

      let previousExtras = getExtras().extras;

      if (previousExtras.length) {
        previousExtras.forEach((item) => {
          if (!savedFilters[item.filterType]) {
            savedFilters[item.filterType] = [];
          }

          let { reason, reasonValue, chartTitle, ...rest } = item;
          savedFilters[item.filterType].push(rest);
        });
      }

      userFilters.forEach(({ filterType, data }) => {
        let filterConf = previousConfig.find(({ type }) => type === filterType);

        if (filterConf?.filterType) {
          filterType = filterConf?.filterType;
          if (!savedFilters[filterType]) {
            savedFilters[filterType] = [];
          }

          savedFilters[filterType] = uniqBy(
            [
              ...savedFilters[filterType],
              ...data.map(({ reason, reasonValue, chartTitle, ...item }) => ({ ...item, filterType })),
            ],
            'ID'
          );
        }
      });

      if (userFilters.length) {
        currentConfig.forEach((currentItem) => {
          if (savedFilters[currentItem?.filterType]) {
            let { allow = false, onlyDimensionType, otherThenDimensionType } = currentItem;
            let toFilters = [];
            let pFilter = cloneDeep(savedFilters[currentItem?.filterType]);
            let workingFilter = cloneDeep(savedFilters[currentItem?.filterType]);

            let removeFilterIndex = [];

            if (onlyDimensionType || otherThenDimensionType) {
              workingFilter = pFilter.filter((item, index) => {
                let DimensionType = get(item, 'DimensionType', '').toLowerCase();
                if (
                  DimensionType === onlyDimensionType ||
                  (otherThenDimensionType && DimensionType !== otherThenDimensionType)
                ) {
                  return true;
                }

                removeFilterIndex.push(index);
                return false;
              });
            }

            if (allow !== false) {
              toFilters = workingFilter.splice(0, allow);
            } else {
              toFilters = workingFilter.splice(0, workingFilter.length);
            }

            if (removeFilterIndex.length) {
              savedFilters[currentItem?.filterType] = removeFilterIndex.map((i) => {
                let item = cloneDeep(pFilter[i]);

                if (!currentItem.reason) {
                  item['reason'] = otherThenDimensionType ? RULE_OTHER_THAN : RULE_ONLY;
                  item['reasonValue'] = onlyDimensionType || otherThenDimensionType;
                  item['chartTitle'] = currentItem.title;
                  item['chartType'] = currentItem.type;
                }

                return item;
              });
            } else {
              savedFilters[currentItem?.filterType] = workingFilter.splice(0, workingFilter.length).map((item) => {
                if (!item.reason) {
                  item['reason'] = RULE_RESTRICTED_UPTO;
                  item['reasonValue'] = allow;
                  item['chartTitle'] = currentItem.title;
                  item['chartType'] = currentItem.type;
                }

                return item;
              });
            }

            applyingFilters.push(
              setChecked({
                sourceID,
                type: currentItem.type,
                data: toFilters,
              })
            );
          }
        });

        let savedExtraValues = Object.values(savedFilters);
        let extras = [];

        savedExtraValues.forEach((item) => {
          extras = [...extras, ...item];
        });

        setAny({
          sourceID,
          type: TYPE_EXTRAS,
          data: {
            extras: unionBy(extras, 'ID'),
            from: chart,
            to: selected,
          },
        });

        applyingFilters.forEach(({ data: { quickPaths = {}, checked }, type }) => {
          getSources({ sourceID }).forEach((ID) => {
            if (apFilter[ID]) {
              apFilter[ID][type] = checked;
            }

            if (apPaths[ID]) {
              apPaths[ID][type] = quickPaths;
            }

            if (apSelecteCount[ID]) {
              apSelecteCount[ID][type] = checked.length;
            }
          });
        });

        changeRoot(apFilter);
        changeRoot(apPaths, 'path');
        changeRoot(apSelecteCount, 'count');
      }
    }
  };

  const swap = ({ from, to }) => {
    getSources({ sourceID }).forEach((ID) => {
      let fromFilters = getSelectedFilters({ sourceID, type: from });
      let toFilters = getSelectedFilters({ sourceID: ID, type: to });

      if (!Array.isArray(fromFilters)) {
        fromFilters = [];
      }

      if (!Array.isArray(toFilters)) {
        toFilters = [];
      }

      setChecked({
        sourceID: ID,
        type: from,
        data: toFilters,
      });

      setAppliedFilter({
        appliedFilter: toFilters,
        quickPaths: getAny({
          type: from,
          sourceID: ID,
          key: 'quickPaths',
        }),
        type: from,
        sourceID: ID,
      });

      setChecked({
        sourceID: ID,
        type: to,
        data: fromFilters,
      });

      setAppliedFilter({
        appliedFilter: fromFilters,
        quickPaths: getAny({
          type: to,
          sourceID: ID,
          key: 'quickPaths',
        }),
        type: to,
        sourceID: ID,
      });
    });
  };

  const switchFilters = ({ from, to, key, value, primaryKey = 'ID', not = false }) => {
    getSources({ sourceID }).forEach((ID) => {
      let fromFilters = getSelectedFilters({ sourceID, type: from });
      let toFilters = getSelectedFilters({ sourceID: ID, type: to });

      if (!Array.isArray(fromFilters)) {
        fromFilters = [];
      }

      if (!Array.isArray(toFilters)) {
        toFilters = [];
      }

      let shiftableFilters = fromFilters?.filter((item) => (not ? item[key] !== value : item[key] === value));

      if (shiftableFilters?.length) {
        fromFilters = fromFilters?.filter((item) => (not ? item[key] === value : item[key] !== value));

        shiftableFilters.forEach((item) => {
          let existingItemIndex = toFilters?.findIndex((toItem) => toItem[primaryKey] === item[primaryKey]);

          if (existingItemIndex >= 0) {
            toFilters[existingItemIndex] = item;
          } else {
            toFilters.push(item);
          }
        });

        setChecked({
          sourceID: ID,
          type: from,
          data: fromFilters,
        });

        setAppliedFilter({
          appliedFilter: fromFilters,
          quickPaths: getAny({
            type: from,
            sourceID: ID,
            key: 'quickPaths',
          }),
          type: from,
          sourceID: ID,
        });

        setChecked({
          sourceID: ID,
          type: to,
          data: toFilters,
        });

        setAppliedFilter({
          appliedFilter: toFilters,
          quickPaths: getAny({
            type: to,
            sourceID: ID,
            key: 'quickPaths',
          }),
          type: to,
          sourceID: ID,
        });
      }
    });
  };

  const resetConditions = () => {
    const dataApplied = getSelectedFilters({
      sourceID,
      includeOnly: VISUALIZE_TYPE_INDICATORS,
    });

    let indicators = [];
    dataApplied.forEach(({ data }) => (indicators = [...indicators, ...data]));
    indicators = uniqBy(indicators, 'ID');
    removeAppliedCondition(indicators, { includeOnly: true });
    return indicators;
  };

  const removeAppliedCondition = (item = {}, { type = TYPE_CONDITION_VISUALIZATION, includeOnly = false } = {}) => {
    const selected = getSelectedFilters({ sourceID, type });
    const IDs = includeOnly ? item.map(({ ID }) => ID) : [];
    const data = selected.filter(({ column }) => (includeOnly ? IDs.includes(column) : column !== item?.ID));

    setAppliedFilter({
      sourceID,
      type,
      appliedFilter: data,
    });
  };

  const removeApplied = (selected, removeItem) => {
    let filteredSelect = selected.filter((item) => item.ID !== removeItem.ID);

    setChecked({
      sourceID,
      type,
      data: filteredSelect,
    });

    setAppliedFilter({
      appliedFilter: getAny({
        type,
        sourceID,
        key: 'checked',
      }),
      quickPaths: getAny({
        type,
        sourceID,
        key: 'quickPaths',
      }),
      type,
      sourceID,
    });
  };

  const saveFiltersToLocalStorage = (sourceID, user) => {
    let filters = exportAppliedFilters(sourceID, user);
    let fillterID = uniqID();
    localStorage.setItem(fillterID, JSON.stringify(filters));
    return fillterID;
  };

  const createAndApplyFilters = ({
    sourceID,
    type,
    applyFilters = [],
    applyRoot = true,
    defaultDataIndex = TYPE_YEAR,
    cleanNames = true,
  }) => {
    const list = [];

    applyFilters.forEach(({ dataIndex = defaultDataIndex, value, key, id }) => {
      value.forEach((dataValue, index) => {
        list.push({
          [dataIndex]: dataValue,
          [`${dataIndex}Checked`]: true,
          [`${dataIndex}ID`]: id?.[index] || dataValue,
          checked: { [dataIndex]: true },
          root: applyRoot ? key : null,
          order: 0,
        });
      });
    });

    const checkedList = setChecked({
      sourceID,
      type,
      data: list,
      cleanNames,
    });

    setAppliedFilter({
      appliedFilter: list,
      quickPaths: checkedList.data.quickPaths,
      type,
      sourceID,
    });
  };

  const resetToPrevious = ({ id, types = [] } = {}) => {
    if (typeof types === 'string') {
      types = [types];
    }

    if (!types.length && type) {
      types = [type];
    }

    clearFilters();
    types.forEach((filterType) => {
      setChecked({
        sourceID: id || sourceID,
        type: filterType,
        data: getSelectedFilters({ sourceID: id || sourceID, type: filterType }),
      });
    });
  };

  const getGranularity = (ID, fullObject = false) => {
    const granularity = getSelectedFilters({ sourceID: ID || sourceID, type: TYPE_GRANULARITY }) || [];

    if (fullObject) {
      return granularity;
    }

    return granularity[0]?.selected || false;
  };

  return {
    removeApplied,
    switchFilters,
    swap,
    saveFiltersToLocalStorage,
    switchVisualizationFilters,
    getExtras,
    removeExtras,
    addOrReplaceExtras,
    exportAppliedFilters,
    getSelectedCount,
    resetFilters,
    createAndApplyFilters,
    resetToPrevious,
    getGranularity,
    filters,
    appliedFilters,
    destroy,
    removeAppliedCondition,
    resetConditions,
  };
};

export const useFilterContext = () => useContext(FilterContext);
export const useAppliedFilterContext = () => useContext(AppliedFilterContext);
export const getfilterFromLocalStorage = (fillterID) => {
  let filters = localStorage.getItem(fillterID);
  if (!filters) return false;

  return JSON.parse(filters);
};

export default FilterContextProvider;
