import axios from '../axios';
import * as Sentry from '@sentry/browser';
import APIConfinguration from './conf.js';
import Pool from '../shared/Login/userPool';
import { voidFunction, replaceAll } from '../utils';
import Auth from './Auth';
import { message } from '../components';

export const getUserBookmarks = (otherParams = {}) => {
  const params = {
    order: 'd',
    from_date: 'null',
    to_date: 'null',
  };

  return axios.get(`/v1/bookmark/list`, { params: { ...params, ...otherParams } });
};

export const getBookmarkDetails = (bookmarkID) => {
  if (!bookmarkID) return APIConfinguration.apiErrorResponse;

  const params = {
    bookmarkID: bookmarkID,
  };

  return axios.get(`/v1/bookmark/id`, { params });
};

export const removeBookMark = async ({ id, setLoading = voidFunction, setData = voidFunction }) => {
  if (!id) return APIConfinguration.apiErrorResponse;
  setLoading(true);
  const { data } = await axios.post(`/v1/bookmark/delete`, { ip_bookmarkid: String(id) });
  setLoading(false);
  setData(data || APIConfinguration.apiErrorResponse.data);
  return data;
};

export const setBookmarkDetails = async (body) => {
  let { data } = await axios.post('/v1/bookmark/save', body);
  return { isError: data.IsError, ...data };
};

export const saveFiltersToBookmark = async ({ mode, filters = {}, bookmarkID = '0' }) => {
  const format = replaceAll(JSON.stringify(filters), '"', '\\"');
  const body = {
    ip_bookmarkname: `${new Date().getTime()}`,
    ip_state: `${mode}-share`,
    ip_bookmarkid: `${bookmarkID}`,
    bookmarkstring: `${replaceAll(format, "'", "''''")}`,
  };

  const { records, IsError } = await setBookmarkDetails(body);
  return { id: records?.[0]?.id, records, IsError };
};

export const updatePofile = (params = {}) => {
  let updateParams = [];

  if (params.userType) {
    updateParams.push({
      Name: 'custom:UserType',
      Value: params.userType,
    });
  }

  if (params.name) {
    updateParams.push({
      Name: 'name',
      Value: params.name,
    });
  }

  if (params?.attributes?.picture) {
    updateParams.push({
      Name: 'profile',
      Value: params.attributes.picture,
    });
  }

  return axios.post(`/v1/updateuser`, updateParams);
};

export const changePassword = (oldPassword, newPassword, confirmPassword) => {
  const cognitoUser = Pool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (newPassword !== confirmPassword) {
      return reject('Password and confirm password should be the same');
    }

    if (oldPassword === newPassword || oldPassword === confirmPassword) {
      return reject('New password should not be the same as old password.');
    }

    cognitoUser.getSession((error, session) => {
      if (error) {
        return reject(error.message || JSON.stringify(error));
      }

      cognitoUser.changePassword(oldPassword, newPassword, (err) => {
        if (err) {
          return reject(err.message || JSON.stringify(err));
        }

        // Successfully changed the password. Now, perform a global sign-out
        cognitoUser.globalSignOut({
          onSuccess: function (result) {
            resolve(); // Resolve the promise after successfully signing out
          },
          onFailure: function (err) {
            reject(err.message || JSON.stringify(err));
          },
        });
      });
    });
  });
};

export const globalSignOut = () => {
  const cognitoUser = Pool.getCurrentUser();
  if (!cognitoUser) return null;
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((error) => {
      if (error) {
        return reject(error.message || JSON.stringify(error));
      }

      cognitoUser.globalSignOut({
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          reject(err.message || JSON.stringify(err));
        },
      });
    });
  });
};

export const getUserSubscriptions = async ({ userID, setData = voidFunction, setLoading = voidFunction }) => {
  const getData = (params = {}) => {
    const { ip_user = false } = params;
    if (!ip_user) return new Promise((resolve) => resolve(APIConfinguration.apiErrorResponse));
    return axios.get(`/v1/subscribe/user/id`);
  };

  setLoading(true);
  try {
    const { data } = await getData({ ip_user: userID });
    setData(data);
    setLoading(false);
  } catch (error) {
    setLoading(false);
    setData({
      IsError: true,
      error,
    });
    Sentry.captureException(`Failed to get user subscriptions, ` + error);
  }
};

export const follow = (params = {}) => {
  let { ip_user = false, ip_type = 'dataset', subscribe = true, ip_sourcecode = [] } = params;

  const sourceCode = [Number(ip_sourcecode)];

  if (!ip_user || !ip_sourcecode.length) return new Promise((resolve) => resolve(APIConfinguration.apiErrorResponse));

  return axios.post('/v1/unsubscribe', {
    ip_sourcecode: sourceCode,
    ip_type: ip_type,
    ip_check: subscribe,
  });
};

export const subscribeData = async ({
  userID,
  onError = voidFunction,
  setData = voidFunction,
  setLoading = voidFunction,
  subscribe = true,
  ...params
}) => {
  setLoading(true);
  const { data } = await follow({ ...params, ip_user: userID, subscribe });

  if (data.IsError === false) {
    getUserSubscriptions({
      userID,
      setData,
      setLoading,
    });
  } else {
    setLoading(false);
    onError();
  }
};

export const unSubscribeData = (params) => subscribeData({ ...params, subscribe: false });

export const getProfileImage = (file_name) => axios.post(`/v1/profile/picture/read`, { file_name });

export const uploadProfilePicture = async ({ file, fileName, setLoading = voidFunction, setData = voidFunction }) => {
  setLoading(true);
  var formData = new FormData();
  formData.append('file', file, fileName);

  try {
    const { data } = await axios.post(`/v1/profile/picture/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (data.IsError) {
      setLoading(false);
      return data;
    } else {
      const { UploadStatus = false, Message } = data;
      if (UploadStatus) {
        const {
          data: { IsError = false, URL = '' },
        } = await getProfileImage(fileName);

        if (!IsError) {
          const result = await updatePofile({
            attributes: {
              picture: fileName,
            },
          });

          const token = await Auth.getTokenAndRefreshOnExpire(true);

          setData({ avatar: URL, result, token });
          setLoading(false);
        } else {
          throw new Error('Error while getting profile image');
        }
      } else {
        message.success(Message);
        setLoading(false);
      }
    }
  } catch (error) {
    setData({ ...APIConfinguration.apiErrorResponse.data, error });
    setLoading(false);
  }
};

export const saveBookmarkSchedule = async ({
  params = {},
  setData = voidFunction,
  setLoading = voidFunction,
  onError = voidFunction,
}) => {
  try {
    setLoading(true);
    const { data } = await axios.post(`/v1/bookmark/schedule`, params);
    setLoading(false);
    setData(data);
    return data;
  } catch (error) {
    onError(error);
    setLoading(false);
    return error;
  }
};
