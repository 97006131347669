import axios from '../axios';

export const uploadNewsletter = (params = {}) => {
  const url = `/v1/newsletter/upload`;
  return axios.post(url, params);
};

export const uploadResearchPapers = (params = {}) => {
  const url = `/v1/researchpaper/upload`;
  return axios.post(url, params);
};

export const getResearchPapers = (params = {}) => {
  const url = `/v1/researchpaper/get`;
  return axios.get(url, { params });
};

export const getNewsletters = () => {
  const url = `/v1/newsletter/get`;
  return axios.get(url);
};

export const getReserachFilters = () => {
  const url = `/v1/researchpaper/getmeta`;
  return axios.get(url);
};

export const getNewsletterEmailList = () => {
  const url = `/v1/newsletter/emails`;
  return axios.get(url);
};
