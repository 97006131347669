import { DownloadOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { Button } from '../../components';
import { exportToCsv } from '../../utils';
import { cleanName } from '../../utils/common';

const ExportTableToCSVButton = ({
  rows = [],
  columns = [],
  fileName = `ndap-data-table-${new Date().toDateString()}`,
}) => {
  const onExport = () => {
    let header = [];
    let cols = columns.map((item) => {
      header.push(item.dowloadTitle || (typeof item.title === 'function' ? item.title() : item.title));
      return item.dataIndex;
    });

    let dataRows = [header];

    rows.forEach((item) => {
      let row = [];
      cols.forEach((key) => {
        if (key === 'State') row.push(item[key + 'Label'] ? item[key + 'Label'] : item[key]);
        else row.push(item[key] || '');
      });

      dataRows.push(row);
    });

    exportToCsv(cleanName(fileName, '-', true).replace(/--+/gi, '-'), dataRows);
  };

  return (
    <div className="text-right position-relative">
      <Button onClick={onExport} icon={<DownloadOutlined />} type="link" size="small" className="fon-12">
        {t('ExportToCsv')}
      </Button>
    </div>
  );
};

export default ExportTableToCSVButton;
