import { Fragment } from 'react';
import { t } from 'i18next';
import { Typography, Tooltip } from '../../components';
import CustomIcon from '../CustomIcon';
import MergePlaceholder from '../Dataset/Merge/MergePlaceholder';

const DatasetModalTitle = ({ icon = false, title = 'Filter', sourceName = 'Dataset', colorCode = 0 }) => {
  const modalTitle = t(title, {
    sourceName: (sourceName && `: ${sourceName}`) || '',
  });

  return (
    <Fragment>
      <Tooltip title={modalTitle}>
        <Typography.Paragraph className="mb-0 pr-4 font-14" ellipsis={{ rows: 1 }}>
          {(icon &&
            (icon === 'source' ? (
              <MergePlaceholder name={sourceName} mt="n1" size={18} colorCode={colorCode} className="font-12" />
            ) : (
              <CustomIcon type={icon} style={{ marginBottom: -2 }} className="mr-1" />
            ))) ||
            null}

          {modalTitle}
        </Typography.Paragraph>
      </Tooltip>
    </Fragment>
  );
};

export default DatasetModalTitle;
