import React from 'react';
import update from 'immutability-helper';
import { PivotData } from './Utilities';
import PivotTable from './PivotTable';

/* eslint-disable react/prop-types */
// eslint can't see inherited propTypes!

class PivotTableUI extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      unusedOrder: [],
      zIndices: {},
      maxZIndex: 1000,
      openDropdown: false,
      attrValues: {},
      materializedInput: [],
    };
  }

  componentDidMount() {
    this.materializeInput(this.props.data);
  }

  componentDidUpdate() {
    this.materializeInput(this.props.data);
  }

  materializeInput(nextData) {
    if (this.state.data === nextData) {
      return;
    }
    const newState = {
      data: nextData,
      attrValues: {},
      materializedInput: [],
    };
    let recordsProcessed = 0;
    PivotData.forEachRecord(newState.data, this.props.derivedAttributes, function (record) {
      newState.materializedInput.push(record);
      for (const attr of Object.keys(record)) {
        if (!(attr in newState.attrValues)) {
          newState.attrValues[attr] = {};
          if (recordsProcessed > 0) {
            newState.attrValues[attr].null = recordsProcessed;
          }
        }
      }
      for (const attr in newState.attrValues) {
        const value = attr in record ? record[attr] : 'null';
        if (!(value in newState.attrValues[attr])) {
          newState.attrValues[attr][value] = 0;
        }
        newState.attrValues[attr][value]++;
      }
      recordsProcessed++;
    });
    this.setState(newState);
  }

  render() {
    return (
      <PivotTable
        {...update(this.props, {
          data: { $set: this.state.materializedInput },
        })}
      />
    );
  }
}

PivotTableUI.defaultProps = Object.assign({}, PivotTable.defaultProps, {
  hiddenAttributes: [],
  hiddenFromAggregators: [],
  hiddenFromDragDrop: [],
  unusedOrientationCutoff: 85,
  menuLimit: 500,
  icons: {},
});

export default PivotTableUI;
