import React, { useMemo } from 'react';
import Filters from '../Filters';
import { voidFunction } from '../../../utils';
import s from '../index.module.less';
import { useFilterActions } from '../../../providers/FilterContextProvider';
import { MERGE_MODES } from '../../../constants';

const DatasetFilters = (props) => {
  const {
    columns = [],
    details = { Indicators: { Items: [] }, Dimensions: { Items: [] } },
    setFilteredDataColumns = voidFunction,
    exclude = [],
    applyModalTo = null,
    onCreatePivot,
    view_name = '',
    onCustomizeFilter = false,
    hiddenDataColumns = false,
    locationType,
    othersType,
    timeType,
    conditionType,
    classnames,
    mode,
    conditions,
    isVisualisation,
    isStandarized = true,
  } = props;
  const { getGranularity } = useFilterActions({ sourceID: details.SourceId });

  const primaryColumn = useMemo(() => {
    return columns.filter(({ primary, isPrimary }) => isPrimary || primary);
  }, [columns]);

  return (
    <div className={`${s.dataTab} joyride-datasetvisualization-step1`}>
      <Filters
        applyModalTo={applyModalTo}
        source={details}
        columns={columns}
        exclude={exclude}
        onCreatePivot={onCreatePivot}
        view_name={view_name}
        hiddenDataColumns={hiddenDataColumns}
        onSelectColumn={(columnsSelected, columnsApplied) => {
          setFilteredDataColumns(
            [...primaryColumn, ...columns.filter((column) => columnsSelected.includes(column.key))],
            columnsApplied
          );
        }}
        onCustomizeFilter={onCustomizeFilter}
        locationType={locationType}
        othersType={othersType}
        timeType={timeType}
        conditionType={conditionType}
        classnames={classnames}
        granularity={mode === MERGE_MODES.CUSTOMIZE_FILTER && getGranularity(details.SourceId)}
        conditions={conditions}
        isVisualisation={isVisualisation}
        isStandarized={isStandarized}
      />
    </div>
  );
};

export default DatasetFilters;
