import React from 'react';
import VisualizationsLayout from './VisualizationsLayout';
import { t } from 'i18next';

const VisualizationTab = (props) => {
  const containerProps = {
    title: t('CreateChart'),
    ...props,
  };

  return (
    <div className="mt-n3">
      <VisualizationsLayout {...containerProps} />
    </div>
  );
};

export default VisualizationTab;
