export const indDB = () =>
  (function () {
    if (!window.indexedDB) {
      console.error(`Your browser doesn't support IndexedDB`);
      return;
    }
    let db;
    const request = indexedDB.open('locationDB', 1);

    request.onupgradeneeded = (event) => {
      db = event.target.result;
      let store = db.createObjectStore('RecentSearches', {
        autoIncrement: true,
      });
      store.createIndex('searches', 'LGDCode', {
        unique: true,
      });
    };

    request.onerror = (event) => {
      console.error(`Database error: ${event.target.errorCode}`);
    };

    request.onsuccess = () => {
      db = request.result;
    };

    const addSearch = (contact) => {
      if (!db) return null;
      try {
        const txn = db.transaction('RecentSearches', 'readwrite');
        const store = txn.objectStore('RecentSearches');
        store.put(contact);
        txn.oncomplete = () => db.close();
      } catch (e) {}
    };
    const getDB = () => db;

    return {
      db: getDB,
      search: addSearch,
    };
  })();
