import { useEffect, useMemo, useState } from 'react';
import PivotTableUI from './PivotTableUI';
import TableRenderers from '../Pivot/TableRenderers';
import './pivottable.css';
import { voidFunction } from '../../utils';
import { useAppliedFilterContext } from '../../providers/FilterContextProvider';
import { TYPE_ALL_DIMENSIONS, TYPE_PIVOT_COLUMNS } from '../../constants';
import GraphCard from '../Plotly/GraphCard';
import { Condition, Empty, Row } from '../../components';
import { t } from 'i18next';

const Pivot = ({
  sourceData = {},
  sourceID = [],
  aggregatorFuction = 'Sum',
  values = ['Values'],
  charTitle = 'pivot',
  loadingData: loading = false,
  loadingYears,
  years,
  onChangeAggregateOverTime,
  aggregateOverTime = false,
  onChangeYear,
  selectedYear,
  selectMode,
  merge = false,
}) => {
  let [data, setData] = useState([]);
  let { getSelectedFilters, appliedFilters } = useAppliedFilterContext();

  let filters = useMemo(() => {
    let allFilters = getSelectedFilters({
      sourceID,
      includeOnly: [TYPE_ALL_DIMENSIONS, TYPE_PIVOT_COLUMNS],
    });

    let pivotRows = [];
    let icons = { indicators: 'Indicators' };
    let pivotColumns = ['Indicators'];

    allFilters.forEach(({ filterType, data: filterData = [] }) => {
      if (filterType === TYPE_ALL_DIMENSIONS) {
        filterData = filterData.slice(0, 2);
      } else {
        filterData = filterData.slice(0, 1);
      }

      filterData.forEach(({ DisplayName, DimensionType }) => {
        if (filterType === TYPE_ALL_DIMENSIONS) {
          pivotRows.push(DisplayName);
        } else {
          pivotColumns.push(DisplayName);
        }

        icons[String(DisplayName).toLowerCase()] = DimensionType;
      });
    });

    return {
      pivotRows,
      pivotColumns,
      icons,
    };

    // eslint-disable-next-line
  }, [appliedFilters]);

  useEffect(() => {
    setData(sourceData?.data || []);
  }, [sourceData]);

  let dataSource = loading ? [] : data;

  return (
    <GraphCard
      loadingYears={loadingYears}
      years={years}
      onChangeAggregateOverTime={onChangeAggregateOverTime}
      onChangeYear={onChangeYear}
      aggregateOverTime={aggregateOverTime}
      bodyStyles={{ padding: Boolean(dataSource.length) ? 0 : 20 }}
      selectedYear={selectedYear}
      selectMode={selectMode}
      merge={merge}
    >
      <Condition show={!Boolean(dataSource.length)}>
        <Row justify="center">
          <Empty
            imageStyle={{
              width: 300,
            }}
            description={t('NoData')}
          />
        </Row>
      </Condition>
      <Condition show={Boolean(dataSource.length)}>
        <div className="overflow-hidden border-top">
          <PivotTableUI
            data={dataSource}
            rows={loading ? [] : filters.pivotRows}
            cols={loading ? [] : filters.pivotColumns}
            aggregatorName={aggregatorFuction}
            vals={values}
            renderers={{ ...TableRenderers }}
            unusedOrientationCutoff={Infinity}
            onChange={voidFunction}
            icons={loading ? [] : filters.icons}
            title={charTitle}
          />
        </div>
      </Condition>
    </GraphCard>
  );
};

export default Pivot;
