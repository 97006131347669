import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';

import { getDimensionsByCategory } from './DimensionHelpers';

export const useFilterByCategory = (
  dimensions,
  category,
  mergeDimensions = [],
  appliedFilters = [],
  active = false,
  upperLimit = false
) => {
  const [filteredDimensions, setFilteredDimensions] = useState([]);
  const [intialValue, setInitialValue] = useState(null);

  useEffect(() => {
    return () => {
      setFilteredDimensions([]);
    };
  }, []);

  useEffect(() => {
    if (filteredDimensions.length && active !== false && isEmpty(active)) {
      setInitialValue(null);
    }
  }, [filteredDimensions, active]);

  useEffect(() => {
    if (intialValue === null && filteredDimensions.length) {
      if (appliedFilters.length) {
        setInitialValue(filteredDimensions.find((item) => appliedFilters[0].root === item.ID));
      } else {
        setInitialValue(cloneDeep(filteredDimensions[0]));
      }
    }

    // eslint-disable-next-line
  }, [filteredDimensions, intialValue, appliedFilters]);

  useEffect(() => {
    if (dimensions.length) {
      setFilteredDimensions(getDimensionsByCategory(dimensions, category, upperLimit));
    }
  }, [dimensions, category, upperLimit]);

  useEffect(() => {
    if (mergeDimensions.length) {
      let addedDimensions = {};
      const searchCategory = category.toLowerCase();
      setFilteredDimensions(() => {
        mergeDimensions.forEach((item) => {
          if ((item?.DimensionType || '').toLowerCase() === searchCategory && !addedDimensions[item?.ID]) {
            addedDimensions[item.ID] = item;
          }
        });

        return Object.values(addedDimensions);
      });
    }
  }, [mergeDimensions, category]);

  return { filteredDimensions, intialValue };
};
