import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './en.json';
import translationTE from './te.json';
import translationHI from './hi.json';
import translationTA from './ta.json';
import translationASM from './asm.json';
import translationBA from './ba.json';
import translationGU from './gu.json';
import translationKA from './ka.json';
import translationMAL from './mal.json';
import translationMAR from './mar.json';
import translationNE from './ne.json';
import translationOD from './od.json';
import translationPUN from './pun.json';
import translationSIN from './sin.json';
import translationURD from './urd.json';
import translationBODO from './bodo.json';
import translationDONG from './dogri.json';
import translationSANS from './sank.json';
import translationKASH from './kash.json';
import translationKONK from './konk.json';
import translationMAIT from './mait.json';
import translationMANI from './mani.json';
import translationSANT from './sant.json';
import translationMIZO from './mizo.json';
import translationBHOJ from './bhoj.json';
import * as config from '../config';

// const fallbackLng = ['en'];
const availableLanguages = [
  'en',
  'hi',
  'te',
  'ta',
  'asm',
  'ba',
  'gu',
  'ka',
  'mal',
  'mar',
  'ne',
  'od',
  'pun',
  'sin',
  'urd',
  'bodo',
  'dog',
  'sank',
  'kas',
  'kon',
  'mait',
  'mani',
  'sant',
  'mizo',
  'bhoj',
];

const resources = {
  en: {
    translation: translationEN,
  },
  hi: {
    translation: translationHI,
  },
  te: {
    translation: translationTE,
  },
  ta: {
    translation: translationTA,
  },
  asm: {
    translation: translationASM,
  },
  ba: {
    translation: translationBA,
  },
  gu: {
    translation: translationGU,
  },
  ka: {
    translation: translationKA,
  },
  mal: {
    translation: translationMAL,
  },
  mar: {
    translation: translationMAR,
  },
  ne: {
    translation: translationNE,
  },
  od: {
    translation: translationOD,
  },
  pun: {
    translation: translationPUN,
  },
  sin: {
    translation: translationSIN,
  },
  urd: {
    translation: translationURD,
  },
  bodo: {
    translation: translationBODO,
  },
  dog: {
    translation: translationDONG,
  },
  sank: {
    translation: translationSANS,
  },
  kas: {
    translation: translationKASH,
  },
  kon: {
    translation: translationKONK,
  },
  mait: {
    translation: translationMAIT,
  },
  mani: {
    translation: translationMANI,
  },
  sant: {
    translation: translationSANT,
  },
  mizo: {
    translation: translationMIZO,
  },
  bhoj: {
    translation: translationBHOJ,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    react: {
      wait: true,
      useSuspense: true,
    },
    detection: {
      checkWhitelist: true,
    },
    fallbackLng: 'en',
    debug: config.NODE_ENV !== 'production' ? true : false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  });

export default i18n;
