import React, { useState, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { t } from 'i18next';
import debounce from 'lodash/debounce';
import size from 'lodash/size';
import { SearchOutlined } from '@ant-design/icons';
import DOMPurify from 'dompurify';

import { Popover, Tag, Input, message } from '../../../components';

import { getLocationFilterSearch } from '../../../services';
import LocationSearchPopup from './locationSearchPopup';
import HandleOutsideClick from '../../Hoc/HandleOutsideClick';
import { CloseButton } from '../..';
import { indDB } from './indDB';
import s from '../../SearchBar/index.module.less';
import { voidFunction } from '../../../utils';

const search = async (params = {}) => {
  const {
    ip_sourceid,
    callBack = () => {},
    ip_location,
    ip_keyword,
    granularity = false,
    setIsLoading = voidFunction,
  } = params;

  const reqObject = {
    ip_sourceid,
    ip_location,
    ip_keyword,
    granularity,
  };

  try {
    setIsLoading(true);
    const { data } = await getLocationFilterSearch(reqObject);
    const { records, IsError = false } = data;
    if (IsError) {
      message.error(t('FailedFetchingSearchFilters'));
    } else {
      setIsLoading(false);
      callBack(records || []);
    }
  } catch (e) {
    if (axios.isCancel(e)) return;
    Sentry.captureException(`Failed to fetch location search filters, ` + e);
    console.error(t('FailedFetchingSearchFilters'), e);
    message.error(t('FailedFetchingSearchFilters'));
    setIsLoading(false);
  }
};

const waitAndSearch = debounce(search, 500);

const LocationSearch = ({
  containerClass = '',
  searchInput = '',
  isSearchValue = false,
  onSelect = () => {},
  width = 50,
  height = 50,
  handleSearchEmpty = () => {},
  handleFocus = () => {},
  placeholder = '',
  sourceID,
  searchFilters,
  classnames,
  granularity = false,
}) => {
  const [searchValue, setSearchValue] = useState(searchInput);
  const [searchKeyword, setSearchKeyword] = useState(searchInput);

  const [searchOptions, setSearchOptions] = useState([]);
  const [filter, setFilter] = useState({});
  const [, setInputFocus] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const popupReference = useRef(null);
  const filtersArray = searchFilters?.map((f) => f?.ID?.replace('Name', ''));
  const dbRef = useRef(null);

  HandleOutsideClick(popupReference, () => {
    inputRef.current.blur();
    if (document.activeElement === inputRef.current.input) {
      setClicked(true);
    } else {
      setClicked(false);
    }
  });

  useEffect(() => {
    if (!isSearchValue) {
      setSearchValue('');
      setSearchKeyword('');
      setFilter({});
      setShowFilters(true);
    }
  }, [isSearchValue]);

  useEffect(() => {
    dbRef.current = indDB();
  });

  useEffect(() => {
    let isMounted = true;

    const getSearchResults = async () => {
      const filterID = filter?.ID?.replace('Name', '');

      waitAndSearch({
        ip_sourceid: sourceID,
        ip_location: size(filter) > 0 ? [filterID] : filtersArray,
        ip_keyword: searchKeyword || '',
        granularity,
        setIsLoading,
        callBack: (data) => {
          isMounted && setSearchOptions(data);
        },
      });
    };

    if (searchKeyword) {
      getSearchResults();
    } else {
      setSearchOptions([]);
    }

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [searchKeyword, sourceID, filter, granularity]);

  useEffect(() => {
    setSearchValue(searchInput);
    setSearchKeyword(searchInput);
    setClicked(false);
  }, [searchInput]);

  const onPressEnter = () => {
    if (searchValue) {
      dbRef.current.search(searchValue);
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    setSearchKeyword('');
    handleSearchEmpty('');
    onSelect();
    setIsLoading(false);
  };

  const handleSearch = async (evt) => {
    setClicked(true);
    const value = evt.target.value;

    if (value) {
      const formatValue = DOMPurify.sanitize(value);
      if (formatValue) {
        setSearchValue(formatValue);
        setSearchKeyword(formatValue);
      } else {
        setSearchValue(searchValue);
        setSearchKeyword(searchValue);
      }
      inputRef.current.focus();
    } else {
      clearSearch();
    }
  };

  const handleSelect = (item = {}) => {
    setSearchValue(item?.LGDName);
    onSelect(item);
    closePopup();
    dbRef.current.search(item);
  };

  const onClose = () => {
    setFilter({});
    setShowFilters(true);
    inputRef.current.focus();
  };

  const closePopup = () => {
    setClicked(false);
  };

  const onInputFocus = async (evt) => {
    setClicked(true);
    const value = evt.target.value;
    setInputFocus(true);
    if (value) {
      setSearchValue(value);
    } else {
      setSearchValue(null);
      // const db = dbRef.current.db();
      // getAllRecentSearches(db);
    }
    handleFocus(true);
  };

  // const getAllRecentSearches = async (db) => {
  //   if (!db) return null;
  //   const txn = db.transaction('RecentSearches', 'readonly');
  //   const objectStore = txn.objectStore('RecentSearches');
  //   const data = await named();
  //   function named() {
  //     if ('getAll' in objectStore) {
  //       objectStore.getAll().onsuccess = function (event) {
  //         const results = event.target.result;
  //         setSearchOptions(results?.slice(0, 10) || []);
  //       };
  //     }
  //   }
  //   return data;
  // };

  const onFilterChange = (selFilter) => {
    setFilter(selFilter);
    setInputFocus(true);
    setShowFilters(false);
    inputRef.current.focus();
  };

  const sortedFilters = searchFilters.sort((a, b) => {
    return a.hierarchy - b.hierarchy;
  });

  return (
    <>
      <div className={[s.searchBarPopup, containerClass].join(' ')}>
        <Popover
          overlayClassName={`search-popover ${classnames}`}
          placement="bottom"
          content={
            <div ref={popupReference}>
              <LocationSearchPopup
                data={searchOptions}
                onSelect={handleSelect}
                onFilterChange={onFilterChange}
                height={height}
                width={width}
                showFilters={showFilters}
                closePopup={closePopup}
                searchFilters={sortedFilters}
                isLoading={isLoading}
              />
            </div>
          }
          trigger="click"
          visible={clicked}
        >
          <div className="CommonSearchDefault">
            <Input
              ref={inputRef}
              style={{
                width: `100%`,
              }}
              placeholder={placeholder}
              prefix={
                <>
                  <SearchOutlined size={16} className="font-16" />
                  {size(filter) > 0 && (
                    <Tag
                      className={`${s.hintItem} font-24 location-search-tag`}
                      closable
                      onClose={onClose}
                      closeIcon={<CloseButton className={s.closeBtn} />}
                    >
                      {filter.DisplayName}
                    </Tag>
                  )}
                </>
              }
              className={`${s.searchInput} ${s.searchInputHeader} font-14 rounded`}
              allowClear={true}
              value={searchValue}
              onChange={handleSearch}
              onPressEnter={onPressEnter}
              onFocus={onInputFocus}
              autoComplete="off"
            />
          </div>
        </Popover>
      </div>
    </>
  );
};

export default LocationSearch;
