export const RoutePaths = {
  LOGIN: '/login',
  PAGE_404: '/404',
  HOME: '/',
  INFO: '/info',
  CATALOGUE: '/catalogue',
  DATASET: '/dataset',
  VISUALIZE: '/visualize',
  COLLECTION: '/collection',
  TUTORIALS: '/tutorials',
  RESEARCHPAPERS: '/researchpapers',
  PROFILE: '/profile',
  SEARCH: '/search',
  REPORTS: '/reports',
  MERGE: '/merge',
  PROCESSING_REPORT: '/processing-report',
  WEIGHTED_AVERAGE: '/weighted-average',
  GET_API: '/get-api',
  HELP: '/help',
  UNSUBSCRIBE: '/unsubscribe',
  USECASEDASHBOARD: '/usecase-dashboard',
  NEWS_LETTER: '/newsletter',
  PUBLICATIONS: '/publications',
};
