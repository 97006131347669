import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { TableInput } from '../CustomInputs';
import { voidFunction } from '../../utils';

const mapSelectedToData = (ps, quickPaths, allow) => {
  let skip = allow || 0;

  return ps.map((item) => {
    let isChecked = get(quickPaths, `${dataIndex}.selected.${item.ID}`, false);
    let order = get(quickPaths, `${dataIndex}.selected.${item.ID}_order`, 0);

    if (!item.checked) {
      item.checked = {};
    }

    if (isChecked && allow) {
      if (skip) {
        skip--;
      } else {
        isChecked = false;
      }
    }

    item[dataIndex + 'Checked'] = isChecked;
    if (order) item['order'] = order;
    item.checked[dataIndex] = isChecked;

    return item;
  });
};

const dataIndex = 'DisplayName';

const DatasetAllDimensionFilter = ({
  dimensions = [],
  height = 400,
  onSelect = voidFunction,
  quickPaths = {},
  allow = false,
  resetSearch = false,
}) => {
  const [data, setData] = useState([]);
  const [columnLength, setColumnLength] = useState([]);

  useEffect(() => {
    setData(() =>
      mapSelectedToData(
        dimensions.filter((item) => !new RegExp('code', 'gi').test(item[dataIndex])),
        quickPaths,
        allow
      )
    );
    setTimeout(() => {
      setColumnLength([dataIndex]);
    }, 500);
  }, [dimensions, quickPaths, allow]);

  let columns = [
    {
      title: 'Dimensions',
      dataIndex,
      key: 'ID',
      enableCheckbox: true,
      showNumberOfRecord: true,
      searchKey: 'ID',
      showDimensionIcon: true,
    },
  ];

  return (
    <TableInput
      width="100%"
      columns={columns}
      dataSource={data}
      height={height}
      onSelect={onSelect}
      showNumberOfRecord={true}
      columnLength={columnLength}
      allow={allow}
      resetSearch={resetSearch}
    />
  );
};

export default DatasetAllDimensionFilter;
