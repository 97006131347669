import { Spin } from '../../components';

const PageLoader = () => {
  return (
    <Spin tip="Loading please wait..." size="large">
      <div style={{ height: '60vh' }}></div>
    </Spin>
  );
};

export default PageLoader;
