import React from 'react';
import i18n from 'i18next';
import { voidFunction } from '../../utils';
import { FATLANGUAGES } from '../../constants';
import s from './index.module.less';

const FatLanguageLogo = ({ className = '', title, subtitle1, subtitle2, onClick = voidFunction, ...rest }) => {
  const currentLanguage = i18n.language;

  return (
    <>
      <div className={`${className}`}>
        <h4 className={`${s.headerTextFirstLine} ${FATLANGUAGES.includes(currentLanguage) ? s.fatFont : ''}`}>
          <span>{title}</span>
        </h4>
        <h4 className={`${s.headerTextFirstLine} ${FATLANGUAGES.includes(currentLanguage) ? s.fatFont : ''}`}>
          {subtitle1}
          <b className={`${s.headerTextSecondLine} ml-1`}> {subtitle2}</b>
        </h4>
      </div>
    </>
  );
};

export default FatLanguageLogo;
