import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { TableInput } from '../CustomInputs';
import { voidFunction } from '../../utils';
import { Row, Col } from '../../components';
import DatasetAggregationDropdown from '../DatasetAggregationDropdown';
import { GridFixedSpan } from '../../constants';

const mapSelectedToData = (ps, quickPaths, allow) => {
  let skip = allow || 0;
  return ps.map((item) => {
    let isChecked = get(quickPaths, `${dataIndex}.selected.${item.ID}`, false);
    let order = get(quickPaths, `${dataIndex}.selected.${item.ID}_order`, 0);
    const agFunctionValue = get(quickPaths, `${agFunction}.selected.${item.ID}`, false);

    if (!item.checked) {
      item.checked = {};
    }

    if (isChecked && allow) {
      if (skip) {
        skip--;
      } else {
        isChecked = false;
      }
    }

    item[dataIndex + 'Checked'] = isChecked;
    item.checked[dataIndex] = isChecked;

    if (agFunctionValue) {
      item[agFunction] = agFunctionValue;
      item.checked[agFunction] = agFunctionValue;
    }

    if (order) item['order'] = order;
    return item;
  });
};

const dataIndex = 'Description';
const agFunction = 'AggregationFunction';

const DatasetIndicaterFilter = ({
  indicaters = [],
  height = 400,
  onSelect = voidFunction,
  quickPaths = {},
  allow = false,
  isGraphIndicater = false,
  merge = false,
  selectAllByDefault = false,
  disableAggregation = false,
  resetSearch = false,
}) => {
  const [data, setData] = useState([]);
  const [columnLength, setColumnLength] = useState([]);

  useEffect(() => {
    setData(() => mapSelectedToData(indicaters, quickPaths, allow));
    setTimeout(() => {
      setColumnLength([dataIndex]);
    }, 500);
  }, [indicaters, quickPaths, allow]);

  let columns = [
    {
      title: 'AllIndicaters',
      dataIndex: 'Description',
      key: 'ID',
      enableCheckbox: true,
      showNumberOfRecord: true,
      searchKey: 'ID',
      merge,
    },
  ];

  if (!disableAggregation) {
    columns[0]['customRender'] = ({ input, rowData, handleSelect, searchKey }) => {
      if (rowData?.DescriptionChecked) {
        return (
          <Row>
            <Col {...GridFixedSpan.size.eight}>{input}</Col>
            <Col {...GridFixedSpan.size.four}>
              <DatasetAggregationDropdown
                isGraphIndicater={isGraphIndicater}
                value={rowData.AggregationFunction}
                onChange={(value) => {
                  handleSelect(rowData, value, agFunction, { isCheckValue: false, searchKey });
                }}
              />
            </Col>
          </Row>
        );
      }

      return input;
    };
  }

  return (
    <TableInput
      width="100%"
      columns={columns}
      dataSource={data}
      height={height}
      onSelect={onSelect}
      showNumberOfRecord={true}
      columnLength={columnLength}
      allow={allow}
      selectAllByDefault={selectAllByDefault}
      resetSearch={resetSearch}
    />
  );
};

export default DatasetIndicaterFilter;
