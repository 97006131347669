import { QuestionCircleTwoTone } from '@ant-design/icons';
import { t } from 'i18next';
import { useState } from 'react';
import { Modal, Button, Tooltip } from '../../../components';
// import { cleanName } from '../../../utils/common';

const MetaLongDescription = ({ description = '', name = '' }) => {
  const [showLg, setShowLg] = useState();
  // WIP: due to some some issue commented out xhanged to below
  // if (cleanName(description) === cleanName(name) || !description) return null;
  if (description === name || !description) return null;

  return (
    <>
      <Tooltip title={t('ClickToKnowMore')} trigger="hover">
        <QuestionCircleTwoTone className="ml-1 -left text-primary" onClick={() => setShowLg(true)} />
      </Tooltip>
      <Modal
        visible={showLg}
        maskClosable={false}
        onCancel={() => setShowLg(false)}
        title={null}
        closable={false}
        footer={[
          <Button type="primary" onClick={() => setShowLg(false)} className="rounded">
            {t('Close')}
          </Button>,
        ]}
      >
        <div className="text-left">{description}</div>
      </Modal>
    </>
  );
};

export default MetaLongDescription;
