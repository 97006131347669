import { voidFunction } from '../../../utils';
import { Multiselect } from '../..';
import DataService from '../../Hoc/DataService';

const ServiceFilter = (props) =>
  DataService(
    ({
      dataService,
      productName,
      onApply = voidFunction,
      input = 'checkbox',
      placeholder = 'Select',
      apply = {},
      size = 20,
    }) => {
      return (
        <Multiselect
          data={dataService}
          size={size}
          text={placeholder}
          mr={10}
          applyOnly={true}
          input={input}
          onApply={(filter) => onApply(filter, productName)}
          apply={apply}
        />
      );
    },
    props
  )();

export default ServiceFilter;
