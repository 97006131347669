export const screenReaderItems = [
  { title: 'Non Visual Desktop Access (NVDA)', link: 'http://www.nvda-project.org/', type: 'Free' },
  { title: 'System Access To Go', link: 'http://www.satogo.com/', type: 'Free' },
  { title: 'Web Anywhere', link: 'http://webanywhere.cs.washington.edu/wa.php', type: 'Free' },
  { title: 'HAL', link: 'https://yourdolphin.com/en-gb/products/individuals/screen-reader', type: 'Commercial' },
  { title: 'JAWS', link: 'http://www.freedomscientific.com/jaws-hq.asp', type: 'Commercial' },
  {
    title: 'Supernova',
    link: 'https://yourdolphin.com/en-gb/products/individuals/supernova-magnifier-screen-reader',
    type: 'Commercial',
  },
  { title: 'Window-Eyes', link: 'http://www.gwmicro.com/Window-Eyes/', type: 'Commercial' },
];
