import { StandarisationYes } from '../constants';
import { checkStandarisation } from '../utils/common';
import { Skeleton } from './index';
import { StandardizationDatasetIcon } from './StandardizationDatasetIcon';
import StandarizationTag from './StandarizationTag';

const DatasetName = ({
  name = '',
  showIcon = true,
  standarization = StandarisationYes,
  showStandarization = true,
  fontSize = 18,
  forwardedRef,
  iconSize,
}) => {
  const isStandarized = checkStandarisation(standarization);

  return (
    <Skeleton loading={!name ? true : false} avatar paragraph={{ rows: 0 }}>
      <div className="mb-2">
        <div className="d-flex align-items-center">
          <div className="align-self-start">
            {showIcon ? (
              <StandardizationDatasetIcon isStandarized={isStandarized || !showStandarization} iconSize={iconSize} />
            ) : null}
          </div>
          <div>
            <span
              ref={forwardedRef}
              style={{
                fontSize,
                fontWeight: 600,
              }}
            >
              {name}
            </span>
            {showStandarization && name ? <StandarizationTag isStandarized={isStandarized} /> : null}
          </div>
        </div>
      </div>
    </Skeleton>
  );
};

export default DatasetName;
