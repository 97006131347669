import { Select, Row, Col } from '../../components';
import { voidFunction } from '../../utils';
import { WEIGHTED_AVG, STANDERED_FUNCTIONS } from '../../constants';
import { InfoCircleTwoTone } from '@ant-design/icons';
const { Option } = Select;

const DatasetAggregationDropdown = ({ onChange = voidFunction, defaultValue = 'sum', value = 'sum' }) => {
  if (!STANDERED_FUNCTIONS[value] && !WEIGHTED_AVG[value]) {
    value = Object.keys(WEIGHTED_AVG)[0];
  }

  let isWeightedAvg = WEIGHTED_AVG[value || defaultValue];

  return (
    <Row>
      {isWeightedAvg ? (
        <Col className="pt-2 mr-1">
          <InfoCircleTwoTone onClick={() => window.open('/weighted-average', '_blank')} style={{ fontSize: 18 }} />
        </Col>
      ) : null}
      <Col span={isWeightedAvg ? 20 : 24}>
        <Select defaultValue={defaultValue} value={value || defaultValue} className="w-100" onChange={onChange}>
          {Object.keys(STANDERED_FUNCTIONS).map((key) => (
            <Option value={key} key={key}>
              {STANDERED_FUNCTIONS[key]}
            </Option>
          ))}
          {Object.keys(WEIGHTED_AVG).map((key) => (
            <Option value={key} key={key}>
              {WEIGHTED_AVG[key]}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default DatasetAggregationDropdown;
